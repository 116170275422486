export const types = {
	FOLDER: "FOLDER",
	USER: "USER",
};

export const models = {
	[types.FOLDER]: {
		model: null,
	},
	[types.USER]: {
		model: null,
	},
};