<template>
  <Modal title="Создать новую заявку" @close="$emit('close')">
    <form v-on:submit.prevent class="application-form" v-loading="isSending">
      <div class="application-form_header">
        Адрес:
        <el-select 
          v-model="selectedAccoutID"
          value-key="AccountID"
          size="medium" 
          filterable
          @change="selectGroup"
        >
          <el-option
            v-for="(account, i) in groupAccounts"
            :key="`request-info-${i}`"
            :value="account"
            :label="account.Address ? account.Address : account.AccountTypes"
          >
            {{ account.Address ? account.Address : account.AccountType }}
          </el-option>
        </el-select>
      </div>

      <div class="application-form_header">
        Тип заявки:
        <el-select v-model="form.typeID" size="medium" filterable>
          <el-option
            v-for="type in types"
            :key="`request-type-${type.ID}`"
            :label="type.Name"
            :value="type.ID"
          />
        </el-select>
      </div>

      <div 
        v-if="subTypes"
        class="application-form_header"
      >
        Неисправность:
        <el-select v-model="form.subTypeID" size="medium" filterable>
          <el-option
            v-for="type in subTypes"
            :key="`request-subtype-${type.ID}`"
            :label="type.Name"
            :value="type.ID"
          />
        </el-select>
      </div>

      <div 
        v-if="detailSubTypes"
        class="application-form_header"
      >
        Подробный вид:
        <el-select v-model="form.detailSubTypeID" size="medium" filterable>
          <el-option
            v-for="type in detailSubTypes"
            :key="`request-subtype-${type.ID}`"
            :label="type.Name"
            :value="type.ID"
          />
        </el-select>
      </div>
      
      <div class="form-group error_block">
        {{ creationDeniedMessage }}
      </div>
      <div class="form-group">
        <textarea
          v-model="form.text"
          class="form-textarea"
          placeholder="Введите описание заявки..."
        ></textarea>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <span class="medium-text">Файлы</span>
          <p>Максимальный размер файла: 10 МБ</p>
        </div>
        <div class="col-sm-4">
          <label class="input-file" v-show="selectedFileName.length === 0">
            <span class="input-file_icon glipf-clip"></span>
            <span class="button">
              <span class="text">+ Прикрепить</span>
              <input
                type="file"
                ref="fileNewRequest"
                @change="selectFile"
                name="file"
              />
            </span>
          </label>
          <div class="fileName" v-show="selectedFileName.length > 0">
            {{ selectedFileName }}
            <el-button
              icon="el-icon-delete"
              size="mini"
              type="danger"
              @click="resetFile"
              circle
            />
          </div>
        </div>
      </div>
      <div class="input_file-field">
        <span class="name"></span><span class="file-weight"></span
        ><span class="file-remove glipf-remove"></span>
      </div>
      <input
        type="submit"
        @click="newRequest"
        class="application-form_submit-btn main-btn"
        :disabled="!isValidate"
        value="создать"
      />
    </form>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from "@/components/Modal";
import * as requestApi from "@/api/request";
import * as authService from "@/services/auth.service";

export default {
  name: "newRequestModal",
  components: { Modal },
  data() {
    return {
      form: {
        ident: null,
        typeID: null,
        subTypeID: null,
        detailSubTypeID: null,
        text: "",
      },
      selectedAccoutID: null,

      selectedFileName: "",
      types: [],
      accountInfoIDs: [],
      isSending: false,
    };
  },
  mounted() {
    this.getRequestTypes();
    this.getAccountInfo();
  },
  methods: {
    ...mapActions({
      getInfo: 'account/getInfo',
    }),

    getRequestTypes() {
      this.$store.dispatch("request/getRequestTypes").then((result) => {
        this.types = [...result];

        this.types = this.types.map((el) => {
          return {
            ...el,
            Name: el.Name.charAt(0).toUpperCase() + el.Name.substr(1),
          };
        });
      });
    },

    selectFile() {
      if (this.$refs.fileNewRequest.files.length > 0) {
        this.selectedFileName = this.$refs.fileNewRequest.files[0].name;
      } else {
        this.selectedFileName = "";
      }
    },

    resetFile() {
      this.$refs.fileNewRequest.value = "";
      this.selectedFileName = "";
    },

    newRequest() {
      if (!this.isValidate) {
        return;
      }

      this.isSending = true;

      this.$store
        .dispatch("request/newRequest", this.form)
        .then((result) => {
          if (result.id && this.$refs.fileNewRequest.files[0]) {
            let formData = new FormData();
            formData.append("requestId", result.id);
            formData.append("file", this.$refs.fileNewRequest.files[0]);

            //todo: refactor
            requestApi
              .addFile(formData)
              .then(() => {
                this.isSending = false;
                this.$root.$emit("new-request", result.id);
                this.$emit("close");
              })
              .catch(() => {
                this.isSending = false;
                this.$root.$emit("new-request", result.id);
                this.$emit("close");
              });
          } else {
            this.isSending = false;
            this.$root.$emit("new-request", result.id);
            this.$emit("close");
          }
        })
        .catch(() => {
          this.isSending = false;
        });
    },

    getAccountInfo() {
      this.getInfo()
        .then((result) => {
          this.accountInfoIDs = [...result.keys];
        });
    },

    selectGroup(e) {
      this.form.ident = e.Ident;
    }
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo',
      getAccountSettings: 'account/getAccountSettings',
    }),

    isValidate() {
      return (
        this.form.text.length > 0 &&
        this.form.typeID !== null &&
        this.form.ident !== null &&
        !this.isCreationDenied
      );
    },

    accountInfo() {
      return this.$store.getters["account/getInfos"](this.accountInfoIDs);
    },

    groupAccounts() {
      return this.getAllInfo()
        .filter((x, i, self) => {
          return self.findIndex((t) => t.Address == x.Address) === i;
        })
        .filter((account) => {
          return !this.getAccountSettings(account.AccountID).HideOnRequestsPage;
        })
        .sort((a, b) => a.Ident - b.Ident);
    },

    selectedAccountInfo() {
      if (!this.form.ident) {
        return null;
      }
      var info = authService.getUserData();

      if (info && info.accounts && info.accounts.length) {
        var infos = info.accounts.filter((x) => x.ident == this.form.ident);
        if (infos.length) {
          return infos[0];
        }
      }

      return null;
    },

    isCreationDenied() {
      var info = this.selectedAccountInfo;
      return info && info.denyRequestCreation;
    },

    creationDeniedMessage() {
      var info = this.selectedAccountInfo;
      if (!info) {
        return null;
      }
      return info.denyRequestCreationMessage;
    },

    subTypes() {
      if (this.form.typeID) {
        const type = this.types.find((type) => {
          return type.ID === this.form.typeID && type.HasSubTypes
        });
        
        if (typeof type !== 'undefined' && type.SubTypes.length > 0) {
          return type.SubTypes;
        }
      }

      return null;
    },

    detailSubTypes() {
      if (this.form.subTypeID) {
        const type = this.subTypes.find((type) => {
          return type.ID === this.form.subTypeID && type.HasSubTypes
        });

        if (typeof type !== 'undefined' && type.SubTypes.length > 0) {
          return type.SubTypes;
        }
      }

      return null;
    }
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.el-select
  position: relative
  top: -3px
  margin-left: 10px
</style>

<style lang="scss">
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}
</style>
