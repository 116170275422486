<template>
  <main class="main-content">
    <div class="container">
      <a @click="$router.go(-1)" style="cursor:pointer" class="back-page_btn"
        >Назад</a
      >
      <h1 class="main-inner_title">Новости</h1>
      <article class="news-detailed">
        <figure
          class="news-detailed_img tablet_small-hidden"
          v-if="news.HasImage"
        >
          <img
            v-bind:src="image"
            alt="alt"
            :key="`news-img-mobile-${keyImage}`"
          />
        </figure>
        <h3 class="section-title">{{ news.Header }}</h3>
        <span class="news-detailed_date">{{ news.Created }}</span>
        <figure
          class="news-detailed_img tablet_small-visible"
          v-if="news.HasImage"
        >
          <img v-bind:src="image" alt="alt" :key="`news-img-${keyImage}`" />
        </figure>
        <p v-html="news.Text"></p>
      </article>
    </div>
  </main>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      keyImage: 1,
      image: "",
    };
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      this.$store.dispatch("news/getNews", this.idNews).then(() => {
        if (this.news.HasImage) {
          this.getImage();
        }
      });
    },
    async getImage() {
      let blob = await this.$store.dispatch("news/getImage", this.idNews);
      this.image = blob;

      ++this.keyImage;
    },
  },
  computed: {
    idNews() {
      return this.$route.params.id;
    },
    news() {
      return this.$store.getters["news/getNewsById"](this.idNews);
    },
  },
  watch: {
    $route() {
      this.getNews();
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped></style>
