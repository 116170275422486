<template>
  <header class="ui-header">
    <div class="container">
      <div class="ui-header_content">
        <div class="head-logo">
          <template v-if="config.logoLink">
            <a :href="config.logoLink()">
              <img :src="config.appIconFile" alt="logo" />
            </a>
            <a :href="config.logoLink()">
              <span class="logo-title">{{ config.main_name }}</span>
            </a>
          </template>
          <template v-else>
            <img :src="config.appIconFile" alt="logo" />
            <span class="logo-title">{{ config.main_name }}</span>
          </template>
        </div>
        <nav class="head-nav" :class="{ 'head-nav--visible': isShowMobileNav }">
          <span
            class="close-menu_btn glipf-remove"
            @click="isShowMobileNav = false"
          ></span>
          <ul class="head-menu" @click="isShowMobileNav = !isShowMobileNav">
            <li class="head-menu_item">
              <router-link :to="{ name: 'Home' }">
                <span class="glipf-events head-menu_icon"
                  ><span class="path1"></span><span class="path2"></span
                  ><span class="path3"></span><span class="path4"></span
                  ><span class="path5"></span><span class="path6"></span
                  ><span class="path7"></span><span class="path8"></span
                  ><span class="path9"></span></span
                >События
              </router-link>
            </li>
            <li class="head-menu_item" v-if="menuItems.Payment">
              <router-link :to="{ name: 'Payment' }">
                <span class="glipf-payment head-menu_icon"></span>Оплата
              </router-link>
            </li>
            <li class="head-menu_item" v-if="menuItems.Meters">
              <router-link :to="{ name: 'Meters' }">
                <span class="glipf-testimony head-menu_icon"></span>Показания
              </router-link>
            </li>
            <li class="head-menu_item" v-if="menuItems.Requests && !accessOSS">
              <router-link :to="{ name: 'Requests' }">
                <span class="glipf-message head-menu_icon"></span>Заявки
              </router-link>
            </li>
            <li class="head-menu_item" v-if="config.showOurService">
              <router-link :to="{ name: 'Services' }">
                <span :class="[
                  'head-menu_icon',
                  (isGranel) ? 'glipf-our-service' : 'glipf-our-content', 
                ]"></span>
                Наш сервис
              </router-link>
            </li>    
            <li class="head-menu_item" v-if="accessOSS">
              <router-link :to="{ name: 'Oss' }">
                <span class="glipf-meeting head-menu_icon"></span>Собрания
              </router-link>
            </li>
            <li class="head-menu_item" v-if="showOSSLink">
              <a href="https://oss.sm-center.ru/">
                <span class="glipf-meeting head-menu_icon"></span>Собрания
              </a>
            </li>
            <li class="head-menu_item">
              <router-link :to="{ name: 'Profile' }">
                <span class="glipf-user head-menu_icon"></span>Профиль
              </router-link>
            </li>
          </ul>
        </nav>
        <ul class="head-contact">
          <li>
            <a :href="'tel:' + config.clearPhone" class="head_phone-number"
              ><span class="glipf-phone"></span>{{ config.phone || "" }}</a
            >
          </li>
          <!-- <li>
            <a
              href="#support"
              @click.prevent="$emit('supportModalChat')"
              data-toggle="modal"
              class="head_write-support"
              ><span class="glipf-user-2"></span>
              <span class="text">Написать в тех. поддержку</span></a
            >
          </li> -->
        </ul>
        <div
          :class="[
            'hamburger',
            'hamburger--spring',
            { 'is-active': isShowMobileNav },
          ]"
          @click="isShowMobileNav = !isShowMobileNav"
        >
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderBlock",
  data() {
    return {
      isShowMobileNav: false,
      accessOSS: false,
      showOSSLink: false,
      menuItems: {
        Payment: false,
        Meters: false,
        Requests: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getMenuVisibilitySettings: "config/getMenuVisibilitySettings",
    }),
  },
  created() {
    this.updateMenuItemVisibility(this.getMenuVisibilitySettings);
  },
  mounted() {
    this.getAccountInfo();
  },
  watch: {
    getMenuVisibilitySettings(newValue) {
      this.updateMenuItemVisibility(newValue);
    },
  },
  methods: {
    getAccountInfo() {
      this.accessOSS = this.authUser.accessOSS;
      this.showOSSLink = !this.accessOSS && this.authUser.showOSSLink;
    },
    updateMenuItemVisibility(visibilitySettings) {
      this.menuItems = visibilitySettings;
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped></style>
