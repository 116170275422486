<template>
  <div :class="['global-wrapper', globalWrapper]" id="app">
    <modals-container />
    <div class="wrapper-loader" v-loading="loading" v-show="loading"></div>
    <transition :name="transitionName">
      <component :is="layout">
        <router-view />
      </component>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      transitionName: "",
      loading: false,
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "page-layout";
    },

    globalWrapper() {
      switch (this.$route.name) {
        case "News":
          return "global-wrapper_inner";
        case "Announcement":
          return "global-wrapper_inner";
        case "Profile":
          return "global-wrapper_profile";
        case "Payment":
          return "global-wrapper_payment";
        case "Requests":
          return "global-wrapper_application";
        case "OssVote":
          return "global-wrapper_qustion";
        case "OssResult":
          return "global-wrapper_qustion";
        case "OssInfo":
          return "global-wrapper_meetings";
        case "Oss":
          return "global-wrapper_meetings";
      }

      return "";
    },
  },
  created() {
    if (!localStorage.getItem("deviceID")) {
      this.$store.dispatch("common/generateGUID");
    }
    this.$store.dispatch("account/syncUserData");
    this.$root.$on("page-loading", (status) => {
      this.loading = status;
    });
  },
  destroyed() {
    this.$root.$off("page-loading");
  },
  methods: {
    // generateGUID() {
    //   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
    //     c
    //   ) {
    //     var r = (Math.random() * 16) | 0,
    //       v = c == "x" ? r : (r & 0x3) | 0x8;
    //     return v.toString(16);
    //   });
    // },
    colorToRGB(colorString) {
      if (!colorString || colorString.length != 6) {
        return "0, 0, 0";
      }
      return (
        parseInt(colorString.substr(0, 2), 16) +
        ", " +
        parseInt(colorString.substr(2, 2), 16) +
        ", " +
        parseInt(colorString.substr(4, 2), 16)
      );
    },
    setIcon(url) {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = url;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },

    config(newValue) {
      let root = document.documentElement;
      root.style.setProperty("--base-color", "#" + newValue.color);
      root.style.setProperty(
        "--base-color_rgb",
        this.colorToRGB(newValue.color)
      );
      this.setIcon(newValue.siteIconFile);
    },
  },
};
</script>

<style>
:root {
  /*--base-color: #ff0713;*/
  --base-color: transparent;
  --base-color_rgb: 0, 0, 0;
}

.vm--overlay {
  background: rgba(0, 0, 0, 0.8);
}

.slick-slide,
.slick-slide > div,
.slick-slide > div > div {
  outline: none;
}
</style>

<style lang="sass">
//@import "sass/main"
</style>
