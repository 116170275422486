<template>
  <div :class="[{'modalStyle': setBaseStyle}]">
    <button @click="$emit('close')" class="close popup-form_close" type="button">
      <span class="glipf-remove"></span>
    </button>
    <div class="popup-title" v-if="title">{{ title }}</div>
    <slot/>
  </div>
</template>

<script>
  export default {
    name: "Modal",
    props: {
      title: {
        type: String,
        default: null
      },
      setBaseStyle: {
        type: Boolean,
        default: true
      },
    },
  }
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
  .modalStyle {
    padding: 2em 4em;
    background-color: #fff;
  }
  @media only screen and (max-width: 575px) {
    .modalStyle {
      padding: 1em 1em;   
    }
  }
</style>

<style lang="scss" rel="stylesheets/scss">
  .vm--modal {
    padding: 0 !important;
    background-color: transparent !important;
  }
</style>
