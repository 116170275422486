<template>
  <main class="main-content">
    <div class="container">
      <div class="profile-section">
        <div class="main-title main-title_left">Профиль</div>
        <div class="profile-content">
          <div class="row">
            <div class="col-md-6">
              <form v-on:submit.prevent class="unified-form profile-form">
                <div class="form-group">
                  <label class="form-label"
                    ><span class="glipf-user-3"></span>ФИО</label
                  >
                  <input type="text" v-model="form.fio" class="form-control" />
                </div>
                <div class="form-group">
                  <label class="form-label"
                    ><span class="glipf-mail-2"></span>Email</label
                  >
                  <input
                    type="email"
                    v-model="form.email"
                    class="form-control"
                  />
                </div>
                <input
                  type="submit"
                  @click="updateProfile"
                  :disabled="!isValidate"
                  class="profile-form_submit-btn main-btn"
                  value="Сохранить"
                />
              </form>
              <a
                @click="logout"
                style="cursor: pointer"
                class="profile_exit-btn"
                >Выйти</a
              >
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import * as authService from "../services/auth.service";

export default {
  name: "Profile",

  data() {
    return {
      form: {
        email: "",
        fio: "",
      },

      saveBankCard: false,
      dontSavePassword: true,
    };
  },

  computed: {
    isValidate() {
      return !!this.authUser.fio;
    },
  },

  mounted() {
    this.cloneData();
  },

  methods: {
    cloneData() {
      this.form = {
        email: this.authUser.email,
        fio: this.authUser.fio,
      };
    },

    updateProfile() {
      if (this.isValidate) {
        this.$store
          .dispatch("account/updateProfile", this.form)
          .then((result) => {
            if (result.isSucceed) {
              this.$notify.success("Данные успешно обновлены");
            }
          })
          .catch(() => {
            this.$notify.error("Ошибка обновления данных профиля");
          });
      }
    },

    logout() {
      authService.resetAuthData();
      location.href = location.href.split("#")[0];
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped></style>
