import { render, staticRenderFns } from "./IndexRoute.vue?vue&type=template&id=3b272094&scoped=true&"
import script from "./IndexRoute.vue?vue&type=script&lang=js&"
export * from "./IndexRoute.vue?vue&type=script&lang=js&"
import style0 from "./IndexRoute.vue?vue&type=style&index=0&id=3b272094&lang=sass&rel=stylesheets%2Fsass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b272094",
  null
  
)

export default component.exports