<template>
  <div :class="['switch-btn', switchStatusClass]" @click="change"></div>
</template>

<script>
  export default {
    name: "SwitchBtn",
    props: {
      value: {
        type: Boolean,
      },
    },
    data() {
      return {
        status: false
      }
    },
    mounted() {
      this.status = this.value
    },
    methods: {
      change() {
        this.status = !this.status
        this.$emit('input', this.status)
      }
    },
    computed: {
      switchStatusClass() {
        return this.status ? 'switch-on' : 'switch-off';
      }
    },
    watch: {
      value(newValue) {
        this.status = newValue
      }
    },
  }
</script>

<style lang="sass" rel="stylesheets/sass" scoped>

</style>
