import { normalizeData } from "../../services/common";
import * as commonApi from "@/api/common";
import * as newsApi from "@/api/news";

const state = {
  news: {},
};

const actions = {
  getEventBlockData({ commit }) {
    return commonApi
      .getEventBlockData()
      .then((response) => {
        let news = normalizeData(response.data.News, "ID");
        commit("setNews", news.list);

        let announcements = normalizeData(response.data.Announcements, "ID");
        commit("announcement/setAnnouncements", announcements.list, {
          root: true,
        });

        let services = normalizeData(response.data.AdditionalServices, "ID");
        /* Перенос значения из списка магазинов в список магазинов в AdditionalServicesByGroups*/
        Object.keys(response.data.AdditionalServicesByGroups).forEach(
          (group) => {
            response.data.AdditionalServicesByGroups[group].forEach((item) => {
              item.ExternalSystem = services.list[item.ID].ExternalSystem;
            });
          }
        );
        commit("additionalService/setServices", services.list, { root: true });
        commit(
          "additionalService/setServicesByGroups",
          response.data.AdditionalServicesByGroups,
          { root: true }
        );

        commit("polls/setPolls", response.data.Polls, { root: true });

        return { news, announcements, services };
      })
      .catch((error) => {
        throw error;
      });
  },

  getNews({ commit }, news_id) {
    return newsApi
      .getNewsContent(news_id)
      .then((response) => normalizeData([response.data], "ID"))
      .then((result) => {
        commit("setNews", result.list);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  },

  async getImage(context, id) {
    let response = await newsApi.getNewsImage(id);

    if (response.status === 200) {
      let blob = new Blob([response.data], { type: "image/png" });
      return window.URL.createObjectURL(blob);
    }
  },
};

const getters = {
  getNewsById: (state) => (id) => state.news[id] || {},
  getNews: (state) => (list) => list.map((id) => state.news[id] || {}),
};

const mutations = {
  setNews(state, data) {
    state.news = {
      ...state.news,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
