<template>
  <Modal title="Оплата" @close="$emit('close')">
    <form v-on:submit.prevent class="payment-form" v-loading="isSending">
      <template>
        <div class="payment-form_number">
          Лицевой счёт:
          <el-select
            v-model="form.accountID"
            size="medium"
            @change="getAccountInfo"
            filterable
          >
            <el-option
              v-for="account in accountInfo"
              :key="`request-info-${account.AccountID}`"
              :label="`${account.Ident} (${account.AccountType})`"
              :value="account.AccountID"
            >
              {{ account.Ident }} ({{ account.AccountType }})
            </el-option>
          </el-select>
        </div>
        <div class="form-group">
          <label class="form-label"><span class="glipf-house"></span></label>
          <div class="wrapper_form-control">
            <input
              type="number"
              min="1"
              step="0.01"
              class="form-control"
              v-model.number="form.sum"
            />
            <span class="text">руб.</span>
          </div>
        </div>
        <div
          class="paymentSwitch-group"
          v-if="
            insuranceSum &&
              (!form.paymentSystem || form.paymentSystem == 'Tinkoff')
          "
        >
          <switch-btn v-model="form.payInsurance" />
          <span class="text">Страхование {{ insuranceSum }} руб.</span>
          <a
            class="payment-disable_insurance"
            @click.prevent="disableInsurance()"
            href=""
            >Не предлагать страхование</a
          >
          <a
            class="payment-rules_insurance"
            target="blank"
            href="https://sm-center.ru/vsk_polis.pdf"
            >Условия страхования</a
          >
        </div>
        <div
          class="paymentSwitch-group"
          v-if="
            form.paymentSystem == 'Tinkoff' ||
              (paymentSystems &&
                paymentSystems.length === 1 &&
                paymentSystems[0].name == 'Tinkoff')
          "
        >
          <SwitchBtn v-model="form.allowCardSaving" />
          <span class="text">Cохранить карту</span>
        </div>
        <template v-if="paymentSystems && paymentSystems.length > 1">
          <div class="payment-select_payment_system_group">
            <div class="payment-select_payment_system_group_label">
              Платежная система:
            </div>
            <template v-for="item in paymentSystems">
              <input
                class="select_payment_system_group_radio"
                :id="'psradio_' + item.name"
                type="radio"
                :value="item.name"
                v-model="form.paymentSystem"
                :key="'i_' + item.name"
              />
              <label :for="'psradio_' + item.name" :key="'l_' + item.name">
                <img
                  class="payment-select_payment_system_group-icon"
                  width="32"
                  height="32"
                  :src="getPaymentSystemImage(item.name)"
                />
                {{ item.displayName }}
              </label>
              <br :key="'b_' + item.name" />
            </template>
          </div>
        </template>
        <div class="line"></div>
        <div>
          <div class="payment-form_total-price" v-loading="commissionIsLoading">
            Итого: <span class="payment-form_sum">{{ totalSum }}</span>
            <span class="rouble">руб.</span>
          </div>
          <template v-if="!hideComissionInfo">
            <span v-if="commission > 0" class="text-commission"
              >*Комиссия: {{ commission }}
              <span class="rouble">руб.</span></span
            >
            <span v-else class="text-commission">*Комиссия не взимается</span>
          </template>
        </div>
        <button
          class="payment-form_submit main-btn"
          @click="startPayment"
          style="border: 0"
        >
          Оплатить
        </button>
      </template>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import SwitchBtn from "@/components/ui/SwitchBtn";
import * as commonApi from "@/api/common";

export default {
  name: "payModal",
  components: { SwitchBtn, Modal },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        accountID: null,
        sum: 0,
        payInsurance: false,
        allowCardSaving: false,
        paymentSystem: null,
      },

      insuranceSum: 0,
      totalSum: 0,
      commission: 0,
      hideComissionInfo: true,
      commissionIsLoading: false,
      //accountInfoIDs: [],
      accountInfo: [],
      paymentSystems: [],
      isSending: false,
      isPaymentSystemSelectionMode: false,
    };
  },
  mounted() {
    this.form.accountID = this.id;
    this.getAccountInfo();
    this.getPaymentSystems();
  },
  methods: {
    getAccountInfo() {
      this.accountInfo = this.$store.getters["account/getAllInfo"]();
      this.setForm();
    },

    disableInsurance() {
      this.isSending = true;

      this.$store
        .dispatch("account/disableAccountInsurance", this.id)
        .then((result) => {
          this.isSending = false;
          if (result.error) {
            this.$notify.error({
              position: "top-left",
              title: "Ошибка",
              message: result.error,
            });
          } else {
            this.getAccountInfo();
          }
        })
        .catch(() => (this.isSending = false));
    },

    getPaymentSystems() {
      this.isSending = true;

      this.$store
        .dispatch("account/getPaymentSystems")
        .then((result) => {
          this.isSending = false;
          this.paymentSystems = result;
          if (this.paymentSystems && this.paymentSystems.length > 1) {
            this.form.paymentSystem = this.paymentSystems[0].name;
          } else {
            this.form.paymentSystem = "Tinkoff";
          }
        })
        .catch(() => (this.isSending = false));
    },

    setForm() {
      const data = this.accountInfo.find((x) => x.AccountID == this.form.accountID);
      //this.$store.getters['account/getInfoByIdent'](this.form.ident)

      if (data) {
        this.insuranceSum = data.InsuranceSum;

        this.form.sum = data.Sum;
        this.form.insuranceEnabledByUser = data.InsuranceEnabledByUser
      } else {
        this.form.sum = this.accountInfo.reduce((accumulator, current) => {
          return parseFloat((accumulator + current.Sum).toFixed(2));
        }, 0);
        this.form.accountID = null;
      }

      this.form.paymentSystem = this.form ? this.form.paymentSystem : null;
      this.form.allowCardSaving = false;

      if (this.config && this.config.insuranceSwitchIsOffByDefault) {
        this.form.payInsurance = false;
      }
    },

    startPayment() {
      if (parseInt(this.form.sum) > 0) {
        this.getPayLink();
      }
    },

    getPaymentSystemImage(name) {
      return commonApi.getPaymentSystemImageDownloadLink(name);
    },

    getPayLink() {
      this.isSending = true;
      this.$store
        .dispatch("account/getPayLink", this.form)
        .then((result) => {
          this.isSending = false;
          this.isPaymentSystemSelectionMode = false;
          if (result.payLink && result.payLink.length > 0) {
            location.href = result.payLink;
          }
        })
        .catch(() => {
          this.isSending = false;
          this.isPaymentSystemSelectionMode = false;
        });
    },

    getCommission() {
      if (!this.form.sum) {
        this.commission = 0;
        this.totalSum = 0;
        return;
      }

      this.commissionIsLoading = true;
      this.$store
        .dispatch("account/getSumWithCommission", {
          sum: this.form.sum + (this.form.payInsurance ? this.insuranceSum : 0),
          accountID: this.form.accountID,
        })
        .then((result) => {
          this.commission = result.Commission;
          this.totalSum = result.TotalSum;
          this.hideComissionInfo = result.HideComissionInfo;
          this.commissionIsLoading = false;
        })
        .catch(() => (this.commissionIsLoading = false));
    },
  },
  watch: {
    "form.sum"(val) {
      // console.log('val:', val);
      let value = String(val);
      if (value.indexOf(".") != '-1') {
        this.form.sum = value.substring(0, value.indexOf(".") + 3);
      }

      this.getCommission();
    },
    "form.payInsurance"() {
      this.getCommission();
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.el-select
  position: relative
  top: 1px
  margin-left: 10px
</style>

<style lang="scss">
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}
</style>
