<template>
  <main class="main-content">
    <div class="container">
      <router-link class="back-page_btn" :to="{ name: 'Oss' }"
        >Назад</router-link
      >
      <h1 class="main-inner_title">Вопросы для голосования</h1>
      <div v-if="isCompleting" class="number-qustion">
        Завершение голосования...
      </div>
      <div v-else class="number-qustion">
        Вопрос {{ questionNumber }}/{{ questionCount }}.
      </div>
    </div>
    <div class="qustion-line">
      <span
        class="qustion_progress-bar"
        :style="'width: ' + progress + '%'"
      ></span>
    </div>
    <div class="container">
      <div class="qustion-section">
        <div class="row">
          <template v-if="isCompleting">
            <div class="col-sm-7 col-6 column">
              <p class="large-text">
                Пожалуйста, подождите...
              </p>
            </div>
          </template>
          <template v-else>
            <div class="col-sm-7 col-6 column">
              <p class="large-text">
                {{ question.QuestionMessage }}
              </p>
              <div class="item-file" v-if="question.HasFile">
                <span class="glipf-pdf"></span>
                <span class="item-desc"
                  >Файл для обсуждения.pdf<br /><a
                    :href="question.FileLink"
                    download=""
                    >Скачать</a
                  ></span
                >
              </div>
              <div style="margin-top: 10px;">
                <a
                  href=""
                  @click.prevent="goPrevious"
                  :class="[
                    'main-btn',
                    canGoPrevious ? '' : 'main-btn-disabled',
                  ]"
                  >{{ "&lt; Назад" }}</a
                >
              </div>
            </div>
            <div class="col-sm-5 col-6 column">
              <ul class="answer-options_list">
                <li>
                  <label class="unified-radio">
                    <input
                      value="0"
                      type="radio"
                      name="radio2"
                      v-model="selectedValue"
                    />
                    <span class="radio-text">За</span>
                  </label>
                </li>
                <li>
                  <label class="unified-radio">
                    <input
                      value="1"
                      type="radio"
                      name="radio2"
                      v-model="selectedValue"
                    />
                    <span class="radio-text"> Против</span>
                  </label>
                </li>
                <li>
                  <label class="unified-radio">
                    <input
                      value="2"
                      type="radio"
                      name="radio2"
                      v-model="selectedValue"
                    />
                    <span class="radio-text">Воздержался</span>
                  </label>
                </li>
              </ul>
              <a
                href=""
                @click.prevent="goNext"
                :class="['main-btn', canGoNext ? '' : 'main-btn-disabled']"
                >{{ isLastQuestion ? "Завершить" : "Далее &gt;" }}</a
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "OssVote",
  data() {
    return {
      oss: {},
      isLoading: false,
      isCompleting: false,

      currentQuestionIndex: 0,
      selectedValue: null,
    };
  },
  mounted() {
    if (!this.authUser.accessOSS) {
      this.$router.replace({ name: "Home" });
      return;
    }
    this.getOSS();
  },
  methods: {
    setupQuestion() {
      let oss = this.oss;
      if (!oss || !oss.Questions) {
        return;
      }
      if (oss.IsComplete) {
        this.$router.replace({ name: "Oss" });
      }

      this.$store.dispatch("oss/startVote", this.idOss);

      this.currentQuestionIndex = -1;
      for (let i = 0; i < oss.Questions.length; i++) {
        if (oss.Questions[i].Answer === null) {
          this.currentQuestionIndex = i;
          break;
        }
      }
      if (this.currentQuestionIndex === -1) {
        this.$router.replace({ name: "Oss" });
      }
    },
    getOSS() {
      this.isLoading = true;
      this.$store
        .dispatch("oss/getOssById", this.idOss)
        .then((result) => {
          this.oss = result;
          this.isLoading = false;
          this.$nextTick(() => this.setupQuestion());
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    goNext() {
      if (!this.canGoNext) {
        return;
      }
      this.question.Answer = this.selectedValue;
      this.$store.dispatch("oss/saveAnswer", {
        ossId: this.idOss,
        questionId: this.question.ID,
        answer: this.selectedValue,
      });
      if (this.currentQuestionIndex + 1 < this.questionCount) {
        this.currentQuestionIndex++;
        this.selectedValue = null;
        return;
      }
      // Complete voting
      this.isCompleting = true;
      this.$store.dispatch("oss/completeVote", this.idOss).then(() => {
        // Go to results
        this.$router.replace({ name: "OssResult", params: { id: this.idOss } });
      });
    },
    goPrevious() {
      if (!this.canGoPrevious) {
        return;
      }
      if (this.selectedValue !== null) {
        this.question.Answer = this.selectedValue;
        this.$store.dispatch("oss/saveAnswer", {
          ossId: this.idOss,
          questionId: this.question.ID,
          answer: this.selectedValue,
        });
      }
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.selectedValue = this.question.Answer;
      }
    },
  },
  computed: {
    idOss() {
      return this.$route.params.id;
    },
    question() {
      var oss = this.oss;
      if (
        oss &&
        oss.Questions &&
        oss.Questions.length > 0 &&
        this.currentQuestionIndex < oss.Questions.length
      ) {
        return oss.Questions[this.currentQuestionIndex];
      }
      return { Text: "" };
    },
    questionCount() {
      var oss = this.oss;
      if (!oss || !oss.Questions) {
        return 0;
      }
      return oss.Questions.length;
    },
    questionNumber() {
      return this.currentQuestionIndex + 1;
    },
    isLastQuestion() {
      return this.questionNumber == this.questionCount;
    },
    canGoNext() {
      return this.selectedValue !== null;
    },
    canGoPrevious() {
      return this.currentQuestionIndex > 0;
    },
    progress() {
      let count = this.questionCount;
      if (count < 1) {
        return 0;
      }
      return (100 * (this.currentQuestionIndex + 1)) / count;
    },
  },
};
</script>

<style scoped></style>
