<template>
  <div>
    <div class="log-register_header">
      <div class="head-logo head-logo_mod">
        <template v-if="config.logoLink">
          <a :href="config.logoLink()">
            <img :src="config.appIconFile" alt="logo" />
          </a>
          <a :href="config.logoLink()">
            <span class="logo-title">{{ config.main_name }}</span>
          </a>
        </template>
        <template v-else>
          <img :src="config.appIconFile" alt="logo" />
          <span class="logo-title">{{ config.main_name }}</span>
        </template>
      </div>
      <div class="main-title">Личный кабинет</div>
    </div>
    <div class="log-register_box">
      <div class="password-recovery_panel">
        Регистрация.<span class="step">Шаг {{ step }}</span>
      </div>
      <div class="password-recovery_line">
        <span
          class="password-recovery_progress-bar"
          :style="{ width: step * 33.3333 + '%' }"
        ></span>
      </div>

      <!--Шаг 1-->
      <form v-on:submit.prevent class="unified-form" v-if="step === 1">
        <div class="form-group">
          <label class="form-label"
            ><span class="glipf-phone-2"></span>Телефон</label
          >
          <input
            name="phone"
            type="tel"
            ref="phoneControl"
            placeholder="+7 (999) 999 99 99"
            v-mask="`+7 (###) ### ## ##`"
            class="form-control"
            v-model="form.phone"
          />
        </div>
        <div class="form-group">
          <label class="form-label"
            ><span class="glipf-user-3"></span>ФИО</label
          >
          <input
            name="name"
            type="text"
            class="form-control"
            ref="fioControl"
            v-model="form.fio"
          />
        </div>
        <div v-if="config.requireBirthDate" class="form-group">
          <label class="form-label"
            ><span class="glipf-calendar"></span>Дата рождения</label
          >
          <input
            name="name"
            type="text"
            class="form-control"
            placeholder="дд.мм.гггг"
            v-mask="`##.##.####`"
            v-model="form.birthday"
          />
        </div>
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-6">
            <div class="consent-data_processing">
              <SwitchBtn v-model="consent" />
              Согласие на обработку персональных данных
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-6">
            <!-- :disabled="!isValidateStep1" -->
            <button class="main-btn further-btn" @click="nextSmsCode">
              <span class="text">Далее</span>
            </button>
          </div>
        </div>
        <br />
        <a
          class="personal_data-link"
          href=""
          @click.prevent="openPersonalDataPolitics"
          >Политика обработки персональных данных</a
        >
      </form>

      <!--Шаг 2-->
      <form v-on:submit.prevent class="unified-form" v-if="step === 2">
        <div class="form-group">
          <label class="form-label"
            ><span class="glipf-phone-2"></span>Код доступа</label
          >/
          <input
            type="text"
            class="form-control mask-code"
            v-model="form.code"
            v-mask="`####`"
            placeholder="0000"
          />
        </div>
        <div
          v-if="isShowResendMessage"
          class="log-register_resend-starus-message"
        >
          <p class=".log-register_resend-starus-text">
            Можно повторно отправить запрос кода через
          </p>
          <div class="log-register_resend-starus-timer">
            {{ codeResendTimer }}
          </div>
        </div>
        <p 
          v-if="showFlashCodeForm"
          class="text-normal"
        >
          Введите последние 4 цифры номера, 
          с которого поступил звонок на номер 
          <strong>{{ form.phone }}</strong>.
        </p>
        <template v-if="!showFlashCodeForm && form.code.length < 4">
          <p class="text-normal">
            Чтобы получить код доступа нажмите «Звонок с кодом». Вам позвонит
            робот на номер
            <span class="medium-text">{{ form.phone }}</span> и сообщит код либо
            нажмите "Код в SMS" для получения кода сообщением по SMS.
          </p>
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <button
                class="main-btn request_access-code_btn"
                :disabled="isDisabledSendButton"
                @click="getRequestAccessCode"
              >
                Звонок с кодом
              </button>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <button
                class="main-btn request_access-code_btn"
                :disabled="isDisabledSendButton"
                @click="getSMSCode"
              >
                Код в SMS
              </button>
            </div>
          </div>
        </template>
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-6">
            <p class="small-text text-arrow">
              <span class="medium-text">Не получили код?</span> Напишите в тех.
              поддержку, поможем зарегистрироваться.
            </p>
            <a
              href="#support"
              @click.prevent="openSupportModalChatWithousAuth"
              data-toggle="modal"
              class="write-support_btn"
              ><span class="glipf-user-2"></span
              ><span class="text">Написать в тех. поддержку</span></a
            >
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-6">
            <button
              class="main-btn further-btn"
              @click="checkSmsCode"
              :disabled="!(form.code.length >= 4)"
            >
              <span class="text">Далее</span>
            </button>
          </div>
        </div>
      </form>

      <!--Шаг 3-->
      <form v-on:submit.prevent class="unified-form" v-if="step === 3">
        <div class="form-group">
          <label class="form-label form-label_mod"
            ><span class="glipf-key"></span>Создайте пароль</label
          >
          <div class="wrapper_input-password">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              v-model="form.password"
            />
            <span
              @click="showPassword = !showPassword"
              class="glipf-view js_glipf-view"
            ></span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label form-label_mod"
            ><span class="glipf-key"></span>Подтвердите пароль</label
          >
          <div class="wrapper_input-password">
            <input
              :type="showRepeatPassword ? 'text' : 'password'"
              class="form-control"
              v-model="form.repeatPassword"
            />
            <span
              @click="showRepeatPassword = !showRepeatPassword"
              class="glipf-view js_glipf-view"
            ></span>
          </div>
        </div>
        <div class="wrapper_unified-form_submit main-btn">
          <span class="check-text"
            ><span class="glipf-check"></span>Зарегистрироваться</span
          >
          <input
            type="submit"
            class="unified-form_submit-btn"
            @click="register"
            value=""
            :disabled="!isValidateStep3"
          />
        </div>
      </form>
    </div>
    <p class="bottom-help_text">
      <!-- <span class="glipf-qustion"></span>Возникли проблемы?
      <a href="#support" @click.prevent="openSupportModalChatWithousAuth"
        >Напишите нам.</a
      >
      <br /> -->
      <router-link :to="{ name: 'Login' }" class="login-employee_btn"
        >Войти</router-link
      >
    </p>
  </div>
</template>

<script>
import SwitchBtn from "@/components/ui/SwitchBtn";
import {
  yearFromDate,
  monthFromDate,
  dayFromDate,
  daysInMonth,
} from "@/services/common";

export default {
  name: "Register",
  components: { SwitchBtn },
  data() {
    return {
      form: {
        phone: "",
        fio: "",
        password: "",
        repeatPassword: "",
        code: "",
        birthday: null,
      },
      codeResendTimer: 60,
      isDisabledSendButton: false,
      isShowResendMessage: false,

      showPassword: false,
      showRepeatPassword: false,
      consent: false,
      step: 1,
      error: "",
      isSending: false,
      showFlashCodeForm: true,
    };
  },
  mounted() {
    if (this.$route.query.phone && this.$route.query.phone.length === 10) {
      this.form.phone = this.$route.query.phone;
      this.$nextTick(() => this.$refs.fioControl.focus());
    } else {
      this.$nextTick(() => this.$refs.phoneControl.focus());
    }
  },
  methods: {
    nextSmsCode() {
      var error = this.step1ValidationError;
      if (!error) {
        ++this.step;
        this.getFlashCallCode();
      } else {
        this.$notify.error({
          position: "top-left",
          title: "Ошибка",
          message: error,
        });
      }
    },

    setCodeResendTimer() {
      this.isDisabledSendButton = true;
      this.isShowResendMessage = true;
      let timeout = 60000;
      let interval = setInterval(() => {
        this.codeResendTimer -= 1;
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        this.isDisabledSendButton = false;
        this.isShowResendMessage = false;
        this.codeResendTimer = 60;
        this.showFlashCodeForm = false;
      }, timeout);
    },

    getRequestAccessCode() {
      this.$store
        .dispatch("account/getRequestAccessCode", { phone: this.form.phone })
        .then(() => {
          this.setCodeResendTimer();
          this.$notify({
            position: "top-left",
            title: "Код регистрации",
            message: "Ожидайте звонка, робот сообщит Вам код регистрации",
          });
        });
    },

    getSMSCode() {
      this.$store
        .dispatch("account/sendCheckCodeSMS", { phone: this.form.phone })
        .then(() => {
          this.setCodeResendTimer();
          this.$notify({
            position: "top-left",
            title: "Код регистрации",
            message: "Проверочный код отправлен сообщением в SMS",
          });
        });
    },

    checkSmsCode() {
      this.$store
        .dispatch("account/checkAccessCode", {
          phone: this.form.phone,
          code: this.form.code,
        })
        .then((result) => {
          if (result.IsCorrect === true) {
            ++this.step;
          } else {
            this.$notify.error({
              position: "top-left",
              title: "Ошибка",
              message: "Код введён не корректно",
            });
          }
        });
    },

    //todo: do it
    register() {
      if (this.isValidateStep3) {
        this.isSending = true;

        this.$store
          .dispatch("account/registerByPhone", {
            ...this.form,
            phone: this.form.phone.match(/(\d+)/g).join(""),
          })
          .then((result) => {
            if (result.isSucceed === true) {
              this.isSending = false;
              this.login();
            }
          })
          .catch(() => {
            this.isSending = false;
          });
      }
    },

    login() {
      this.$store
        .dispatch("account/login", {
          phone: this.form.phone.match(/(\d+)/g).join(""),
          password: this.form.password,
        })
        .then(() => {
          location.href = location.href.split("#")[0];
        })
        .catch(() => {
          this.$router.push({ name: "Login" });
        });
    },

    getFlashCallCode() {
      this.$store
        .dispatch("account/sendCheckCodeFlashCall", { 
          phone: this.form.phone,
          dontCheck: true,
        })
        .then(() => {
          this.setCodeResendTimer();
          this.showFlashCodeForm = true;
        });
    },
  },
  computed: {
    step1ValidationError() {
      if (
        !this.form.fio ||
        !this.form.phone ||
        (this.config.requireBirthDate && !this.form.birthday)
      ) {
        return "Для продолжения необходимо заполнить все поля";
      }

      if (this.config.requireBirthDate) {
        if (this.form.birthday.length !== 10) {
          return "Указана неверная дата рождения";
        }

        // yearFromDate(this.form.birthday) > 1900 && yearFromDate(this.form.birthday) < new Date().getFullYear()
        var year = yearFromDate(this.form.birthday);
        if (year < 1900) {
          return "Год рождения не может быть меньше 1900";
        }
        if (year > new Date().getFullYear()) {
          return "Год рождения не может быть больше текущего";
        }

        var month = monthFromDate(this.form.birthday);
        if (month < 1) {
          return "Месяц рождения не может быть меньше 1";
        }
        if (month > 12) {
          return "Месяц рождения не может быть больше 12";
        }

        var day = dayFromDate(this.form.birthday);
        if (day < 1) {
          return "День даты рождения не может быть меньше 1";
        }
        var monthDays = daysInMonth(month, year);
        if (day > monthDays) {
          return (
            "День даты рождения в указанном месяце не может быть больше " +
            monthDays
          );
        }
      }

      if (!this.consent) {
        return "Для продолжения необходимо дать согласие на обработку персональных данных";
      }

      return "";
    },

    isValidateStep1() {
      return true;
    },

    isValidateStep3() {
      return (
        this.isValidateStep1 && this.form.password === this.form.repeatPassword
      );
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
button.main-btn
  border: 0

  &[disabled]
    opacity: 0.7

.log-register_resend-starus-message
  display: flex
  justify-content: space-between

.log-register_resend-starus-timer
  color: #fe0612
</style>
