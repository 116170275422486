<template>
  <div class="good-item col-xl-6 col-lg-6 col-md-12 col-sm-12">
    <div class="good-item__wrapper">
      <div class="good-item__desc">{{ good.Name }}</div>
      <div class="good-item__price-cell">
        <div class="good-item__price-header good-item__price-header--hidden">
          Цена
        </div>
        <div class="good-item__price">
          {{ good.Price ? good.Price.toFixed(2) : 0 }} ₽
        </div>
      </div>
      <div class="good-item__button-wrapper">
        <button
          class="good-item__plusminus-button"
          @click="addToCart(-1)"
          :class="{ 'good-item__plusminus-button--disabled': !isEqualZero }"
        >
          -
        </button>
        <input
          type="text"
          class="good-item__count-field"
          placeholder="0"
          :value="this.good.Quantity"
          @input="changeCart"
        />
        <button
          class="good-item__plusminus-button"
          @click="addToCart(1)"
          :class="{ 'good-item__plusminus-button--disabled': !isLessRest }"
        >
          +
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cart";
export default {
  name: "ShopItem",
  props: {
    good: {
      type: Object,
    },
    shopId: null,
  },
  mixins: [cartMixin],
};
</script>

<style scoped>
.good-item__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  background-color: white;
}
.good-item__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.good-item__price {
  font-size: 1.5rem;
}
.good-item__price-cell {
  margin-left: auto;
}
.good-item__price-header--hidden {
  color: #a9b2b7;
  font-weight: 300;
  font-size: 0.75em;
}
.good-item__plusminus-button {
  flex: 0 0 auto;
  height: 100%;
  width: 20%;
  background: white;
  border: none;
  font-size: 3em;
  color: #00bffe;
}

.good-item__plusminus-button--disabled {
  color: #d3d3d3;
}
.good-item__count-field {
  width: 60%;
  flex: 0 0 auto;
  text-align: center;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 5px;
  -moz-appearance: textfield;
  font-size: 1.5rem;
}
.good-item__img {
  width: 80%;
  grid-row: 1 / 3;
}
.good-item__desc {
  font-size: 1.1em;
  grid-row: 1 / 3;
  overflow-wrap: break-word;
}
.good-item__img img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  height: 0;
}
@media (max-width: 992px) {
  .good-item__price {
    font-size: 5vw;
  }
}
</style>
