import Vue from "vue";
import VueRouter from "vue-router";
import {
  redirectToHomeMiddleware,
  checkAccessMiddleware,
  setPageTitleMiddleware,
  checkMobileSettingsMiddleware,
} from "./middlewares";

//pages
import LoginPage from "../pages/Login";
import RegisterPage from "../pages/Register";
import IndexRouterPage from "../pages/IndexRoute";
import HomePage from "../pages/Home";
import NewsPage from "../pages/News";
import PollsPage from "../pages/Polls";
import ServicesPage from "../pages/Services";
import ShopPage from "../pages/Shop";
import ShopMobilePage from "../pages/ShopMobile";
import UserCartPage from "../pages/UserCart";
import UserCartMobilePage from "../pages/UserCartMobile";
import AnnouncementPage from "../pages/Announcement";
import ProfilePage from "../pages/Profile";
import PaymentPage from "../pages/Payment";
import RequestsPage from "../pages/Requests";
import RequestConfirmationPage from "../pages/RequestConfirmation";
import MetersPage from "../pages/Meters";
import OssInfoPage from "../pages/OssInfo";
import OssPage from "../pages/Oss";
import OssVotePage from "../pages/OssVote";
import OssResultPage from "../pages/OssResult";
//import MeetingBegan from '../pages/MeetingBegan'

import detectScreenMixin from "@/mixins/detectScreen";

Vue.use(VueRouter);

let isMobile = detectScreenMixin.methods.isMobile();

const routes = [
  {
    path: "/",
    name: "IndexRouterPage",
    component: IndexRouterPage,
    meta: { title: "Title" },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    meta: { title: "Регистрация", layout: "auth-layout" },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { title: "Авторизация", layout: "auth-layout" },
  },
  {
    path: "/home",
    name: "Home",
    component: HomePage,
    meta: { title: "Личный кабинет", isAuth: true },
  },
  {
    path: "/services",
    name: "Services",
    component: ServicesPage,
    props: true,
    meta: { title: "Специальные предложения", isAuth: true },
  },
  {
    path: "/services/:id/:type/:id_RequestType?/:AdditionalServiceId",
    name: "Shop",
    component: isMobile ? ShopMobilePage : ShopPage,
    props: true,
    meta: { title: "Магазин", isAuth: true },
  },
  {
    path: "/usercart/:id/:type/:id_RequestType?/:AdditionalServiceId",
    name: "UserCart",
    component: isMobile ? UserCartMobilePage : UserCartPage,
    props: true,
    meta: { title: "Корзина", isAuth: true },
  },
  {
    path: "/requestconfirmation/",
    name: "RequestConfirmation",
    component: RequestConfirmationPage,
    meta: { title: "Подтверждение заказа", isAuth: true },
  },
  {
    path: "/news/:id",
    name: "News",
    component: NewsPage,
    meta: { title: "Новость", isAuth: true },
  },
  {
    path: "/polls/",
    name: "Polls",
    component: PollsPage,
    meta: { title: "Опросы", isAuth: true },
  },
  {
    path: "/announcement/:id",
    name: "Announcement",
    component: AnnouncementPage,
    meta: { title: "Уведомление", isAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
    meta: { title: "Профиль", isAuth: true },
  },
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
    meta: { title: "Оплата", isAuth: true },
  },
  {
    path: "/requests/:requestID?",
    name: "Requests",
    component: RequestsPage,
    props: true,
    meta: { title: "Заявки", isAuth: true },
  },
  {
    path: "/meters",
    name: "Meters",
    component: MetersPage,
    meta: { title: "Показания", isAuth: true },
  },
  {
    path: "/oss",
    name: "Oss",
    component: OssPage,
    meta: { title: "Собрания собственников", isAuth: true },
  },
  {
    path: "/oss_info/:id",
    name: "OssInfo",
    component: OssInfoPage,
    meta: { title: "Собрание собственников", isAuth: true },
  },
  {
    path: "/oss_vote/:id",
    name: "OssVote",
    component: OssVotePage,
    meta: { title: "Собрание собственников. Голосование", isAuth: true },
  },
  {
    path: "/oss_result/:id",
    name: "OssResult",
    component: OssResultPage,
    meta: { title: "Собрание собственников. Результат", isAuth: true },
  },

  /*{
    path: '/oss',
    name: 'MeetingBegan',
    component: MeetingBegan,
    meta: { title: 'Собрание собственников' }
  },*/
];

const router = new VueRouter({
  routes,
});

router.beforeEach(checkAccessMiddleware);
router.beforeEach(redirectToHomeMiddleware);
router.beforeEach(checkMobileSettingsMiddleware);
router.beforeEach(setPageTitleMiddleware);

export default router;
