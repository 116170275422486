<template>
  <div :class="isMobile() ? 'c-modal-backdrop vm--overlay' : ''" v-show="show">
    <div class="support-modal-chat">
      <button
        class="close popup-form_close"
        type="button"
        @click="$emit('close')"
      >
        <span class="glipf-remove"></span>
      </button>

      <div class="wrapper_application-content">
        <div class="application_main-content">
          <perfect-scrollbar
            ref="dialogListScroll"
            class="scroll-content scroll-content_application-content"
          >
            <div
              v-loading="isLoadingMessages"
              v-for="(messagesArray, date) in messages"
              :key="`list-date-${date}`"
            >
              <div class="wrapper_application-date">
                <span class="application-date">{{ date }}</span>
              </div>
              <div
                v-for="message in messagesArray"
                :key="`message-${message.ID}`"
                :class="[
                  'application-item_mod',
                  { 'right-content': message.IsSelf },
                ]"
              >
                <div class="application_time-user">
                  <div class="application-user_name" v-if="!message.IsSelf">
                    <span class="application-user_icon"
                      ><img :src="resolveUrl('/img/icons/admin.png')" alt="alt"
                    /></span>
                    {{ message.AuthorName }}
                  </div>
                  <span class="application-time">{{ message.Added }}</span>
                  <span class="application-user_icon" v-if="message.IsSelf">
                    <img :src="resolveUrl('/img/icons/user.png')" alt="alt" />
                  </span>
                </div>
                <div
                  :class="[
                    'text-part_application',
                    { 'red-bg': message.IsSelf },
                  ]"
                >
                  <template v-if="message.FileID > 0">
                    <a
                      @click="getFile(message.FileID)"
                      style="cursor: pointer"
                      >{{ message.Text }}</a
                    >
                  </template>
                  <template v-else>{{ message.Text }}</template>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <!-- Message form -->
        <form v-on:submit.prevent class="form-message">
          <label class="glipf-clip" style="cursor: pointer">
            <input
              type="file"
              @change="addFile"
              name="file"
              style="display: none"
            />
          </label>
          <input
            type="text"
            :disabled="isSendingMessage"
            v-model="form.message"
            @keyup.enter="addMessage"
            class="form-mesage_input"
            placeholder="Введите сообщение.."
          />
          <div class="wrapper_form-message_submit" v-loading="isSendingMessage">
            <!-- :disabled="form.message.length === 0" -->
            <input
              type="submit"
              @click="addMessage"
              value=""
              class="form-message_submit"
            />
            <span class="glipf-message-3"></span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "supportModalChat",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      windowWidth: 0,
      dialogListScroll: null,
      isLoadingMessages: false,
      isSendingMessage: false,
      refreshTimer: null,
      // форма отправки
      form: {
        message: null,
        file: null,
      },
      phone: "",
    };
  },

  created() {
    this.getMessages();
  },

  mounted() {
    this.calcWindowWidth();
    window.addEventListener("resize", this.calcWindowWidth);

    this.$nextTick(() => this.scrollToBottom());
    this.timer = setInterval(() => {
      this.update();
    }, 5000);
  },

  destroyed() {
    clearInterval(this.timer);
  },

  computed: {
    messages() {
      return this.$store.getters["common/getSortedMessages"];
    },

    isValidPhone() {
      return (
        this.phone.length && this.phone.match(/(\d+)/g).join("").length < 10
      );
    },
  },

  methods: {
    calcWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    onConfirmPhone() {
      this.form.phone = this.phone.match(/(\d+)/g).join("");
    },
    getMessages() {
      this.isLoadingMessages = true;
      this.$store
        .dispatch("common/getMessages", {
          deviceID: localStorage.getItem("deviceID"),
          info: navigator.userAgent,
        })
        .then(() => (this.isLoadingMessages = false))
        .catch(() => {
          this.isLoadingMessages = false;
          this.$notify.error("Ошибка загрузки сообщений");
        });
    },

    addMessage() {
      if (this.form.message.length > 0) {
        this.isSendingMessage = true;
        this.$store
          .dispatch("common/addMessage", {
            text: this.form.message,
            deviceID: localStorage.getItem("deviceID"),
          })
          .then(() => {
            this.form.message = "";
            this.isSendingMessage = false;
            this.getMessages();
          })
          .catch(() => {
            this.isSendingMessage = false;
            this.$notify.error("Ошибка отправки файла");
          });
      }
    },

    addFile(event) {
      let fd = new FormData();
      fd.append("file", event.target.files[0]);
      fd.append("deviceID", localStorage.getItem("deviceID"));
      this.$store
        .dispatch("common/addFile", fd)
        .then(() => {
          this.getMessages();
        })
        .catch(() => {
          this.$notify.error("Ошибка загрузки файла");
        });
    },

    getFile(id) {
      this.$store.dispatch("common/getFile", {
        id,
        deviceID: localStorage.getItem("deviceID"),
      });
    },

    update() {
      this.$store
        .dispatch("common/updateMessages", {
          deviceID: localStorage.getItem("deviceID"),
          info: navigator.userAgent,
        })
        .catch(() => {
          this.$notify.error("Ошибка загрузки сообщений");
        });
    },

    scrollToBottom() {
      try {
        this.$refs.dialogListScroll.$el.scrollTop = this.$refs.dialogListScroll.$el.scrollHeight;
      } catch (e) {
        console.log(e);
      }
    },
  },

  watch: {
    messages(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        this.$nextTick(() => this.scrollToBottom());
      }
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.support-modal-chat
  position: fixed
  right: 10px
  top: 70px
  z-index: 99

  max-width: 480px
  margin: 1rem auto
  padding: 3em 2em 1em 2em

  background-color: #ffffff
  border-radius: 50px
  border: 1px solid var(--base-color)

.support-modal-chat .scroll-content
  padding-left: 6px

.support-modal-chat .application_main-content
  box-shadow: unset

.text-part_application
  width: none
  min-width: 85%
  max-width: 85%
  word-wrap: break-word

.application_time-user
  width: none
  min-width: 85%
  max-width: 85%
  margin-bottom: 0px

.support-modal-chat .application-user_icon
  flex-shrink: 0
</style>

<style scoped>
.support-modal-chat {
  box-shadow: 0 8px 92.1px 6.9px rgb(0 0 0 / 19%);
}

@media only screen and (max-width: 767px) {
  .support-modal-chat {
    position: relative;
    right: 0;
    top: 0;
  }
}
</style>
