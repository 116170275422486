<template>
  <li>
    <router-link
      :to="to"
      :class="{ 'breadcrumbs__link--disabled': !to }"
      class="breadcrumbs__link"
      ><slot></slot>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "BreadcrumbsItem",

  props: {
    to: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>

<style></style>
