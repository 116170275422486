<template>
  <main class="main-content">
    <div class="container">
      <a @click="$router.go(-1)" style="cursor:pointer" class="back-page_btn"
        >Назад</a
      >
      <h1 class="main-inner_title">Уведомление</h1>
      <article class="news-detailed">
        <h3 class="section-title">{{ announce.Header }}</h3>
        <span class="news-detailed_date">{{ announce.Created }}</span>
        <p v-html="announce.Text"></p>
        <ul class="news-detailed_files-list">
          <li
            v-for="(file, index) in announce.Files"
            :key="file.Name + index"
            class="news-detailed_file"
          >
            <a :href="file.Link" class="news-detailed_download-link">
              <div>
                <span class="news-detailed_download-img glipf-download"></span>
              </div>
              <div class="news-detailed_download-link-text">
                {{ file.Description }}
              </div>
            </a>
          </li>
        </ul>
      </article>
    </div>
  </main>
</template>

<script>
export default {
  name: "Announcement",
  mounted() {
    this.getEventBlockData();
  },
  methods: {
    getEventBlockData() {
      this.$store.dispatch("news/getEventBlockData");
    },
  },
  computed: {
    idAnnounce() {
      return this.$route.params.id;
    },
    announce() {
      return this.$store.getters["announcement/getAnnouncementById"](
        this.idAnnounce
      );
    },
  },
  watch: {
    $route() {
      this.getNews();
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped></style>
