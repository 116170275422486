<template>
  <Modal title="Закрытие заявки" @close="$emit('close')">
    <form v-on:submit.prevent class="application-form" v-loading="isSending">
      <div class="application-form_header">
        Закрытие заявки №{{requestNumber}}
      </div>
      <div class="application-form_header">
        Оцените качество выполнения заявки:
        <template v-if="config.styleOfEvaluation === 'stars'">
          <table class="markTable">
              <tbody>
                  <tr>
                      <td 
                        v-for="i in [1,2,3,4,5]" 
                        :key="`evaluation-${i}`"
                        :class="['markitem', {'markActive': form.mark === i}]"
                        :aria-label="i"
                        @click="form.mark = i"
                      >{{ i }}</td>
                  </tr>
              </tbody>
          </table>
        </template>
        <template v-if="config.styleOfEvaluation === 'smile'">
          <div class="smile-evaluation">
            <div
              v-for="(emoji, key) in smiles"
              :key="`evaluation-${key}`"
              :class="['smile-evaluation__item', {'smile-evaluation__item--active': form.mark === key}]"
              @click="form.mark = key"
            >
              <img :src="emoji" :alt="key" />
            </div>
          </div>
        </template>
      </div>
      <div class="form-group">
        <textarea v-model="form.text" class="form-textarea" placeholder="Комментарий к закрываемой заявке..."></textarea>
      </div>
      <input type="submit"
             @click="closeRequest"
             class="application-form_submit-btn main-btn"
             value="закрыть">
    </form>
  </Modal>
</template>

<script>
  import Modal from "@/components/Modal";

  export default {
    name: "closeRequestModal",
    components: {Modal},
    props: {
      requestId: {
        type: String,
        default: undefined
      },
      requestNumber: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        form: {
          requestId: this.requestId,
          mark: 5,
          text: '',
        },

        isSending: false,
        smiles: {
          1: '/img/emoji/angry_sad.svg', // 😡 angry
          3: '/img/emoji/thinking.svg', // 😐 neutral
          5: '/img/emoji/stars.svg', // 😍 hearteyes
        }
      }
    },
    mounted() {
      this.form.requestId = this.requestId;
    },
    methods: {
      closeRequest() {
        this.isSending = true

        this.$store.dispatch('request/closeRequest', this.form)
          .then(() => {
            this.isSending = false
            this.$root.$emit('new-request', -1)
            this.$emit('close')
          })
          .catch(() => {
            this.isSending = false
          })
      },
    },
  }
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
  .el-select
    position: relative
    top: -3px
    margin-left: 10px
</style>

<style lang="scss">
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}
</style>