import newRequestModal from "@/components/modals/newRequestModal";
import addAccountIdentModal from "@/components/modals/addAccountIdentModal";
import addMeterModal from "@/components/modals/addMeterModal";
import payModal from "@/components/modals/payModal";
import closeRequestModal from "@/components/modals/closeRequestModal";
import contactSupportModal from "@/components/modals/contactSupportModal";
import supportModalChat from "@/components/modals/supportModalChat";
import emailForPayModal from "@/components/modals/emailForPayModal";
import personalDataPoliticsModal from "@/components/modals/personalDataPoliticsModal";
import modalWrapper from "@/components/modals/modalWrapper";
import supportModalChatWithousAuth from "@/components/modals/supportModalChatWithousAuth";

const baseModalConfig = {
  width: window.innerWidth > 717 ? "717px" : "100%",
  height: "auto",
  scrollable: true,
};

export default {
  methods: {
    openModalWrapper() {
      this.$modal.show(
        modalWrapper,
        {},
        ...baseModalConfig,
      )
    },

    openNewRequestModal() {
      this.$modal.show(
        newRequestModal,
        {},
        {
          ...baseModalConfig,
        }
      );
    },
    openAddAccountIdentModal() {
      this.$modal.show(
        addAccountIdentModal,
        {},
        {
          ...baseModalConfig,
        }
      );
    },
    openAddMeterModal(id) {
      this.$modal.show(
        addMeterModal,
        { id },
        {
          ...baseModalConfig,
        }
      );
    },
    openPayModal(id) {
      this.$modal.show(
        payModal,
        { id },
        {
          ...baseModalConfig,
        }
      );
    },
    closeRequestModal(requestId, requestNumber) {
      this.$modal.show(
        closeRequestModal,
        { requestId, requestNumber },
        {
          ...baseModalConfig,
        }
      );
    },
    contactSupportModal() {
      this.$modal.show(
        contactSupportModal,
        {},
        {
          ...baseModalConfig,
        }
      );
    },
    supportModalChat() {
      this.$modal.show(
        supportModalChat,
        {},
        {
          left: "1343px",
          width: window.innerWidth > 717 ? "480px" : "100%",
          height: "auto"
        }
      );
    },
    openSupportModalChatWithousAuth() {
      this.$modal.show(
        supportModalChatWithousAuth,
        {},
        {
          left: "1343px",
          width: window.innerWidth > 717 ? "480px" : "100%",
          height: "auto",
          scrollable: true
        }
      );
    },
    openEnterEmailForPay(ident) {
      this.$modal.show(
        emailForPayModal,
        { ident },
        {
          ...baseModalConfig,
        }
      );
    },
    openPersonalDataPolitics() {
      this.$modal.show(
        personalDataPoliticsModal,
        {},
        {
          ...baseModalConfig,
        }
      );
    },
  },
};
