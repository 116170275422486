// import { normalizeData } from '../../services/common';
// import * as announcementsApi from "@/api/announcement";

const state = {
  announcements: {},
};

const actions = {}

const getters = {
  getAnnouncementById: (state) => (id) => state.announcements[id] || {},
  getAnnouncements: (state) => (list) => list.map(id => state.announcements[id] || {}),
};

const mutations = {
  setAnnouncements(state, data) {
    state.announcements = {
      ...state.announcements,
      ...data
    };
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
};
