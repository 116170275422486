/**
 ******************************
 * @methods
 ******************************
 */

export function getAccessToken() {
	return localStorage.getItem('accessToken')
}

export function resetAuthData() {
	localStorage.removeItem('accessToken')
	setUserData({})
}

export function setAuthData(token) {
	localStorage.setItem('accessToken', token)
}

export function setUserData(data) {
	localStorage.setItem('user', JSON.stringify(data))
}

export function getUserData() {
	let user = localStorage.getItem('user');

	if(user && user.length > 0) {
		return JSON.parse(user);
	}

	return {};
}

export function isAuthUser() {
	return !!(getUserData().login && getUserData().login.length > 0)
}

export function hasPermission(permission_code) {
	if(!isAuthUser()) return false

	return getUserData().permissions.some(permission => permission.code === permission_code)
}
