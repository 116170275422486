<template>
  <footer class="main-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <div class="footer-title">{{ config.main_name }}</div>
          <div class="footer-requisites"></div>
        </div>
        <div class="col-sm-6">
          <ul class="footer_applications">
            <li v-if="config.appLinkIOS"><a :href="config.appLinkIOS"><img :src="resolveUrl('/img/static/application/01.svg')" alt="AppStore"></a></li>
            <li v-if="config.appLinkAndroid"><a :href="config.appLinkAndroid"><img :src="resolveUrl('/img/static/application/02.svg')" alt="GooglePlay"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "FooterBlock"
  }
</script>

<style lang="sass" rel="stylesheets/sass" scoped>

</style>
