var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poll"},[_c('button',{staticClass:"close popup-form_close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"glipf-remove"})]),_c('h2',{staticClass:"main-inner_title poll__name"},[_vm._v(_vm._s(_vm.poll.Name))]),_c('div',{staticClass:"poll__question"},[_c('p',{staticClass:"poll__question-text",domProps:{"innerHTML":_vm._s(_vm.currentQuestion.Text)}}),(
        _vm.currentQuestion.AnswerType === 'single' ||
          _vm.currentQuestion.AnswerType === null
      )?_c('div',{staticClass:"poll__answers"},_vm._l((_vm.currentQuestion.Answers),function(answer){return _c('label',{key:("answer-" + (answer.ID))},[_c('input',{attrs:{"type":"radio","name":"pollSingleAnswer","disabled":_vm.currentQuestion.IsCompleteByUser},domProps:{"checked":_vm.isChecked({
              QuestionId: _vm.currentQuestion.ID,
              AnswerId: answer.ID,
            })},on:{"change":function($event){return _vm.setSingleAnswer($event, {
              QuestionId: _vm.currentQuestion.ID,
              AnswerId: answer.ID,
              CustomAnswer: null,
            })}}}),_vm._v(" "+_vm._s(answer.Text)+" ")])}),0):_vm._e(),(_vm.currentQuestion.AnswerType === 'multiple')?_c('div',{staticClass:"poll__answers"},_vm._l((_vm.currentQuestion.Answers),function(answer){return _c('label',{key:("answer-" + (answer.ID))},[_c('input',{attrs:{"type":"checkbox","name":"pollMultipleAnswer","disabled":_vm.currentQuestion.IsCompleteByUser},domProps:{"checked":_vm.isChecked({
              QuestionId: _vm.currentQuestion.ID,
              AnswerId: answer.ID,
            })},on:{"change":function($event){return _vm.setMultipleAnswer($event, {
              QuestionId: _vm.currentQuestion.ID,
              AnswerId: answer.ID,
              CustomAnswer: null,
            })}}}),_vm._v(" "+_vm._s(answer.Text)+" ")])}),0):_vm._e(),(_vm.currentQuestion.AnswerType === 'open')?_c('div',{staticClass:"poll__answers"},[_c('textarea',{staticClass:"poll__open-answer",attrs:{"readonly":_vm.currentQuestion.IsCompleteByUser},domProps:{"value":_vm.getOpenAnswer(_vm.currentQuestion.ID)},on:{"input":function($event){return _vm.setOpenAnswer({
            QuestionId: _vm.currentQuestion.ID,
            AnswerId: null,
            CustomAnswer: $event.target.value,
          })}}})]):_vm._e()]),_c('div',{staticClass:"poll__buttons"},[_c('div',{staticClass:"poll_button-wrapper"},[(_vm.selectedQuestionIndex > 0)?_c('span',{staticClass:"poll__button main-btn",on:{"click":function($event){_vm.selectedQuestionIndex -= 1}}},[_c('span',{staticClass:"poll__back-ico"}),_c('span',[_vm._v("Назад")])]):_vm._e()]),_c('div',{staticClass:"poll_button-wrapper"},[(
          _vm.selectedQuestionIndex == _vm.poll.Questions.length - 1 &&
            _vm.hasAnswer &&
            !_vm.currentQuestion.IsCompleteByUser
        )?_c('span',{staticClass:"poll__button main-btn",on:{"click":_vm.savePoll}},[_c('span',[_vm._v("Завершить")])]):_vm._e(),(
          _vm.selectedQuestionIndex == _vm.poll.Questions.length - 1 &&
            _vm.currentQuestion.IsCompleteByUser
        )?_c('span',{staticClass:"poll__button main-btn",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v("Закрыть")])]):(
          _vm.currentQuestion.IsCompleteByUser ||
            (_vm.selectedQuestionIndex != _vm.poll.Questions.length - 1 && _vm.hasAnswer)
        )?_c('span',{staticClass:"poll__button main-btn",on:{"click":function($event){_vm.selectedQuestionIndex += 1}}},[_c('span',[_vm._v("Далее")]),_c('span',{staticClass:"poll__next-ico"})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }