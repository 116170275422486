<template>
  <div class="shop">
    <slot>
      <router-link
        :to="{
          name: 'Shop',
          params: {
            id: shop.ShopID,
            AdditionalServiceId: shop.ID,
            type: shop.ShopType,
            id_RequestType: shop.id_RequestType,
            breadcrumbs: [
              {
                text: `${serviceName}`,
                route: {
                  name: 'Services',
                  params: { serviceId: shop.ID },
                },
              },
              {
                text: `${shop.Name}`,
                route: '',
              },
            ],
          },
          props: {},
        }"
      >
        <img
          v-if="shop.HasLogo"
          :src="shop.LogoLink"
          :alt="shop.Name"
          class="shop__img"
        />
        <div class="shop__desc">{{ shop.Name }}</div>
      </router-link>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ServiceItem",
  props: {
    shop: {
      type: Object,
      require: true,
    },
    serviceName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.shop {
  padding: 10px;
}
.shop__img {
  display: inline-block;
  width: 100%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.shop__desc {
  font-size: 1rem;
}
@media (max-width: 576px) {
  .shop__desc {
    font-size: 0.7rem;
  }
}
</style>
