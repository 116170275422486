<template>
  <div>
    <div class="log-register_header">
      <div class="head-logo head-logo_mod">
        <template v-if="config.logoLink">
          <a :href="config.logoLink()">
            <img :src="config.appIconFile" alt="logo" />
          </a>
          <a :href="config.logoLink()">
            <span class="logo-title">{{ config.main_name }}</span>
          </a>
        </template>
        <template v-else>
          <img :src="config.appIconFile" alt="logo" />
          <span class="logo-title">{{ config.main_name }}</span>
        </template>
      </div>
      <div class="main-title">Личный кабинет</div>
    </div>

    <div class="log-register_box">
      <div class="switch-panel">
        Вход по номеру телефона
      </div>
      <form @submit.prevent="login" class="unified-form">
        <div class="login-info">
          Если Вы ранее входили по № лицевого счета или логину,
          зарегистрируйтесь по № телефона и подключите свой лицевой счет
        </div>
        <div class="form-group">
          <label class="form-label"
            ><span class="glipf-phone-2"></span>Телефон</label
          >
          <input
            name="phone"
            type="tel"
            placeholder="+7 (999) 999 99 99"
            ref="loginControl"
            v-mask="`+7 (###) ### ## ##`"
            class="form-control"
            v-model="form.login"
          />
        </div>
        <div class="form-group">
          <label class="form-label"
            ><span class="glipf-key"></span>Пароль</label
          >
          <div class="wrapper_input-password">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              v-model="form.password"
              ref="passwordControl"
            />
            <span
              @click="showPassword = !showPassword"
              class="glipf-view js_glipf-view"
            ></span>
          </div>
        </div>
        <div class="wrapper_unified-form_submit main-btn">
          <span class="text">Войти</span>
          <input
            type="submit"
            class="unified-form_submit-btn"
            value=""
            :disable="isSending || isValidate"
          />
        </div>
      </form>
      <div class="log-register_footer">
        <el-popover trigger="click" placement="top">
          Для восстановления пароля пройдите повторную регистрацию
          <a
            slot="reference"
            style="cursor: pointer;"
            class="forgot-password_btn"
            >Забыли пароль?</a
          >
        </el-popover>
        <router-link :to="{ name: 'Register' }" class="register-btn"
          >Зарегистрироваться</router-link
        >
      </div>
      <!-- <p class="bottom-help_text">
        <span class="glipf-qustion"></span>Возникли проблемы с личным кабинетом?
        <a href="" @click.prevent="openSupportModalChatWithousAuth"
          >Напишите нам.</a
        >
        <br />
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        login: "",
        password: "",
      },

      showPassword: false,
      error: "",
      isSending: false,
    };
  },
  mounted() {
    if (this.$route.query.phone && this.$route.query.phone.length === 10) {
      this.form.login = this.$route.query.phone;
      this.$nextTick(() => this.$refs.passwordControl.focus());
    } else {
      this.$nextTick(() => this.$refs.loginControl.focus());
    }
  },
  methods: {
    login() {
      if (this.isValidate) {
        this.isSending = true;
        this.error = "";

        this.$store
          .dispatch("account/login", {
            phone: this.form.login.match(/(\d+)/g).join(""),
            password: this.form.password,
          })
          .then(() => {
            this.isSending = false;
            location.href = location.href.split("#")[0];
          })
          .catch((error) => {
            this.isSending = false;
            if (error.response && error.response.status === 401) {
              this.error = "Не верная пара логин/пароль";

              this.$notify.error({
                position: "top-left",
                title: "Ошибка",
                message: "Не верная пара логин/пароль",
              });
            } else {
              this.error = "Ошибка.";
              this.$notify.error({
                position: "top-left",
                title: "Ошибка",
                message: "",
              });
            }
          });
      }
    },
  },
  computed: {
    isValidate() {
      return this.form.login.length > 0 && this.form.password.length > 0;
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.error
  padding: 5px
  text-align: center
  color: darkred
</style>
