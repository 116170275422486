import * as configApi from "../../api/config";

const state = {
  mobileAppSettings: {},
};

const getters = {
  getMobileAppSettings(state) {
    return state.mobileAppSettings;
  },
  getMenuVisibilitySettings(state) {
    if (!state.mobileAppSettings?.menu) {
      // default
      return {
        Requests: false,
        Payment: false,
        Meters: false,
        Polls: false,
        Bills: false,
        Services: false,
      };
    }
    return {
      Requests: !!state.mobileAppSettings.menu.find(
        (x) => x.name_app === "Заявки" && x.visible
      ),

      Payment: !!state.mobileAppSettings.menu.find(
        (x) => x.name_app === "Оплата ЖКУ" && x.visible
      ),
      Meters: !!state.mobileAppSettings.menu.find(
        (x) =>
          ["Показания счетчиков", "Показания приборов"].includes(x.name_app) &&
          x.visible
      ),
      Polls: !!state.mobileAppSettings.menu.find(
        (x) => x.name_app === "Опросы" && x.visible
      ),
      Bills: !!state.mobileAppSettings.menu.find(
        (x) => x.name_app === "Квитанции" && x.visible
      ),
      Services: state.mobileAppSettings.showOurService,
    };
  },
  getDisableCommentingRequests(state) {
    if (!state.mobileAppSettings) {
      // default
      return false;
    }

    return !!state.mobileAppSettings.disableCommentingRequests;
  },
};

const mutations = {
  setMobileAppSettings(state, data) {
    state.mobileAppSettings = {
      ...data,
    };
  },
};
const actions = {
  getMobileAppSettings({ commit }) {
    return configApi
      .getMobileAppSettings()
      .then((response) => {
        if (response.status === 200) {
          commit("setMobileAppSettings", response.data);
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
