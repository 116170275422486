import account from "./account";
import config from "./config";
import news from "./news";
import polls from "./polls";
import announcement from "./announcement";
import additionalService from "./additionalService";
import request from "./request";
import meter from "./meter";
import oss from "./oss";
import common from "./common";

export default {
  account,
  config,
  news,
  polls,
  announcement,
  additionalService,
  request,
  meter,
  oss,
  common,
};
