<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div class="main_single-column unified-box">
          <div class="shop__header header flex_start">
            <breadcrumbs :items="currentBreadcrumbs" />
          </div>
          <div class="shop__header header">
            <div class="header__cell">
              <router-link class="application-item_btn" :to="previousRoute">
              </router-link>
              <router-link :to="previousRoute">
                <span>Назад</span>
              </router-link>
            </div>
            <div class="header__cell">
              <div class="header__cartdata">
                <div class="header__cell">
                  <router-link
                    :disabled="cartIsEmpty"
                    :event="!cartIsEmpty ? 'click' : ''"
                    :to="{
                      name: 'UserCart',
                      params: $route.params,
                    }"
                  >
                    <span class="header__ico glipf-cart"></span>
                  </router-link>
                </div>
                <div v-if="isGoodsType" class="header__cell">
                  <div>Вес:</div>
                  <div class="cart-payment__summary--bolded">
                    {{ cartWeight }} кг
                  </div>
                </div>
                <div class="header__cell">
                  <div>Итого цена:</div>
                  <div class="cart-payment__summary--bolded">
                    {{ cartCost }} ₽
                  </div>
                  <div v-if="isGorserv" class="cart-payment__min-summary">
                    Мин. сумма заказа 1000р.
                  </div>
                </div>
                <div class="header__payment-button">
                  <router-link
                    :disabled="cartIsEmpty"
                    :event="!cartIsEmpty ? 'click' : ''"
                    :to="{
                      name: 'UserCart',
                      params: nextRouteParams('Shop', 'Оформление заказа'),
                    }"
                  >
                    <a
                      class="main-btn personal-account_item-btn"
                      :class="{ 'main-btn-disabled': cartIsEmpty }"
                      >Оформить заказ</a
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <vue-slick-carousel
            v-if="categories.length > 0"
            :centerMode="true"
            :arrows="true"
            :slidesToShow="slidesToShow"
            :focusOnSelect="true"
          >
            <div
              v-for="(category, index) in categories"
              :key="`good-category-${index}`"
              class="good-category__item text-center good-category__item_bolded"
              :class="{
                'good-category__item_selected': selectedCategory == category,
              }"
              @click="selectedCategory = category"
            >
              <span v-text="category"></span>
            </div>
          </vue-slick-carousel>
          <div
            class="shop__sorter"
            @click="sortDirection = sortsSwitch[sortDirection].name"
          >
            <span
              class="glipf-sort-ico"
              :class="{ 'shop__sort-ico_rotated': sortDirection === 'asc' }"
            ></span>
            <span> по цене</span>
          </div>
          <div class="goods-list">
            <shop-item
              v-for="good in goods"
              :key="`good good__item_${good.ID}`"
              :good="good"
            >
            </shop-item>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ShopItem from "@/components/ui/ShopItem";
import cartMixin from "@/mixins/cart";
import additionalServiceMixin from "@/mixins/additionalService";

import Breadcrumbs from "@/components/breadcrumbs/TheBreadcrumbs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Shop",
  components: { ShopItem, Breadcrumbs },
  props: {
    breadcrumbs: Array,
  },
  mixins: [additionalServiceMixin, cartMixin],
  data() {
    return {
      selectedCategory: "",
      searchText: "",
      cart: [],
      sortDirection: "asc",
      sortsSwitch: {
        asc: { name: "desc", sortFn: (a, b) => a.Price - b.Price },
        desc: { name: "asc", sortFn: (a, b) => b.Price - a.Price },
      },
    };
  },
  computed: {
    ...mapGetters({ categories: "additionalService/getCategories" }),
    currentBreadcrumbs() {
      if (this.$route.params.breadcrumbs) {
        return this.$route.params.breadcrumbs;
      }
      if (this.additionalService.servicesByGroups.length == 0) {
        return [];
      }
      return [
        this.defineServiceBreadcrumb(),
        { text: this.getShopName(), route: "" },
      ];
    },
    goods() {
      return this.additionalService.goods
        .filter((item) => {
          if (this.selectedCategory) {
            return item.Categories.includes(this.selectedCategory);
          }
          return true;
        })
        .filter((item) => {
          if (this.searchText) {
            return (
              item.Name.toUpperCase().search(this.searchText.toUpperCase()) > -1
            );
          }
          return true;
        })
        .sort(this.sortsSwitch[this.sortDirection].sortFn);
    },
    previousRoute() {
      if (this.$route.params.breadcrumbs) {
        let index = this.$route.params.breadcrumbs.length - 2;
        return this.$route.params.breadcrumbs[index].route;
      }
      return {
        name: "Services",
        params: { serviceId: this.$route.params.AdditionalServiceId },
      };
    },
    slidesToShow() {
      let slides = 3;
      if (window.innerWidth < 576) {
        slides = 1;
      } else if (window.innerWidth < 768) {
        slides = 2;
      }
      return slides;
    },
  },
  mounted() {
    this.getGoods(this.$route.params.id);
    window.scrollTo(0, 0);
    if (this.additionalService.servicesByGroups.length == 0) {
      this.getEventBlockData();
    }
  },
  methods: {
    ...mapActions({ getGoods: "additionalService/getGoods" }),
  },
};
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
.application-item_btn {
  cursor: pointer;
  position: relative;
  transform: rotate(225deg);
  right: 0;
  width: 15px;
  height: 15px;
}
.slick-arrow {
  width: 20px;
  height: 20px;
}
.shop__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.flex_start {
  justify-content: flex-start;
}
.shop__sorter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.9rem;
  padding: 0 1em;
  margin: 0 1em;
  cursor: pointer;
}
.shop__sort-ico {
  transition: transform 0.5s;
}
.shop__sort-ico_rotated {
  transform: scale(1, -1);
}
.header__cartdata {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header__cell:first-child {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.header__cell {
  padding: 0 15px;
}
.shop__header_spcbtw {
  justify-content: space-between;
}
.glipf-cart {
  font-size: 2.5rem;
}
.goods-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.goods-list__search {
  padding: 10px 10px;
}
.goods-list__search input {
  height: 100%;
}
.goods-list__search_theme_grey {
  border: 1px solid #ccc;
  border-radius: 3px;
}
.good-category__item {
  font-size: 1.2rem;
  margin: auto 0;
}
@media (max-width: 992px) {
  .header__cartdata {
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: fixed;
    background-color: white;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  .good-category__item {
    font-size: 1rem;
  }
  .main-btn {
    min-width: auto;
  }
}
.good-category__item_bolded {
  font-weight: 600;
}
.good-category__item_selected {
  border-bottom: 4px solid #00bffe;
}
.cart-payment__min-summary {
  color: #a9b2b7;
  font-weight: 300;
  font-size: 0.75em;
}
</style>
