import { normalizeData } from "../../services/common";
import * as meterApi from "@/api/meter";

const state = {
  meters: {},
  meterValues: {},
};

const actions = {
  getMetersList({ commit }) {
    return meterApi
      .getMetersList()
      .then((response) => normalizeData(response.data.Data, "ID"))
      .then((result) => {
        commit("setMeters", result.list);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  },

  getMeterValues({ commit }, { meterUniqueNum }) {
    return meterApi
      .getMeterValues({ meterUniqueNum })
      .then((response) => {
        commit("setMeterValues", response.data.Data);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  saveMeterValue(context, { ID, Value, ValueT2, ValueT3 }) {
    return meterApi
      .saveMeterValue({ ID, Value, ValueT2, ValueT3 })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  deleteMeterValue(context, { ID }) {
    return meterApi
      .deleteMeterValue({ ID })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  createRequestOnMeterRecheck(context, ID) {
    return meterApi
      .createRequestOnMeterRecheck({ ID })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
};

const getters = {
  getMeterById: (state) => (id) => state.meters[id] || {},
  getMeters: (state) => (list) => list.map((id) => state.meters[id] || {}),
};

const mutations = {
  setMeters(state, data) {
    state.meters = {
      ...state.meters,
      ...data,
    };
  },

  setMeterValues(state, data) {
    state.meterValues = { ...data };
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
