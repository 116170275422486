<template>
  <ul class="breadcrumbs">
    <breadcrumbs-item
      v-for="(item, index) in items"
      :key="'breadcrumbs_item_' + index"
      :to="item.route"
      class="breadcrumbs__item"
      >{{ item.text }}</breadcrumbs-item
    >
  </ul>
</template>

<script>
import BreadcrumbsItem from "./BreadcrumbsItem.vue";
export default {
  name: "TheBreadcrumbs",

  components: {
    BreadcrumbsItem,
  },

  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style>
/*START Хлебные крошки Breadcrumbs*/

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  color: #989898;
}

.breadcrumbs__item {
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}

.breadcrumbs__item:last-child {
  margin-right: 0;
}

.breadcrumbs__item:after {
  content: "";
  display: block;
  width: 1px;
  height: 12px;
  background: #989898;
  position: absolute;
  top: 4px;
  right: -3px;
  transform: skew(-12deg);
}

.breadcrumbs__item:last-child:after {
  display: none;
}

.breadcrumbs__link {
  color: inherit;
  transition: color 0.3s;
}

.breadcrumbs__link:hover {
  color: #00a0e3;
}

.breadcrumbs__link--disabled,
.breadcrumbs__link--disabled:hover {
  color: #989898 !important;
  cursor: default;
}

.breadcrumbs__link.router-link-exact-active {
  color: #333333 !important;
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .breadcrumbs {
    padding-right: 1.5em;
  }
}
/*END  Хлебные крошки Breadcrumbs*/
</style>
