import { normalizeData } from "../../services/common";
import * as requestApi from "@/api/request";

const state = {
  requests: [],
  messages: {},
  updateKey: null,
};

const actions = {
  getRequestsList({ commit }) {
    return (
      requestApi
        .getRequestsList()
        .then((response) => {
          commit("setRequests", response.data.Requests);
          commit("setUpdateKey", response.data.UpdateKey);
          return response.data.Requests;
        })
        /*.then((response) => {
        return {
          updateKey: response.data.UpdateKey,
          requests: normalizeData(response.data.Requests, "ID"),
        };
      })*/
        /*.then((result) => {
        commit("setRequests", result.requests.list);
        commit("setUpdateKey", result.updateKey);
        return result.requests;
      })*/
        .catch((error) => {
          throw error;
        })
    );
  },

  getRequestDetails({ commit }, id) {
    return requestApi
      .getRequestDetails(id)
      .then((response) => {
        let data = response.data;
        let result = normalizeData(data.Messages, "ID");
        commit("setMessages", result.list);

        return {
          ...data,
          Messages: result.keys,
        };
      })
      .catch((error) => {
        throw error;
      });
  },

  getRequestTypes() {
    return requestApi
      .getRequestTypes()
      .then((response) => {
        let data = response.data.Data;

        return data;
      })
      .catch((error) => {
        throw error;
      });
  },

  addMessage(context, { RequestId, Text }) {
    return requestApi
      .addMessage({ RequestId, Text })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  newRequest(context, data) {
    return requestApi
      .newRequest({
        ident: data.ident,
        typeID: data.typeID,
        subTypeID: data.subTypeID,
        detailSubTypeID: data.detailSubTypeID,
        text: data.text,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  newShopRequest(context, data) {
    return requestApi
      .newShopRequest(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  closeRequest(context, { requestId, mark, text }) {
    return requestApi
      .closeRequest({
        requestId,
        mark,
        text,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  update({ getters, commit, dispatch }, currentRequestId) {
    var updateKey = getters["getUpdateKey"]();
    if (!updateKey) {
      return null;
    }
    return (
      requestApi
        .getUpdates(updateKey, currentRequestId)
        .then((response) => {
          return response.data;
        })
        .then((result) => {
          // Update current
          if (result.CurrentRequestUpdates) {
            let normalizedResult = normalizeData(
              result.CurrentRequestUpdates.Messages,
              "ID"
            );
            commit("setMessages", normalizedResult.list);
          }

          if (result.NewUpdateKey) {
            commit("setUpdateKey", result.NewUpdateKey);
          }

          // Update list
          if (result.CurrentRequestUpdates) {
            return dispatch("getRequestsList").then(() => {
              return {
                current: result.CurrentRequestUpdates,
                hasChanges: result.UpdatedRequests.length > 0,
              };
            });
          }

          return {
            current: result.CurrentRequestUpdates,
            hasChanges: result.UpdatedRequests.length > 0,
          };
          /*if(result.UpdatedRequests.length){
          dispatch('getRequestsList');
        }*/
        })
        /*.then(result => {
        commit('setRequests', result.requests.list, result.updateKey);
        return result.requests
      })*/
        .catch((error) => {
          throw error;
        })
    );
  },
  checkRequestSumIsVerified(context, id) {
    return requestApi.checkRequestSumIsVerified(id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendPaidRequestCompleteCodeOnline(context, payload) {
    return requestApi.sendPaidRequestCompleteCodeOnline(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendPaidRequestCompleteCodeCash(context, payload) {
    return requestApi.sendPaidRequestCompleteCodeCash(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPaidRequestAllowedBonuses(context, id) {
    return requestApi.getPaidRequestAllowedBonuses(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
};

const getters = {
  getRequestById: (state) => (id) => state.requests[id] || {},

  getMessageById: (state) => (id) => state.messages[id] || {},
  getMessages: (state) => (list) => list.map((id) => state.messages[id] || {}),
  getUpdateKey: (state) => () => state.updateKey,
};

const mutations = {
  setRequests(state, data) {
    state.requests = [...data];
  },

  setUpdateKey(state, data) {
    state.updateKey = data;
  },

  setMessages(state, data) {
    state.messages = {
      ...state.messages,
      ...data,
    };
  },

  /*updateMessages(state, data) {
    state.messages = {
      ...state.messages,
      ...data
    };
  },  */
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
