<template>
  <main class="log-register_content">
    <div class="log-register_left-column">
      <div class="log-register_left-column_body">
        <slot />
      </div>
      <footer class="main-footer_mod tablet_small-hidden">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12 col-sm-6">
            <div class="footer_name-company">
              {{ config.main_name || 'ООО "Название"' }}
            </div>
            <div class="copyright">&#169;{{ copyrightYear }}</div>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-6">
            <a :href="'tel:' + config.clearPhone" class="footer_phone-number"
              ><span class="glipf-phone"></span>{{ config.phone || "" }}</a
            >
          </div>
        </div>
      </footer>
    </div>
    <div class="log-reister_right-column">
      <div
        ref="sliderContainer"
        :class="{
          'log-reister_right-column_container': true,
          'log-reister_right-column_container__nomock': sliderSlidesCount < 1,
        }"
      >
        <div>
          <el-carousel
            class="preview-slider"
            :interval="6000"
            :style="{
              width: widthSliderItem + 'px',
              'margin-top': sliderTop + 'px',
            }"
            :height="heightSliderItem + 'px'"
            v-if="sliderSlidesCount > 0"
          >
            <el-carousel-item
              class="preview-item"
              v-for="number in sliderSlidesCount"
              :key="number"
            >
              <div class="preview-item_img">
                <img :src="getMockupLink(number)" alt="alt" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="log-reister_right-column_container_buttons">
          <ul class="footer_applications footer_applications_mod">
            <li v-if="config.appLinkIOS">
              <a :href="config.appLinkIOS"
                ><img
                  :src="resolveUrl('/img/static/application/01.svg')"
                  alt="AppStore"
              /></a>
            </li>
            <li v-if="config.appLinkAndroid">
              <a :href="config.appLinkAndroid"
                ><img
                  :src="resolveUrl('/img/static/application/02.svg')"
                  alt="GooglePlay"
              /></a>
            </li>
          </ul>
        </div>
        <div class="log-reister_right-column_powered_by_sm">
          Работает на технологиях
          <a target="_blank" href="https://sm-center.ru">sm-center.ru</a>.
          <br />
          Управление МКД - программы и CRM системы
          <br />
          для РСО, УО, ТСН, ТСЖ.
        </div>
      </div>
      <div class="main-footer_mod tablet_small-visible">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-12 col-sm-6">
            <div class="footer_name-company">
              {{ config.main_name || 'ООО "Название"' }}
            </div>
            <div class="copyright">&#169;{{ copyrightYear }}</div>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-6">
            <a :href="'tel:' + config.clearPhone" class="footer_phone-number"
              ><span class="glipf-phone"></span>{{ config.phone || "" }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import * as commonApi from "@/api/common";

export default {
  name: "LoginRegisterLayout",
  data() {
    return {
      heightSliderItem: 0,
      widthSliderItem: 0,
      sliderTop: 0,
      sliderSlidesCount: 0,
      sliderContainerEl: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.sliderContainerEl = this.$refs.sliderContainer;
      this.applyConfiguration();
      window.addEventListener("resize", () => {
        this.applyConfiguration();
      });
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.applyConfiguration);
  },

  watch: {
    config() {
      this.applyConfiguration();
    },
  },

  methods: {
    applyConfiguration() {
      if (!this.config) {
        this.calculateSliderPositions(0, 1, 1);
        return;
      }
      this.calculateSliderPositions(
        this.config.MockupCount,
        this.config.MockupMaxWidth,
        this.config.MockupMaxHeight
      );
    },
    calculateSliderPositions(count, width, height) {
      this.sliderSlidesCount = count;
      var freeWidth = this.sliderContainerEl.clientWidth - 100;
      var freeHeight = window.innerHeight - 170;
      var finalWidth = width;
      var finalHeight = height;
      if (finalWidth <= freeWidth && finalHeight <= freeHeight) {
        // we're ok
      } else {
        // resize
        var widthScale = freeWidth / finalWidth;
        var heightScale = freeHeight / finalHeight;
        var scale = Math.min(widthScale, heightScale);
        finalWidth *= scale;
        finalHeight *= scale;
      }

      this.heightSliderItem = finalHeight;
      this.widthSliderItem = finalWidth + 100;
      this.sliderTop = (freeHeight - finalHeight) / 2;
      this.sliderSlidesCount = count;
    },
    getMockupLink(number) {
      return commonApi.getMockupDownloadLink(number);
    },
  },
};
</script>

<style lang="scss" rel="stylesheets/scss">
.preview-slider {
  padding-bottom: 45px;
}

.el-carousel__container {
  /*height: auto;*/
  /*position: unset;*/
}
</style>
