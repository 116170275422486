<template>
  <div class="flex-wrap">
    <HeaderBlock @supportModalChat="onSupportModalChat" />
    <slot />
    <FooterBlock />
    <support-modal-chat
      :show="showSupportModalChat"
      @close="showSupportModalChat = false"
    />
  </div>
</template>

<script>
import HeaderBlock from "@/layouts/HeaderBlock";
import FooterBlock from "@/layouts/FooterBlock";
import supportModalChat from "@/components/modals/supportModalChat";

export default {
  name: "Page",
  components: { FooterBlock, HeaderBlock, supportModalChat },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSupportModalChat: false,
    };
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$root.$emit("page-loading", newValue);
      }
    },
  },
  methods: {
    onSupportModalChat() {
      this.showSupportModalChat = !this.showSupportModalChat;
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
@media (min-width: 1281px)
  .flex-wrap
    display: -webkit-flex
    display: -moz-flex
    display: -ms-flex
    display: -o-flex
    display: flex
    flex-direction: column
    min-height: 100vh
    transition: min-height 0.1s ease-in-out
</style>
