<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div class="main_left-column">
          <div class="main-title main-title_other">Оплата</div>
          <template v-if="!accountInfoIDs || accountInfoIDs.length">
            <div class="payment-header">
              <div class="section-title white-title">
                <span class="glipf-clock"></span>История платежей
              </div>
            </div>
            <div class="">
              <div class="payment-history_box">
                <div class="personal-account_number">
                  Лицевой счёт:
                  <el-select
                    v-model="selectedIdent"
                    filterable
                    style="width: 140px; margin-left: 10px;"
                  >
                    <el-option
                      v-for="(info, i) in paymentAccountInfo"
                      :value="info.Ident"
                      :label="info.Ident"
                      :key="`select-bill-${i}`"
                    >
                      (Л/с: {{ info.Ident }}) {{ info.Address }}
                    </el-option>
                  </el-select>
                </div>
                <div class="payment-history_tabs">
                  <button
                    class="payment-history_tab"
                    :class="{
                      'payment-history_tab--active':
                        activeHistoryTab === 'accounts',
                    }"
                    @click="activeHistoryTab = 'accounts'"
                  >
                    ЖКУ
                  </button>
                  <button
                    v-if="
                      accountInfo &&
                        accountInfo.filter((item) => item.HasInsurance).length
                    "
                    class="payment-history_tab"
                    :class="{
                      'payment-history_tab--active':
                        activeHistoryTab === 'insurances',
                    }"
                    @click="activeHistoryTab = 'insurances'"
                  >
                    Cтрахование
                  </button>
                </div>
                <div class="wrapper_payment-history_table">
                  <table class="payment-history_table">
                    <tr>
                      <th>Дата</th>
                      <th></th>
                      <th>Сумма</th>
                      <th></th>
                    </tr>

                    <template v-if="activeHistoryTab === 'accounts'">
                      <tr
                        v-for="(payment, index) in pendingPayments[
                          selectedIdent
                        ]"
                        :key="`pendingPayments-${index}`"
                      >
                        <td>{{ payment.Date }}</td>
                        <td class="payment-history_table-pending">
                          обрабатывается...
                        </td>
                        <td>
                          <span class="sum">{{ payment.Sum }}</span
                          ><span class="rouble">руб.</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr
                        v-for="(payment, index) in payments[selectedIdent]"
                        :key="`payments-${index}`"
                      >
                        <td>{{ payment.Date }}</td>
                        <td></td>
                        <td>
                          <span class="sum">{{ payment.Sum }}</span
                          ><span class="rouble">руб.</span>
                        </td>
                        <td>
                          <a
                            :href="getCheckLink(payment)"
                            v-if="payment.HasCheck"
                            style="cursor: pointer"
                            class="receipt_download_btn"
                          >
                            <span class="glipf-download"></span>
                          </a>
                        </td>
                      </tr>
                    </template>

                    <template v-if="activeHistoryTab === 'insurances'">
                      <tr
                        v-for="(insurance, index) in insurances[selectedIdent]"
                        :key="`insurances-${index}`"
                      >
                        <td>{{ insurance.Date }}</td>
                        <td></td>
                        <td>
                          <span class="sum">{{ insurance.Sum }}</span
                          ><span class="rouble">руб.</span>
                        </td>
                        <td>
                          <a
                            :href="getCheckLink(insurance)"
                            v-if="insurance.HasCheck"
                            style="cursor: pointer"
                            class="receipt_download_btn"
                          >
                            <span class="glipf-download"></span>
                          </a>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="main_right-column">
          <template v-if="!accountInfoIDs || accountInfoIDs.length">
            <!--Лицевые счета-->
            <div class="personal-accounts_top-panel">
              <div class="section-title white-title">Лицевые счета</div>
              <a
                @click="openAddAccountIdentModal"
                style="cursor: pointer"
                class="connect-account_btn"
              >
                + Подключить лицевой счёт
              </a>
            </div>
            <div
              class="personal-account_item none"
              v-if="accountInfo && accountInfo.length <= 0"
            >
              <div class="personal-account_item-header">Нет счетов</div>
            </div>
            <vue-slick-carousel
              v-if="paymentAccountInfo && paymentAccountInfo.length"
              ref="accountsCarousel"
              v-bind="personalAccountsSlider"
              class="personal-accounts_slider"
              style="margin-bottom: 15px"
            >
              <div
                v-for="info in paymentAccountInfo"
                class="personal-account_item"
                :key="`account-info-${info.AccountID}`"
              >
                <div class="personal-account_item-header">
                  <div class="left-column">
                    <div class="personal-account_item-number">
                      Л/сч: <span class="item-digit">№{{ info.Ident }}</span>
                    </div>
                    <span class="personal-account_item-adress">{{
                      info.AccountType
                    }}</span
                    ><br />
                    <span class="personal-account_item-adress">{{
                      info.Address
                    }}</span>
                  </div>
                  <div class="right-column">
                    <span
                      class="personal-account_item-delete"
                      @click="deleteAccountInfo(info.Ident)"
                    >
                      <span class="glipf-remove"></span
                      ><span class="text">Удалить</span>
                    </span>
                  </div>
                </div>
                <div class="personal-account_item-body">
                  <div class="left-column">
                    Сумма к оплате
                    <span class="item-date">на {{ info.DebtActualDate }}:</span>
                  </div>
                  <div class="personal-account_item-sum">
                    {{ info.Sum }} <span class="rouble">руб.</span>
                  </div>
                </div>
                <a
                  class="main-btn personal-account_item-btn"
                  style="cursor: pointer;"
                  @click="payForIdent(info.AccountID)"
                  >Оплатить &#62;</a
                >
                <div class="item_bottom-text">
                  Платеж обрабатывается <strong>2-3</strong> рабочих дня.
                </div>
              </div>
            </vue-slick-carousel>

            <div
              class="personal-accounts_top-panel"
              v-if="accountInfo && accountInfo.length > 1"
            >
              <div></div>
              <button
                v-if="canPayForAllAccounts"
                class="personal-account_payall-btn main-btn"
                @click="payForIdent(null)"
                style="border: 0"
              >
                Оплатить все
              </button>
            </div>

            <!-- Страховка -->
            <!-- Обязательно проверяем длину отфильтрованного списка по полю страховки -->
            <vue-slick-carousel
              v-if="
                accountInfo &&
                  accountInfo.filter((item) => item.HasInsurance).length > 0
              "
              v-bind="personalAccountsSlider"
              class="personal-accounts_slider"
            >
              <div
                class="personal-account_item insurance-account_item"
                v-for="info in accountInfo.filter((item) => item.HasInsurance)"
                :key="`insurance-info-${info.AccountID}`"
              >
                <div
                  class="personal-account_item-header"
                  style="border-bottom: none"
                >
                  <div class="left-column">
                    <div class="personal-account_item-number">
                      <span class="item-digit"
                        >Страховой полис с {{ info.InsurancePeriodStart }} по
                        {{ info.InsurancePeriodEnd }}</span
                      >
                    </div>
                    <div class="personal-account_item-number">
                      Л/сч: <span class="item-digit">№{{ info.Ident }}</span>
                    </div>
                    <span class="personal-account_item-adress">{{
                      info.Address
                    }}</span>
                  </div>
                </div>

                <div>
                  <a
                    href="https://sm-center.ru/vsk_polis.pdf"
                    target="_blank"
                    class="insurance-account_link"
                    >Посмотреть полис</a
                  >
                </div>
              </div>
            </vue-slick-carousel>

            <!--Квитанции-->
            <div v-if="getMenuVisibilitySettings.Bills" class="receipts-panel">
              <div class="section-title">
                <span class="glipf-receipts"></span>Квитанции
              </div>
              <ul class="filter_receipts">
                <li
                  :class="[
                    'filter_receipt-item',
                    {
                      active: sortField === 'SortableDate',
                      reverseOrder:
                        (sortField === 'SortableDate' && sortOrder === -1) ||
                        sortField !== 'SortableDate',
                    },
                  ]"
                  @click="switchSort('SortableDate', -1)"
                >
                  <span class="text noselect">По дате</span>
                </li>
                <li
                  :class="[
                    'filter_receipt-item',
                    {
                      active: sortField === 'Ident',
                      reverseOrder: sortField === 'Ident' && sortOrder === -1,
                    },
                  ]"
                  @click="switchSort('Ident', 1)"
                >
                  <span class="text noselect">По л/сч</span>
                </li>
              </ul>
            </div>
            <div
              v-if="getMenuVisibilitySettings.Bills"
              class="wrapper_receipts-table"
            >
              <table class="receipts-table">
                <tr>
                  <th>Лицевой счёт</th>
                  <th>Дата</th>
                  <th>Сумма к оплате с учетом долга</th>
                  <th></th>
                </tr>
                <tr v-for="bill in bills" :key="`bills-list-${bill.ID}`">
                  <td>№ {{ bill.Ident }} ({{ bill.Address }})</td>
                  <td>{{ bill.Period }}</td>
                  <td>
                    <span class="receipt-sum"
                      >{{ bill.Total }} <span class="rouble">руб.</span></span
                    >
                  </td>
                  <td>
                    <template v-if="loadingBills.indexOf(bill.ID) < 0">
                      <!-- <a download v-if="bill.HasFile"
                          @click="getBillFile(bill)"
                          style="cursor: pointer" class="receipt_download_btn">
                          <span class="glipf-download"></span>                        
                        </a>       -->
                      <a
                        :href="getBillLink(bill)"
                        v-if="bill.HasFile"
                        style="cursor: pointer"
                        class="receipt_download_btn"
                      >
                        <span class="glipf-download"></span>
                      </a>
                    </template>
                    <template v-else>
                      <div class="spinner">
                        <div class="rect1"></div>
                        <div class="rect2"></div>
                        <div class="rect3"></div>
                        <div class="rect4"></div>
                        <div class="rect5"></div>
                      </div>
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </template>
          <template v-else>
            <div class="adress-box">
              <div class="common-warning-text">Лицевые счета не подключены</div>
              <a @click="openAddAccountIdentModal" class="common-warning-link">
                + Подключить лицевой счёт
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
//vuex
import { mapGetters } from "vuex";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import * as commonApi from "@/api/common";
import download from "downloadjs";

export default {
  name: "Payment",
  data() {
    return {
      accountInfoIDs: null,
      selectedIdent: null,
      isLoading: false,
      activeHistoryTab: "accounts",

      personalAccountsSlider: {
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        rows: 1,
      },

      sortField: "SortableDate",
      sortOrder: -1, // 1 asc, -1 desc

      loadingBills: [],
    };
  },
  mounted() {
    this.initPage();
    setTimeout(this.showPaymentResult, 1000);
    if (this.authUser.accessOSS) {
      this.$router.push("home");
    }
  },
  created() {
    window.addEventListener("resize", this.changeSliderOptions);
    this.$root.$on("updated-account-info", this.initPage);
    this.$root.$on("deleted-account-info", this.deleteLocalAccountInfo);
  },
  destroyed() {
    window.removeEventListener("resize");
    this.$root.$off("updated-account-info");
    this.$root.$off("deleted-account-info");
  },

  methods: {
    payForIdent(id) {
      this.openPayModal(id);
    },

    showPaymentResult() {
      if (location.hash.match(/paymentSuccess$/)) {
        this.$notify.success({
          title: "Оплата",
          message: "Оплата прошла успешно",
          duration: 6000,
          offset: 100,
        });
        location.hash = "/payment";
      }

      if (location.hash.match(/paymentFail$/)) {
        this.$notify.error({
          title: "Оплата",
          message: "Оплата не прошла",
          duration: 6000,
          offset: 100,
        });
        location.hash = "/payment";
      }
    },

    initPage() {
      this.getAccountInfo();
      this.changeSliderOptions();
    },
    changeSliderOptions() {
      this.personalAccountsSlider.slidesToShow =
        window.innerWidth > 1100 ? 2 : 1;
    },
    getAccountInfo() {
      this.isLoading = true;

      this.$store
        .dispatch("account/getInfo")
        .then((result) => {
          this.accountInfoIDs = [...result.keys];
          this.isLoading = false;
        })
        .then(() => {
          if (
            this.accountInfo.length > 0 &&
            this.accountInfo[0].Bills.length > 0
          ) {
            this.selectedIdent = this.accountInfo[0].Bills[0].Ident;
          }
        })
        .then(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch(() => (this.isLoading = false));
    },

    getBillLink(bill) {
      return commonApi.getBillsDownloadLink(bill.ID);
    },

    getCheckLink(payment) {
      return commonApi.getCheckDownloadLink(payment.ID);
    },

    getBillFile(bill) {
      this.loadingBills.push(bill.ID);
      commonApi
        .getBillsDownload(bill.ID)
        .then((response) => {
          var index = this.loadingBills.indexOf(bill.ID);
          if (index > -1) {
            this.loadingBills.splice(index, 1);
          }
          var fileName = `Квитанция-${bill.ID}.pdf`;
          if (response.headers && response.headers["content-disposition"]) {
            var text = response.headers["content-disposition"];
            fileName = text.substring(
              text.indexOf('filename="') + 10,
              text.lastIndexOf('"')
            );
          }
          download(response.data, fileName, response.headers["content-type"]);
        })
        .catch((error) => {
          var index = this.loadingBills.indexOf(bill.ID);
          if (index > -1) {
            this.loadingBills.splice(index, 1);
          }
          this.$notify.error({
            title: "Скачивание квитанции",
            message: "Не удалось скачать квитанцию. Повторите попытку позже.",
            duration: 6000,
            offset: 100,
          });
          if (!error) {
            return;
          }
        });
    },

    deleteAccountInfo(ident) {
      if (confirm("Действительно удалить адрес (Л/С " + ident + ")?")) {
        this.$store.dispatch("account/deleteAccountByIdent", ident).then(() => {
          this.accountInfo
            .filter((x) => x.Ident === ident)
            .forEach((x) => {
              this.$root.$emit("deleted-account-info", x.AccountID);
            });
        });
      }
    },

    deleteLocalAccountInfo(id) {
      if (!this.accountInfoIDs) {
        return;
      }
      let index = this.accountInfoIDs.indexOf(id);
      if (index !== -1) this.accountInfoIDs.splice(index, 1);
    },

    switchSort(fieldName, defaultOrder) {
      if (this.sortField === fieldName) {
        this.sortOrder = -this.sortOrder;
      } else {
        this.sortField = fieldName;
        this.sortOrder = defaultOrder;
      }
    },
  },
  computed: {
    ...mapGetters({
      getMenuVisibilitySettings: "config/getMenuVisibilitySettings",
      canPayForAllAccounts: 'account/canPayForAllAccounts',
      getAccountSettings: 'account/getAccountSettings',
      getInfos: 'account/getInfos',
    }),
    
    accountInfo() {
      if (!this.accountInfoIDs) {
        return [];
      }

      return this.getInfos(this.accountInfoIDs) || [];
    },

    paymentAccountInfo() {
      return this.accountInfo.filter((account) => {
        return !this.getAccountSettings(account.AccountID).HideOnPaymentsPage;
      });
    },

    billsAccountInfo() {
      return this.accountInfo.filter((account) => {
        return !this.getAccountSettings(account.AccountID).HideOnBillsPage;
      });
    },

    uniqueAccounts() {
      if (!this.accountInfoIDs) {
        return null;
      }
      return this.$store.getters["account/getInfos"](this.accountInfoIDs)
        .map((x) => {
          return { Ident: x.Ident, Address: x.Address };
        })
        .filter(
          (x, i, self) => self.findIndex((t) => t.Ident == x.Ident) === i
        );
    },

    //квитанции
    bills() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo
        .filter((account) => {
          return !this.getAccountSettings(account.AccountID).HideOnBillsPage;
        })
        .reduce((oldEl, item) => {
          if (!item.Bills) {
            return [];
          }

          let newEl = item.Bills.map((bill) => {
            let dateParts = bill.Date.split(".");
            return {
              ...bill,
              SortableDate: (dateParts[2] + dateParts[1] + dateParts[0]) * 1,
              Address: item.Address,
            };
          });

          return [...oldEl, ...newEl];
        }, [])
        .sort((a, b) => {
          if (a[this.sortField] < b[this.sortField]) return -this.sortOrder;
          if (a[this.sortField] > b[this.sortField]) return this.sortOrder;
          return 0;
        });
    },

    payments() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo.reduce((newEl, item) => {
        if (!newEl[item.Ident]) {
          newEl[item.Ident] = item.Payments;
        } else {
          newEl[item.Ident] = [...newEl[item.Ident], ...item.Payments];
        }
        return newEl;
      }, {});
    },
    pendingPayments() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo.reduce((newEl, item) => {
        if (!newEl[item.Ident]) {
          newEl[item.Ident] = item.PendingPayments;
        } else {
          newEl[item.Ident] = [...newEl[item.Ident], ...item.PendingPayments];
        }
        return newEl;
      }, {});
    },

    insurances() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo.reduce((newEl, item) => {
        if (!newEl[item.Ident]) {
          newEl[item.Ident] = item.InsurancePayments;
        } else {
          newEl[item.Ident] = [...newEl[item.Ident], ...item.InsurancePayments];
        }
        return newEl;
      }, {});
    },
  },
};
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
.personal-account_item.none {
  min-height: 300px;
  max-width: 300px;
}
</style>

<style lang="scss">
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}
</style>
