<template>
  <div class="cart-item">
    <div
      class="cart-item__description cart-item__cell--flex20 cart-item__cell--01rem"
    >
      {{ good.Name }}
    </div>
    <div class="cart-item__cell cart-item__cell--flex20 cart-item__cell--01rem">
      <div class="cart-item__price">{{ good.Price.toFixed(2) }} ₽</div>
    </div>
    <div
      class="cart-item__button-wrapper cart-item__cell--flex20 cart-item__cell--01rem"
    >
      <button
        class="cart-item__plusminus-button"
        @click="addToCart(-1)"
        :class="{ 'cart-item__plusminus-button--disabled': !isEqualZero }"
      >
        -
      </button>
      <input
        type="text"
        class="cart-item__count-field"
        placeholder="0"
        :value="good.Quantity"
        @input="changeCart"
      />
      <button
        class="cart-item__plusminus-button"
        @click="addToCart(1)"
        :class="{ 'cart-item__plusminus-button--disabled': !isLessRest }"
      >
        +
      </button>
    </div>
    <div
      v-if="isGoodsType"
      class="cart-item__cell cart-item__cell--flex20 cart-item__cell--01rem"
    >
      {{ (good.Weight * good.Quantity).toFixed(1) }} кг
    </div>
    <div
      class="cart-item__cell cart-item__cell--flex20 cart-item__cell--01rem cart-item__cell_bolded"
    >
      {{ (good.Price * good.Quantity).toFixed(2) }} ₽
    </div>
  </div>
</template>

<script>
import cartMixin from "@/mixins/cart";
export default {
  name: "CartItem",
  props: {
    good: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  mixins: [cartMixin],
};
</script>

<style scoped>
.cart-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 0 10px 10px 10px;
  padding: 10px;
  background-color: white;
}
.cart-item__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.cart-item__cell--01rem {
  font-size: 1rem;
}
.cart-item__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.cart-item__cell--flex20 {
  flex: 0 0 20%;
}
.cart-item__description {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto 0;
}
.cart-item__cell_merged {
  grid-column: 2 / 0;
}
.cart-item__price-header--hidden {
  color: #a9b2b7;
  font-weight: 300;
  font-size: 0.75em;
}
.cart-item__cell_bolded {
  font-weight: 600;
}
.cart-item__plusminus-button {
  flex: auto;
  height: 100%;
  width: 20%;
  background: white;
  border: none;
  font-size: 3em;
  color: #00bffe;
  padding: 5px;
}
.cart-item__plusminus-button--disabled {
  color: #d3d3d3;
}
.cart-item__count-field {
  width: 70%;
  flex: auto;
  text-align: center;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 10px;
}
.cart-item__img img {
  object-fit: contain;
  max-width: 50%;
}
@media (max-width: 992px) {
  .cart-item {
    margin: 5px 0;
    padding: 5px;
  }

  .cart-item__plusminus-button {
    font-size: 5vw;
  }
  .cart-item__cell--01rem {
    font-size: 3vw;
  }
  .cart-item__img img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
