import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=2c0e1446&scoped=true&"
import script from "./Payment.vue?vue&type=script&lang=js&"
export * from "./Payment.vue?vue&type=script&lang=js&"
import style0 from "./Payment.vue?vue&type=style&index=0&id=2c0e1446&lang=scss&rel=stylesheets%2Fscss&scoped=true&"
import style1 from "./Payment.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0e1446",
  null
  
)

export default component.exports