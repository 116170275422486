//import { part } from 'core-js/fn/function';
import {models} from './models'

export const normalizeData = (arr, key) => {
  key = key || 'id';

  let list = arr.reduce((newEl, item) => {
    if (/^[0-9]+$/.test(item.id)) {
      item.id = parseInt(item.id)
    }

    newEl[item[key]] = item;
    return newEl
  }, {});

  let keys = arr.reduce((pv, cv) => {
    pv.push(cv[key]);
    return pv;
  }, []);


  return {list, keys}
};

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}});
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {[key]: source[key]});
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const diffArray = (a1, a2) => {
  return a1.filter(i => !a2.includes(i))
    .concat(a2.filter(i => !a1.includes(i)))
}

function emptyTarget(val) {
  return Array.isArray(val) ? [] : {}
}

function mergeObject(target, source, optionsArgument) {
  let destination = {};
  if (isMergeableObject(target)) {
    Object.keys(target).forEach(function (key) {
      destination[key] = cloneIfNecessary(target[key], optionsArgument)
    })
  }
  Object.keys(source).forEach(function (key) {
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument)
    } else {
      destination[key] = deepmerge(target[key], source[key], optionsArgument)
    }
  });
  return destination
}

function isMergeableObject(val) {
  let nonNullObject = val && typeof val === 'object';

  return nonNullObject
    && Object.prototype.toString.call(val) !== '[object RegExp]'
    && Object.prototype.toString.call(val) !== '[object Date]'
}

function cloneIfNecessary(value, optionsArgument) {
  let clone = optionsArgument && optionsArgument.clone === true;
  return (clone && isMergeableObject(value)) ? deepmerge(emptyTarget(value), value, optionsArgument) : value
}

function defaultArrayMerge(target, source, optionsArgument) {
  let destination = target.slice();
  source.forEach(function (e, i) {
    if (typeof destination[i] === 'undefined') {
      destination[i] = cloneIfNecessary(e, optionsArgument)
    } else if (isMergeableObject(e)) {
      destination[i] = deepmerge(target[i], e, optionsArgument)
    } else if (target.indexOf(e) === -1) {
      destination.push(cloneIfNecessary(e, optionsArgument))
    }
  });
  return destination
}

export const initializeData = (entities) => {
  let data = {};
  entities.forEach(entity => {
    data[entity.name] = {
      data: {},
    };
  });

  return data;
};

export function deepmerge(target, source, optionsArgument) {
  let array = Array.isArray(source);
  let options = optionsArgument || {arrayMerge: defaultArrayMerge};
  let arrayMerge = options.arrayMerge || defaultArrayMerge;

  if (array) {
    return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument)
  } else {
    return mergeObject(target, source, optionsArgument)
  }
}

deepmerge.all = function deepmergeAll(array, optionsArgument) {
  if (!Array.isArray(array) || array.length < 2) {
    throw new Error('first argument should be an array with at least two elements')
  }

  // we are sure there are at least 2 values, so it is safe to have no initial value
  return array.reduce(function (prev, next) {
    return deepmerge(prev, next, optionsArgument)
  })
};

export const compileRelations = (model) => {
  let entities = models[model];

  return Object.keys(entities)
    .reduce((previous, entity) => {
      previous[entity] = {
        name: entity,
        ...entities[entity]
      };

      return previous;
    }, {});
};

export const prepareRelations = (relations) => {
  return Object.keys(relations).map(entity => relations[entity]);
};

export const getRelations = (model) => {
  return prepareRelations(compileRelations(model));
};

export const normalizeExtractData = (value, model) => {
  model = model || null;

  let initData = {
    data: {},
    list: [],
  };

  let entities = [];

  if (model) {
    entities = getRelations(model);
    initData = {
      ...initData,
      ...initializeData(entities)
    };
  }

  if (!Array.isArray(value)) {
    value = [value];
  }

  return value.reduce((pv, cv) => {
    if (entities.length) {
      entities.forEach(item => {
        let entity = item.name;

        if (/^[0-9]+$/.test(cv.id)) {
          cv.id = parseInt(cv.id)
        }

        if (entity in cv) {
          // eslint-disable-next-line no-prototype-builtins
          if (cv.hasOwnProperty(entity)) {
            let normalized = {
              data: {},
              list: [],
            };

            if (cv[entity] !== null && cv[entity]) {
              normalized = {
                ...normalized,
                ...normalizeExtractData(cv[entity], item.model)
              };
            }

            if (item.recursive) {
              pv = deepmerge(pv, {
                ...normalized,
                ...{
                  list: [],
                }
              })
            } else {
              pv[entity] = deepmerge(pv[entity], normalized);
            }

            if (item.multiply) {
              cv[entity] = normalized.list;
            } else {
              if (normalized.list.length) {
                if (normalized.list.length === 1) {
                  cv[entity] = normalized.list[0];
                } else {
                  cv[entity] = normalized.list;
                }
              } else {
                cv[entity] = null;
              }
            }
          }
        }
      });
    }


    let modelKey = models[model] && models[model].key || 'id'

    pv.data[cv[modelKey]] = cv;
    pv.list.push(parseInt(cv[modelKey]));

    return pv;
  }, initData);
};

export const paginationExtractData = (data) => {
  return {
    perPage: data.per_page,
    lastPage: data.last_page,
    currentPage: data.current_page,
    total: data.total,
  }
};

export const yearFromDate = (date) =>{
  if(!date){
    return null;
  }
  let parts = date.split('.');
  if(parts.length != 3){
    return null;
  }
  return parseInt(parts[2]);
}

export const monthFromDate = (date) =>{
  if(!date){
    return null;
  }
  let parts = date.split('.');
  if(parts.length != 3){
    return null;
  }
  return parseInt(parts[1]);
}

export const dayFromDate = (date) =>{
  if(!date){
    return null;
  }
  let parts = date.split('.');
  if(parts.length != 3){
    return null;
  }
  return parseInt(parts[0]);
}

export const daysInMonth = (month, year) =>{
  return new Date(year, month, 0).getDate();
}