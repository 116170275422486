import * as authService from "../../services/auth.service";
import * as accountApi from "../../api/account";
import { normalizeData } from "@/services/common";
import Vue from "vue";
import axios from "axios";

const state = {
  user: {}, //this is auth user,
  info: {},
  selectedIdentAddress: null,
  accountPrefixes: [],
  canPayForAllAccounts: false,
};

const actions = {
  syncUserData({ commit }) {
    let localUser = authService.getUserData();
    commit("setUser", localUser);
  },

  login({ commit }, { phone, password }) {
    return accountApi
      .login({ phone, password })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          authService.setAuthData(result.acx);
          commit("setUser", result);
        }

        return result;
      })
      .catch((error) => {
        throw error;
      });
  },

  sendCheckCodeSMS(context, { phone }) {
    return accountApi
      .sendCheckCodeSMS({ phone })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  sendCheckCodeFlashCall(_, { phone, dontCheck }) {
    return accountApi
      .sendCheckCodeFlashCall({ phone, dontCheck })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      })
  },

  getRequestAccessCode(context, { phone }) {
    return accountApi
      .getRequestAccessCode({ phone })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  getInfo({ commit }) {
    return accountApi
      .getInfo()
      .then((response) => {
        commit('setCanPayForAllAccounts', response.data.CanPayForAllAccounts);

        return normalizeData(response.data.Data, "AccountID")
      })
      .then((result) => {
        if (result.keys[0]) {
          commit("setSelectedIdentAddress", result.keys[0]);
        }

        commit("setInfo", result.list);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  },

  getUserInfo({ commit }) {
    if (!commit) {
      return null;
    }
    return accountApi
      .getUserInfo()
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  updateProfile({ commit }, { fio, email }) {
    return accountApi
      .updateProfile({ fio, email })
      .then((response) => {
        if (response.data.isSucceed) {
          commit("setUser", { fio, email });
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  checkAccessCode(context, { phone, code }) {
    return accountApi
      .checkAccessCode({ phone, code })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  registerByPhone(context, { fio, phone, password, code, birthday }) {
    return accountApi
      .registerByPhone({ fio, phone, password, code, birthday })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  deleteAccountByIdent({ commit }, ident) {
    return accountApi
      .deleteAccountByIdent({ ident })
      .then((response) => {
        commit("deleteInfo", ident);

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  addAccountByIdent({ dispatch, commit }, { ident, pinCode }) {
    return accountApi
      .addAccountByIdent({ ident, pinCode })
      .then((response) => {
        authService.setAuthData(response.data.acx);
        axios.defaults.headers.common["acx"] = response.data.acx;
        commit("setUser", response.data);
        dispatch("getInfo");
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPayLink(
    context,
    {
      accountID,
      sum,
      payInsurance,
      paymentSystem,
      payAll,
      allowCardSaving,
      paidRequestId,
      bonusAmount,
    }
  ) {
    return accountApi
      .getPayLink({
        accountID,
        sum,
        payInsurance,
        paymentSystem,
        payAll,
        allowCardSaving,
        paidRequestId,
        bonusAmount,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPaymentSystems() {
    return accountApi
      .getPaymentSystems()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  disableAccountInsurance({ commit }, accountID) {
    return accountApi
      .disableAccountInsurance({ accountID })
      .then((response) => {
        commit("disableStoredAccountInsurance", accountID);

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getSumWithCommission(context, { sum, accountID }) {
    return accountApi
      .getSumWithCommission({ sum, accountID })
      .then((response) => {
        let result = response.data;

        return {
          TotalSum: result.TotalSum,
          Commission: result.Comission,
          HideComissionInfo: result.HideComissionInfo,
        };
      })
      .catch((error) => {
        throw error;
      });
  },

  logout({ commit }) {
    authService.resetAuthData();
    commit("setUser", {});
  },

  getAccountPrefixes({ commit }) {
    accountApi
      .getAccountPrefixes()
      .then((response) => {
        commit("setAccountPrefixes", response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },

  getInfoByIdent: (state) => (ident) => state.info[ident] || {},
  getSelectedAddress: (state) => state.info[state.selectedIdentAddress] || {},
  getInfos: (state) => (list) => list.map((ident) => state.info[ident] || {}),
  getAllInfo: (state) => () => Object.values(state.info),

  isAuth(state) {
    return !!(state.user && state.user.email && state.user.email.length > 0);
  },

  canPayForAllAccounts: (state) => state.canPayForAllAccounts,

  getAccountSettings: ({ user }) => (accountId) => user.accounts.find((account) => account.id === accountId) || null,
};

const mutations = {
  setUser(state, data) {
    state.user = {
      ...state.user,
      ...data,
    };

    authService.setUserData(state.user);
  },

  setInfo(state, data) {
    state.info = {
      ...state.info,
      ...data,
    };
  },

  deleteInfo(state, ident) {
    Vue.delete(state.info, ident);

    if (state.selectedIdentAddress === ident) {
      state.selectedIdentAddress = null;
    }
  },

  setSelectedIdentAddress(state, ident) {
    state.selectedIdentAddress = ident;
  },

  disableStoredAccountInsurance(state, accountID) {
    var info = Object.values(state.info).find((x) => x.AccountID == accountID);
    if (info) {
      info.InsuranceSum = 0;
    }
  },

  setAccountPrefixes(state, accountPrefixes) {
    state.accountPrefixes = accountPrefixes;
  },

  setCanPayForAllAccounts(state, data) {
    state.canPayForAllAccounts = data;
  }

};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
