<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div
          class="main_single-column unified-box main_single-column_theme_turquoise"
        >
          <div class="shop__header header flex_start">
            <breadcrumbs :items="currentBreadcrumbs" />
          </div>
          <div class="shop__header header">
            <div class="header__cell">
              <router-link
                class="application-item_btn"
                :to="previousRouteParams"
              >
              </router-link>
              <router-link :to="previousRouteParams">
                <span>Назад</span>
              </router-link>
            </div>
            <div v-if="isGoodsType" class="header__cell">
              <div>Вес:</div>
              <div class="cart-payment__summary--bolded">
                {{ cartWeight }} кг
              </div>
            </div>
            <div class="header__cell">
              <div>Итого цена:</div>
              <div class="cart-payment__summary--bolded">{{ cartCost }} ₽</div>
              <div v-if="isGorserv" class="cart-payment__min-summary">
                Мин. сумма заказа 1000р.
              </div>
            </div>
          </div>
          <div class="section-title">
            Оформление заказа
          </div>

          <div class="cart">
            <div class="cart__list">
              <div class="cart-list__header">
                <div
                  class="cart-list__cell cart-item__cell--flex20"
                  v-text="`В корзине ${goods.length} товар${wordEnding}`"
                ></div>
                <div class="cart-list__cell cart-item__cell--flex20">
                  Цена
                </div>
                <div class="cart-list__cell cart-item__cell--flex20">
                  Количество
                </div>
                <div
                  v-if="isGoodsType"
                  class="cart-list__cell cart-item__cell--flex20"
                >
                  Вес
                </div>
                <div class="cart-list__cell cart-item__cell--flex20">
                  Стоимость
                </div>
              </div>
              <cart-item
                v-for="good in goods"
                :key="`good good__item_${good.ID}`"
                :good="good"
              >
              </cart-item>
            </div>
            <div class="cart__cart-payment cart-payment">
              <div class="cart-payment__wrapper">
                <div class="cart-payment__item">
                  <div>Стоимость {{ typeShopText }}</div>
                  <div
                    class="cart-payment__summary--bolded"
                    v-text="`${cartCost} ₽`"
                  ></div>
                </div>
                <div v-if="isGoodsType" class="cart-payment__item">
                  <div>Вес</div>
                  <div
                    class="cart-payment__summary--bolded"
                    v-text="`${cartWeight} кг`"
                  ></div>
                </div>
                <div class="cart-payment__item">
                  <div class="cart-payment__summary--bolded">Итого</div>
                  <div
                    class="cart-payment__summary--bolded"
                    v-text="`${cartCost} ₽`"
                  ></div>
                </div>

                <div class="personal-account_number">
                  Адрес:
                  <el-select
                    v-model="selectedAccoutID"
                    value-key="AccountId"
                    filterable
                    style="width: 140px; margin-left: 10px;"
                    @change="selectGroup"
                  >
                    <el-option
                      v-for="(info, i) in uniqueAccounts"
                      :value="info"
                      :label="info.Address ? info.Address : info.AccountTypes"
                      :key="`select-bill-${i}`"
                    >
                      {{ info.Address ? info.Address : info.AccountType }}
                    </el-option>
                  </el-select>
                </div>
                <div class="cart-payment__payment-button">
                  <a
                    class="main-btn personal-account_item-btn"
                    :class="{
                      'main-btn-disabled': cartIsEmpty,
                    }"
                    @click="onPay"
                    >Подтвердить заказ</a
                  >
                </div>
                <div class="cart-payment__payment-button">
                  <router-link :to="previousRouteParams">
                    <a
                      class="cart-payment__hidden-btn cart-payment__hidden-btn_color_grey cart-payment__hidden-btn_sm"
                      >Продолжить покупки</a
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-wrapper :show="showNoticeModal" @close="closeNoticeModal">
      <h3>
        Ошибка
      </h3>
      <p>
        Пожалуйста, выберите лицевой счёт
      </p>
    </modal-wrapper>
  </main>
</template>

<script>
import CartItem from "@/components/ui/CartItem";
import Breadcrumbs from "@/components/breadcrumbs/TheBreadcrumbs";
import modalWrapper from "@/components/modals/modalWrapper";

import cartMixin from "@/mixins/cart";
import additionalServiceMixin from "@/mixins/additionalService";

import { mapActions, mapState } from "vuex";

export default {
  name: "UserCart",
  components: { Breadcrumbs, CartItem, modalWrapper },
  props: {
    breadcrumbs: Array,
  },
  mixins: [additionalServiceMixin, cartMixin],
  data() {
    return {
      accountInfoIDs: null,
      selectedIdent: null,
      showNoticeModal: false,
      selectedAccoutID: null,
    };
  },
  computed: {
    ...mapState(["additionalService", "account"]),

    currentBreadcrumbs() {
      if (this.$route.params.breadcrumbs) {
        return this.$route.params.breadcrumbs;
      }
      if (this.additionalService.servicesByGroups.length == 0) {
        return [];
      }
      let serviceBreadcrumb = this.defineServiceBreadcrumb();
      return [
        serviceBreadcrumb,
        {
          text: this.getShopName(),
          route: {
            name: "Shop",
            params: {
              ...this.$route.params,
              breadcrumbs: [
                serviceBreadcrumb,
                { text: this.getShopName(), route: "" },
              ],
            },
          },
        },
        { text: "Оформление заказа", route: "" },
      ];
    },

    previousRouteParams() {
      if (this.$route.params.breadcrumbs) {
        let index = this.$route.params.breadcrumbs.length - 2;
        return this.$route.params.breadcrumbs[index].route;
      }
      return { name: "Shop", ...this.$route.params };
    },
    accountInfo() {
      if (!this.accountInfoIDs) {
        return null;
      }
      return this.accountInfoIDs.map((ident) => this.account.info[ident] || {});
    },
    uniqueAccounts() {
      if (!this.accountInfoIDs) {
        return null;
      }
      return this.accountInfo
        .map((x) => {
          return {
            Ident: x.Ident,
            Address: x.Address,
            AccountType: x.AccountType,
            AccountId: x.AccountID,
          };
        })
        .filter((x, i, self) => {
          return self.findIndex((t) => t.Address == x.Address) === i;
        })
        .sort((a, b) => a.Ident - b.Ident);
    },
    goods() {
      return this.additionalService.goods.filter((item) => {
        if (this.selectedCategory) {
          return item.Categories.includes(this.selectedCategory);
        }
        return true;
      });
    },
    wordEnding() {
      switch (this.goods.length % 10) {
        case 1:
          return "";
        case 2:
        case 3:
        case 4:
          return "а";
        default:
          return "ов";
      }
    },
  },
  mounted() {
    this.getLocalStorageCart();
    this.getAccounts()
      .then((result) => {
        this.accountInfoIDs = [...result.keys];
      })
      .then(() => {
        if (
          this.accountInfo.length > 0 &&
          this.accountInfo[0].Bills.length > 0
        ) {
          this.selectedIdent = this.accountInfo[0].Bills[0].Ident;
        }
      });

    if (this.additionalService.servicesByGroups.length == 0) {
      this.getEventBlockData().then(this.getShopName);
    }
  },
  methods: {
    ...mapActions({
      addMessage: "request/addMessage",
      getLocalStorageCart: "additionalService/getLocalStorageCart",
      getAccounts: "account/getInfo",
      newRequest: "request/newShopRequest",
    }),
    onPay() {
      if (this.selectedIdent) {
        const sourceCost = this.additionalService.goods.reduce(
          (prevValue, currentValue) => {
            return prevValue + currentValue.Quantity * currentValue.Price;
          },
          0
        );
        let goods = this.additionalService.goods
          .filter((item) => {
            return item.Quantity > 0;
          })
          .map((item) => {
            let Amount = item.Quantity * item.Price;
            if (this.cartCost > sourceCost && this.isGorserv) {
              Amount = ((Amount / sourceCost) * 1000).toFixed(0);
            }
            return {
              ID: null,
              GoodID: item.ID,
              Name: item.Name,
              Price: item.Price,
              Quantity: item.Quantity,
              Amount,
              BonusAmount: 0,
            };
          });

        let request = {
          AdditionalServiceId: +this.$route.params.AdditionalServiceId,
          Ident: this.selectedIdent,
          TypeID: +this.$route.params.id_RequestType,
          ShopId: +this.$route.params.id,
          IsPaid: true,
          SubTypeID: null,
          PaidSum: +this.cartCost,
          Text: `На сумму: ${this.cartCost} руб.`,
          PaidServiceText: "Покупка в магазине",
          ReceiptItems: goods,
        };
        this.newRequest(request)
          .then(this.pushMessage)
          .then((requestId) => {
            this.$router.push({
              name: "Requests",
              params: { requestID: requestId },
            });
          });
      } else {
        this.showNoticeModal = true;
      }
    },
    async pushMessage(response) {
      const sourceCost = this.additionalService.goods.reduce(
        (prevValue, currentValue) => {
          return prevValue + currentValue.Quantity * currentValue.Price;
        },
        0
      );

      let message = this.additionalService.goods
        .filter((item) => {
          return item.Quantity > 0;
        })
        .map((item, index) => {
          let Amount = item.Quantity * item.Price;
          if (this.cartCost > sourceCost && this.isGorserv) {
            Amount = ((Amount / sourceCost) * 1000).toFixed(0);
          }
          return `${index + 1}) ${item.Name} кол-во: ${item.Quantity} ${item.Units != null ? `${item.Units}` : ""}.\nЦена: ${Amount} руб.`;
        });

      const totalMessage = `Ваш заказ: \n${message.join(`\n`)}\n
        Итого цена: ${this.cartCost} руб. 
        ${this.isGoodsType ? this.cartWeight + " кг." : ""}`;

      await this.addMessage({
        RequestId: response.id,
        Text: totalMessage,
      });
      
      return response.id;
    },
    closeNoticeModal() {
      this.showNoticeModal = false;
    },

    selectGroup(e) {
      this.selectedIdent = e.Ident;
    }
  },
};
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
.application-item_btn {
  cursor: pointer;
  position: relative;
  transform: rotate(225deg);
  right: 0;
  width: 15px;
  height: 15px;
}
.cart {
  display: grid;
  grid-template-columns: 70% 30%;
}
.cart__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 10px;
}
.cart-list__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  margin: 0 10px;
  font-size: 0.8rem;
}
.shop__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}
@media (max-width: 1200px) {
  .cart {
    display: grid;
    grid-template-columns: 100%;
  }
  .cart__list {
    margin: 0;
  }
  .cart-list__header {
    display: none;
  }
}
.main_single-column_theme_turquoise {
  background-color: #dbeff0;
}
.cart-payment__wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin: 1em;
  padding: 1em;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.cart-list__cell:first-child {
  justify-content: flex-start;
}
.cart-list__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.cart-item__cell--flex20 {
  flex: 0 0 20%;
}
.cart-item__cell_flex_40 {
  flex: 0 0 40%;
}
.cart-payment__item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
}
.cart-payment__summary--bolded {
  font-weight: 600;
}
.cart-payment__min-summary {
  color: #a9b2b7;
  font-weight: 300;
  font-size: 0.75em;
}
.cart-payment__payment-button {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  text-align: center;
}
.cart-payment__hidden-btn {
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
}
.cart-payment__hidden-btn_color_grey {
  color: #a9b2b7;
}
.cart-payment__hidden-btn_sm {
  font-size: 0.8rem;
}
.slick-arrow {
  width: 20px;
  height: 20px;
}
.header__cell:first-child {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.header__cell {
  padding: 0 15px;
}
.glipf-cart {
  font-size: 2.5rem;
}
.personal-account_number {
  font-size: 1rem;
}
.flex_start {
  justify-content: flex-start;
}
</style>
