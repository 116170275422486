import axios from 'axios'

export function getRequestsList() {
  return axios.get('/Requests/List')
}

export function getRequestDetails(id) {
  return axios.get('/Requests/Details/' + id)
}

export function getFile(id) {
  return axios.get('/Requests/File/' + id, { responseType: 'blob' })
}

export function addMessage({ RequestId, Text }) {
  return axios.post('/Requests/AddMessage', { RequestId, Text })
}

//в formDataObject параметры file и requestId
export function addFile(formDataObject) {
  return axios.post('/Requests/AddFile', formDataObject, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getRequestTypes() {
  return axios.get('/Requests/RequestTypes')
}

export function newRequest({ ident, typeID, subTypeID, detailSubTypeID, text }) {
  return axios.post('/Requests/New', { ident, typeID, subTypeID, detailSubTypeID, text })
}

export function newShopRequest(request) {
  return axios.post('/Requests/New', request)
}

export function closeRequest({ requestId, mark, text }) {
  return axios.post('/Requests/Close', { requestId, mark, text })
}

export function getUpdates(updateKey, currentRequestId) {
  if(currentRequestId){
    return axios.post('/Requests/GetUpdates', { updateKey, currentRequestId })
  }
  else{
    return axios.post('/Requests/GetUpdates', { updateKey })
  }
}
export function checkRequestSumIsVerified(id) {
  return axios.get(`/Requests/CheckRequestSumIsVerified/${id}`)
}

export function sendPaidRequestCompleteCodeOnline(payload) {
  return axios.post('/Requests/SendPaidRequestCompleteCodeOnline', payload)
}


export function sendPaidRequestCompleteCodeCash(payload) {
  return axios.post('/Requests/SendPaidRequestCompleteCodeCash', payload)
}

export function getPaidRequestAllowedBonuses(id) {
  return axios.get(`/Accounting/GetPaidRequestAllowedBonuses/${id}`);
}