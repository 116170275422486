<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div class="main_single-column">
          <div class="section-title">
            Специальные предложения
          </div>
          <div class="service service--theme-white">
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              v-for="(service, index) in additionalService.servicesByGroups"
              :ref="`servicegroup_${index}`"
              :key="`service-group-${index}`"
            >
              <div
                class="service__header poll-item_title"
                @click="toggleService(index)"
              >
                <h3>
                  {{ service.name }}
                </h3>
                <div style="display: flex">
                  <span
                    class="application-item_btn"
                    :class="{
                      'application-item_btn--rotated': service.isShow,
                    }"
                  ></span>
                </div>
              </div>
              <div class="service__shops" v-show="service.isShow">
                <ServiceItem
                  class="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                  v-for="shop in service.services"
                  :key="`special-offer_item_${shop.ID}`"
                  :shop="shop"
                  :serviceName="service.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ServiceItem from "@/components/ui/ServiceItem";
import additionalServiceMixin from "@/mixins/additionalService";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Shop",
  components: { ServiceItem },
  props: {
    serviceId: null,
  },
  mixins: [additionalServiceMixin],
  mounted() {
    if (this.additionalService.servicesByGroups.length != 0) {
      this.scrollToService();
    } else {
      this.getEventBlockData();
    }
  },
  methods: {
    ...mapActions({
      getGoods: "additionalService/getGoods",
    }),
    ...mapMutations({
      toggleService: "additionalService/toggleService",
    }),
    scrollToService() {
      let indexGroup = this.additionalService.servicesByGroups.findIndex(
        (item) => {
          return item.services
            .map((service) => service.ID)
            .includes(this.serviceId);
        }
      );

      if (indexGroup < 0) {
        return;
      }
      this.$refs[`servicegroup_${indexGroup}`][0].scrollIntoView();
    },
  },
};
</script>

<style scoped>
.service {
  display: flex;
  flex-wrap: wrap;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  padding: 2em 2em;
  margin: 2em 0;
}
.service__shops {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1em 0;
  text-align: start;
}
.service__header {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  cursor: pointer;
}
.service--theme-grey {
  background-color: rgb(240, 240, 240);

  /*-webkit-box-shadow: 0 8px 10px 6.9px rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 92.1px 6.9px rgba(0, 0, 0, 0.19);*/
}
.service--theme-white {
  background-color: rgb(255, 255, 255);

  -webkit-box-shadow: 0 8px 10px 6.9px rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 92.1px 6.9px rgba(0, 0, 0, 0.19);
}
.application-item_btn {
  cursor: pointer;
  position: relative;
  transform: rotate(135deg);
  right: 0;
  width: 25px;
  height: 25px;
  transition: transform 0.5s;
}
.application-item_btn--rotated {
  transform: rotate(315deg);
}
@media (max-width: 576px) {
  .col-md-6,
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
