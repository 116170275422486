<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div
          class="main_single-column unified-box main_single-column_theme_turquoise"
        >
          <div class="shop__header header">
            <div class="header__cell">
              <router-link
                class="application-item_btn"
                :to="previousRouteParams"
              >
              </router-link>
              <router-link :to="previousRouteParams">
                <span>Назад</span>
              </router-link>
            </div>
            <div v-if="isGoodsType" class="header__cell">
              <div>Вес:</div>
              <div class="cart-payment__summary--bolded">
                {{ cartWeight }} кг
              </div>
            </div>
            <div class="header__cell">
              <div>Итого цена:</div>
              <div class="cart-payment__summary--bolded">{{ cartCost }} ₽</div>
              <div v-if="isGorserv" class="cart-payment__min-summary">
                Мин. сумма заказа 1000р.
              </div>
            </div>
          </div>
          <div class="section-title">Оформление заказа</div>

          <div class="cart">
            <div class="cart__list">
              <div class="cart-list__header">
                <div
                  class="cart-list__cell cart-item__cell--flex20"
                  v-text="`В корзине ${goods.length} товар${wordEnding}`"
                ></div>
                <div class="cart-list__cell cart-item__cell--flex20">
                  Цена
                </div>
                <div class="cart-list__cell cart-item__cell--flex20">
                  Количество
                </div>
                <div class="cart-list__cell cart-item__cell--flex20">Вес</div>
                <div class="cart-list__cell cart-item__cell--flex20">
                  Стоимость
                </div>
              </div>
              <shop-item
                v-for="good in goods"
                :key="`good good__item_${good.ID}`"
                :good="good"
              >
              </shop-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header__cartdata-wrapper">
      <div
        ref="cartdata"
        class="header__cartdata"
        :class="{ ' header__cartdata--bottom': cartIsBottom }"
      >
        <div class="header__cell">
          <router-link
            :disabled="cartIsEmpty"
            :event="!cartIsEmpty ? 'click' : ''"
            :to="{
              name: 'RequestConfirmation',
              params: nextRouteParams('UserCart', 'Оформление заказа'),
            }"
          >
            <span class="header__ico glipf-cart"></span>
          </router-link>
        </div>
        <div v-if="isGoodsType" class="header__cell">
          <div>Вес:</div>
          <div class="cart-payment__summary--bolded">{{ cartWeight }} кг</div>
        </div>
        <div class="header__cell">
          <div>Итого цена:</div>
          <div class="cart-payment__summary--bolded">{{ cartCost }} ₽</div>
          <div v-if="isGorserv" class="cart-payment__min-summary">
            Мин. сумма заказа 1000р.
          </div>
        </div>
        <div class="header__payment-button">
          <router-link
            :disabled="cartIsEmpty"
            :event="!cartIsEmpty ? 'click' : ''"
            :to="{
              name: 'RequestConfirmation',
              params: nextRouteParams('UserCart', 'Оформление заказа'),
            }"
          >
            <a
              class="main-btn personal-account_item-btn"
              :class="{ 'main-btn-disabled': cartIsEmpty }"
              >Подтвердить заказ</a
            >
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ShopItem from "@/components/ui/ShopItem";

import cartMixin from "@/mixins/cart";
import additionalServiceMixin from "@/mixins/additionalService";

import { mapActions, mapState } from "vuex";

export default {
  name: "UserCartMobile",
  components: { ShopItem },
  props: {
    breadcrumbs: Array,
  },
  mixins: [additionalServiceMixin, cartMixin],
  data() {
    return {
      accountInfoIDs: null,
      selectedIdent: "",
      cartIsBottom: false,
    };
  },
  computed: {
    ...mapState(["additionalService"]),

    currentBreadcrumbs() {
      if (this.$route.params.breadcrumbs) {
        return this.$route.params.breadcrumbs;
      }
      if (this.additionalService.servicesByGroups.length == 0) {
        return [];
      }
      let serviceBreadcrumb = this.defineServiceBreadcrumb();
      return [
        serviceBreadcrumb,
        {
          text: this.getShopName(),
          route: {
            name: "Shop",
            params: {
              ...this.$route.params,
              breadcrumbs: [
                serviceBreadcrumb,
                { text: this.getShopName(), route: "" },
              ],
            },
          },
        },
        { text: "Оформление заказа", route: "" },
      ];
    },

    previousRouteParams() {
      if (this.$route.params.breadcrumbs) {
        let index = this.$route.params.breadcrumbs.length - 2;
        return this.$route.params.breadcrumbs[index].route;
      }
      return { name: "Shop", ...this.$route.params };
    },
    goods() {
      return this.additionalService.goods.filter((item) => {
        if (this.selectedCategory) {
          return item.Categories.includes(this.selectedCategory);
        }
        return true;
      });
    },
    wordEnding() {
      switch (this.goods.length % 10) {
        case 1:
          return "";
        case 2:
        case 3:
        case 4:
          return "а";
        default:
          return "ов";
      }
    },
  },
  mounted() {
    if (this.additionalService.servicesByGroups.length == 0) {
      this.getEventBlockData().then(this.getShopName);
    }
    this.getLocalStorageCart().then(this.detectCartLine);
    window.addEventListener("scroll", this.detectCartLine);
  },
  methods: {
    ...mapActions({
      getLocalStorageCart: "additionalService/getLocalStorageCart",
    }),
  },
};
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
.application-item_btn {
  cursor: pointer;
  position: relative;
  transform: rotate(225deg);
  right: 0;
  width: 15px;
  height: 15px;
}
.cart {
  display: grid;
  grid-template-columns: 70% 30%;
}
.cart__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 10px;
}
.cart-list__header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 1em;
  margin: 0 1em;
  font-size: 0.8rem;
}
.shop__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.header__cartdata-wrapper {
  height: 10vh;
}
.header__cartdata {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  background-color: white;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.main_single-column_theme_turquoise {
  background-color: #dbeff0;
}
.cart-payment__wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  margin: 1em;
  padding: 1em;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.cart-list__cell:first-child {
  margin-left: 1em;
  justify-content: flex-start;
}
.cart-list__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.cart-item__cell--flex20 {
  flex: 0 0 20%;
}
.cart-item__cell_flex_40 {
  flex: 0 0 40%;
}
.cart-payment__item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
}
.cart-payment__summary--bolded {
  font-weight: 600;
}
.cart-payment__min-summary {
  color: #a9b2b7;
  font-weight: 300;
  font-size: 0.75em;
}
.cart-payment__payment-button {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  text-align: center;
}
.cart-payment__hidden-btn {
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
}
.cart-payment__hidden-btn_color_grey {
  color: #a9b2b7;
}
.cart-payment__hidden-btn_sm {
  font-size: 0.8rem;
}
.slick-arrow {
  width: 20px;
  height: 20px;
}
.header__cell {
  padding: 0 5px;
}
.header__cell:first-child {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.glipf-cart {
  font-size: 2.5rem;
}
.personal-account_number {
  font-size: 1rem;
}
.flex_start {
  justify-content: flex-start;
}
@media (max-width: 1200px) {
  .cart {
    display: grid;
    grid-template-columns: 100%;
  }
  .cart__list {
    margin: 0;
  }
  .cart-list__header {
    display: none;
  }
}

.header__cartdata--bottom {
  position: relative;
}
</style>
