import axios from 'axios'

export function getOSS() {
  return axios.get('/OSS/List')
}

export function getOSSByID(id) {
  return axios.get('/OSS/OssById/' + id);
}

export function setAcquainted(ossid){
  return axios.post("/OSS/SetAcquainted", { ID: ossid})
}

export function startVote(ossid){
  return axios.post("/OSS/SetStartVoiting", { ID: ossid})
}

export function completeVote(ossid){
  return axios.post("/OSS/CompleteVote", { ID: ossid})
}

export function saveAnswer({ossId, questionId, answer}){
  return axios.post("/OSS/SaveAnswer", { OssId: ossId, QuestionId: questionId, Answer: answer})
}