<template>
  <main class="main-content">
    <div class="container">
      <div class="row">
        <div class="main_single-column">
          <!--Уведомления-->
          <template v-if="!authUser.accessOSS">
            <div :class="['section-title', 'slider-title', 'white-title']">
              <span class="glipf-events-2"></span>Уведомления
            </div>
            <vue-slick-carousel
              :arrows="true"
              :slidesToShow="slidesToShow"
              class="news-slider notice-slider"
            >
              <div class="news-item" v-if="!(announcements.length > 0)">
                <div class="news-item_title none">Уведомлений нет</div>
              </div>

              <div
                class="news-item"
                v-for="announce in announcements"
                :key="`announce-${announce.ID}`"
              >
                <div class="news-item_title">
                  <router-link
                    :to="{
                      name: 'Announcement',
                      params: { id: announce.ID },
                    }"
                    >{{ announce.Header }}</router-link
                  >
                </div>
                <span class="news-item_date">{{ announce.Created }}</span>
                <p></p>
                <div class="text-right">
                  <router-link
                    :to="{
                      name: 'Announcement',
                      params: { id: announce.ID },
                    }"
                    class="news-item_more-details"
                  >
                    <span class="text">Подробнее</span>
                  </router-link>
                </div>
              </div>
            </vue-slick-carousel>
          </template>

          <template v-if="!hasAccounts && !authUser.accessOSS">
            <div class="events-noaccounts">
              <div class="adress-box">
                <div class="common-warning-text">
                  Лицевые счета не подключены
                </div>
                <a
                  @click="openAddAccountIdentModal"
                  style
                  class="common-warning-link"
                  >+ Подключить лицевой счёт</a
                >
              </div>
            </div>
          </template>

          <!--Список собраний собственников-->
          <template v-if="authUser.accessOSS">
            <template v-if="oss.length > 0">
              <div :class="['section-title', 'slider-title']">
                <span class="glipf-events-oss"></span>Собрания собственников
              </div>
              <vue-slick-carousel
                :arrows="true"
                :slidesToShow="slidesToShow"
                class="news-slider notice-slider"
              >
                <div class="news-item" v-if="!(oss.length > 0)">
                  <div class="news-item_title none">Собраний нет</div>
                </div>

                <div
                  class="news-item"
                  v-for="item in oss"
                  :key="`oss-${item.ID}`"
                >
                  <div class="news-item_title">
                    <router-link
                      :to="{ name: 'OssInfo', params: { id: item.ID } }"
                      >{{
                        item.MeetingTitle || "Наименование не указано"
                      }}</router-link
                    >
                  </div>
                  <span class="news-item_date"
                    >{{ dateNoTime(item.DateStart) }} -
                    {{ dateNoTime(item.DateEnd) }}</span
                  >
                  <br />
                  <span>{{ item.Status }}</span>
                  <p></p>
                  <div class="text-right">
                    <router-link
                      :to="{ name: 'OssInfo', params: { id: item.ID } }"
                      class="news-item_more-details"
                    >
                      <span class="text">Подробнее</span>
                    </router-link>
                  </div>
                </div>
              </vue-slick-carousel>
            </template>
            <template v-else>
              <div :class="['section-title', 'slider-title']">
                <span class="glipf-events-oss"></span>Нет активных собраний
              </div>
            </template>
          </template>

          <!--Новости-->
          <template v-if="!authUser.accessOSS">
            <div :class="['section-title', 'slider-title']">
              <span class="glipf-news"></span>Новости
            </div>
            <vue-slick-carousel
              v-if="!isLoading"
              :arrows="true"
              :slidesToShow="slidesToShow"
              class="news-slider"
            >
              <div class="news-item" v-if="!(news.length > 0)">
                <div class="news-item_title none">Новостей нет</div>
              </div>

              <div
                class="news-item"
                v-for="item in news"
                :key="`home-news-${item.ID}`"
              >
                <div class="news-item_title">
                  <router-link
                    :to="{ name: 'News', params: { id: item.ID } }"
                    >{{ item.Header }}</router-link
                  >
                </div>
                <span class="news-item_date">{{ item.Created }}</span>
                <div class="news-item_description" v-html="item.ShortContent"></div>
                <div class="text-right">
                  <router-link
                    :to="{ name: 'News', params: { id: item.ID } }"
                    class="news-item_more-details"
                  >
                    <span class="text">Подробнее</span>
                  </router-link>
                </div>
              </div>
            </vue-slick-carousel>
          </template>

          <!--Опросы-->
          <template v-if="getMenuVisibilitySettings.Polls">
            <div :class="['section-title', 'slider-title', 'white-title']">
              <span class="glipf-polls"></span>Опросы
            </div>

            <vue-slick-carousel
              :arrows="true"
              :slidesToShow="slidesToShow"
              class="news-slider"
            >
              <div class="news-item">
                <div v-if="!!nonPassedPolls.length" class="news-item_title">
                  Есть новые опросы для прохождения :)
                </div>
                <div v-else class="news-item_title">
                  К сожалению, в данный момент нет новых опросов для
                  прохождения. Но вы можете ознакомится с пройденными опросами
                  :)
                </div>

                <div class="text-right">
                  <router-link
                    :to="{ name: 'Polls' }"
                    class="news-item_more-details"
                  >
                    <span class="text">Подробнее</span>
                  </router-link>
                </div>
              </div>
            </vue-slick-carousel>
          </template>
          <!--Специальные предложения-->
          <template v-if="additionalServices.length > 0">
            <div :class="['section-title', 'slider-title']">
              <span class="glipf-discount"></span>Специальные предложения
            </div>
            <vue-slick-carousel
              :arrows="true"
              :slidesToShow="slidesToShow"
              class="special-offers_slider"
            >
              <div v-if="!(additionalServices.length > 0)"></div>
              <ServiceItem
                v-for="shop in additionalServices"
                :key="`special-offer_item_${shop.ID}`"
                :shop="shop"
              >
                <router-link
                  :to="{
                    name: 'Shop',
                    params: {
                      id: shop.ShopID,
                      AdditionalServiceId: shop.ID,
                      type: shop.ShopType,
                      id_RequestType: shop.id_RequestType,
                      breadcrumbs: [
                        {
                          text: `События`,
                          route: {
                            name: 'Home',
                          },
                        },
                        {
                          text: `${shop.Name}`,
                          route: '',
                        },
                      ],
                    },
                    props: {},
                  }"
                >
                  <img
                    v-if="shop.HasLogo"
                    :src="shop.LogoLink"
                    :alt="shop.Name"
                    class="shop__img"
                  />
                  <div class="shop__desc">{{ shop.Name }}</div>
                </router-link>
              </ServiceItem>
            </vue-slick-carousel>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
//vuex
import { mapState, mapGetters } from "vuex";

import ServiceItem from "@/components/ui/ServiceItem";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "Home",
  components: { ServiceItem },
  data() {
    return {
      hasAccounts: true,
      newsIDs: [],
      ossIDs: [],
      announcementIDs: [],
      additionalServiceIDs: [],
      isLoading: false,
      isLoadingOss: false,

      slidesToShow: 3,
    };
  },
  created() {
    this.$root.$on("updated-account-info", this.onAccountInfoChanged);
    this.$root.$on("deleted-account-info", this.onAccountInfoChanged);
    window.addEventListener("resize", this.changeSliderOptions);
  },
  mounted() {
    this.getAccountInfo();
    this.getEventBlockData();
    this.getOSS();
    this.changeSliderOptions();
  },
  destroyed() {
    this.$root.$off("updated-account-info", this.onAccountInfoChanged);
    this.$root.$off("deleted-account-info", this.onAccountInfoChanged);
    window.removeEventListener("resize", this.changeSliderOptions);
  },
  methods: {
    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getOSS();
    },
    getAccountInfo() {
      this.$store.dispatch("account/getUserInfo").then((result) => {
        this.hasAccounts = result.Accounts && result.Accounts.length;
      });
    },
    getCacheData() {
      let announcementIDs = localStorage.getItem("announcementIDs");
      this.announcementIDs =
        announcementIDs !== null
          ? JSON.parse(announcementIDs)
          : this.announcementIDs;

      let newsIDs = localStorage.getItem("newsIDs");
      this.newsIDs = newsIDs !== null ? JSON.parse(newsIDs) : this.newsIDs;
    },

    changeSliderOptions() {
      this.slidesToShow = 4;
      this.$nextTick(() => {
        this.slidesToShow = window.innerWidth > 700 ? 3 : 1;
      });
    },

    getEventBlockData() {
      this.isLoading = true;
      this.$store
        .dispatch("news/getEventBlockData")
        .then((result) => {
          this.newsIDs = result.news.keys;
          this.announcementIDs = result.announcements.keys;
          this.additionalServiceIDs = result.services.keys;
        })
        .then(() => {
          setTimeout(() => {
            this.isLoading = false;
            this.changeSliderOptions();
          }, 500);
        })
        .catch(() => {
          this.$nextTick(() => {
            this.isLoading = false;
          });
        });
    },
    getOSS() {
      this.isLoadingOss = true;
      this.$store
        .dispatch("oss/getOSS")
        .then((result) => {
          this.ossIDs = result.keys;
          this.isLoadingOss = false;
        })
        .catch(() => {
          this.$nextTick(() => {
            this.isLoadingOss = false;
          });
        });
    },
  },
  computed: {
    ...mapState({
      polls: (state) => state.polls.polls,
    }),
    ...mapGetters({
      getMenuVisibilitySettings: "config/getMenuVisibilitySettings",
      nonPassedPolls: "polls/nonPassedPolls",
    }),
    news() {
      return this.$store.getters["news/getNews"](this.newsIDs);
    },
    oss() {
      return this.$store.getters["oss/getOss"](this.ossIDs);
    },
    announcements() {
      return this.$store.getters["announcement/getAnnouncements"](
        this.announcementIDs
      );
    },
    additionalServices() {
      return this.$store.getters["additionalService/getServices"](
        this.additionalServiceIDs
      );
    },
  },
  watch: {
    announcementIDs(newValue) {
      if (newValue.length > 0) {
        localStorage.setItem("announcementIDs", JSON.stringify(newValue));
      }
    },
    newsIDs(newValue) {
      if (newValue.length > 0) {
        localStorage.setItem("newsIDs", JSON.stringify(newValue));
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
.slide-test {
  width: 200px;
  min-height: 100px;
  background: #fff;
  display: inline-block;
}

.news-item_title.none {
  min-height: 250px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
 {
  opacity: 0;
}
</style>
