import axios from "axios";

export function getMetersList() {
  return axios.get("/Meters/List");
}

export function getMeterValues({ meterUniqueNum }) {
  return axios.get("/Meters/MeterValues", { params: { meterUniqueNum } });
}

export function saveMeterValue({ ID, Value, ValueT2, ValueT3 }) {
  return axios.post("/Meters/SaveMeterValue", {
    MeterId: ID,
    Value,
    ValueT2,
    ValueT3,
  });
}

export function deleteMeterValue({ ID }) {
  return axios.post("/Meters/DeleteMeterValue", { MeterId: ID });
}

export function createRequestOnMeterRecheck({ ID }) {
  return axios.post(`/meters/CreateRequestOnMeterRecheck?meterID=${ID}`);
}
