<template>
  <div class="loading">Загрузка...</div>
</template>

<script>
  export default {
    name: "IndexRoute",
    mounted() {
      if(this.isAuth) {
        location.href = '/home'
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
  }
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.loading
  padding: 20px
  text-align: center
  color: #fff
</style>
