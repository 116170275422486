import { mapState,mapActions } from 'vuex'

export default {
    computed: {
        ...mapState(["additionalService"])
    },
    methods: {
        ...mapActions({
            getEventBlockData: "news/getEventBlockData",
        }),
        
        getServiceGroup() {
            return this.additionalService.servicesByGroups.find((item) => {
            return item.services
                .map((shop) => shop.ID)
                .includes(+this.$route.params.AdditionalServiceId);
            });
        },
        getShopName() {
          return this.getServiceGroup().services.find(
            (shop) => shop.ID == +this.$route.params.AdditionalServiceId
          ).Name;
        },
        
        nextRouteParams(currentRouteName, nextRouteName) {
            let params = JSON.parse(JSON.stringify(this.$route.params));
            if (this.additionalService.servicesByGroups.length > 0) {
                if (!params.breadcrumbs) {
                    params.breadcrumbs = JSON.parse(
                    JSON.stringify(this.currentBreadcrumbs)
                    );
                }
                let params1 = JSON.parse(JSON.stringify(this.$route.params));
                let currentIndex = params.breadcrumbs.length - 1;
        
                params.breadcrumbs[currentIndex].route = {
                    params: params1,
                    name: currentRouteName,
                };
                params.breadcrumbs.push({ text: nextRouteName, route: "" });
                return params;
            }
            return params;
        },
        
        defineServiceBreadcrumb() {
            let serviceGroup = this.getServiceGroup();
            return {
                text: serviceGroup.name,
                route: {
                    name: "Services",
                    params: { serviceId: +this.$route.params.AdditionalServiceId },
                },
            };
        },
    },
}