<template>
  <Modal
    title="Добавить лицевой счёт"
    @close="$emit('close')"
    class="modal-content_personal-account"
    :setBaseStyle="false"
  >
    <form
      v-on:submit.prevent
      class="personal-account_form"
      v-loading="isSending"
    >
      <template v-if="stage === 1">
        <div class="form-group_info">
          Введите номер лицевого счета
        </div>
        <div class="form-group">
          <label class="form-label"
            ><span class="glipf-monitor"></span>Номер счёта</label
          >
          <input
            type="text"
            v-model="form.ident"
            class="form-control"
            placeholder="1234567890"
          />
        </div>
        <template v-if="selectPrefix">
          <div class="form-group_info">
            Выберите управляющую организацию
          </div>
          <div style="margin-bottom: 1.8em;">
            <el-select
              v-model="selectedPrefix"
              size="large"
              style="width: 100%;"
              filterable
            >
              <el-option
                v-for="prefix in prefixes"
                :key="`request-info-${prefix.Prefix}`"
                :label="prefix.Name"
                :value="prefix.Prefix"
              >
                {{ prefix.Name }}
              </el-option>
            </el-select>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="form-group_info">
          Введите пин-код с последней квитанции
        </div>
        <div class="form-group">
          <label class="form-label"><span class=""></span>Пин-код</label>
          <input
            type="text"
            v-model="form.pinCode"
            class="form-control"
            placeholder="Пин-код"
            :minlength="1"
          />
        </div>
      </template>
      <input
        type="submit"
        class="personal-account_form-submit main-btn"
        value="Подключить"
        @click="send"
        :disabled="
          (stage === 1 && form.ident.length === 0) ||
            (stage === 1 &&
              selectPrefix &&
              (!selectedPrefix || !selectedPrefix.length)) ||
            (stage === 2 && !form.pinCode.length)
        "
      />
    </form>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "addAccountIdentModal",
  components: { Modal },
  data() {
    return {
      form: {
        ident: "",
        pinCode: "",
      },
      stage: 1,
      isSending: false,
      selectedPrefix: "",
    };
  },
  created() {
    this.$store.dispatch("account/getAccountPrefixes");
  },
  computed: {
    prefixes() {
      return this.$store.state.account.accountPrefixes;
    },

    selectPrefix() {
      return this.prefixes.length;
    },
  },
  methods: {
    send() {
      if (this.stage === 1) {
        if (this.config.useAccountPinCode) {
          this.stage = 2;
          return;
        }
      }

      if (
        this.form.ident.length > 0 &&
        (!this.selectPrefix ||
          (this.selectedPrefix && this.selectedPrefix.length))
      ) {
        this.isSending = true;
        var data = this.form;
        if (this.selectPrefix) {
          data = {
            ident: this.selectedPrefix + data.ident,
            pinCode: data.pinCode,
          };
        }
        //todo: создать метод добавления счёта
        this.$store
          .dispatch("account/addAccountByIdent", data)
          .then(() => {
            this.form.ident = "";
            this.isSending = false;
            this.$root.$emit("updated-account-info");
            this.$emit("close");
          })
          .catch(() => {
            this.isSending = false;
            this.stage = 1;
          });
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheets/scss">
// .vm--modal {
/*padding: 0 !important;*/
/*background-color: transparent !important;*/
// }
</style>
