var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"main_single-column unified-box"},[_c('div',{staticClass:"shop__header header"},[_c('div',{staticClass:"header__cell"},[_c('router-link',{staticClass:"application-item_btn",attrs:{"to":_vm.previousRoute}}),_c('router-link',{attrs:{"to":_vm.previousRoute}},[_c('span',[_vm._v("Назад")])])],1),_c('div',{staticClass:"header__cell"},[_c('div',{staticClass:"shop__sorter",on:{"click":function($event){_vm.sortDirection = _vm.sortsSwitch[_vm.sortDirection].name}}},[_c('span',{staticClass:"glipf-sort-ico",class:{
                  'shop__sort-ico_rotated': _vm.sortDirection === 'asc',
                }}),_c('span',[_vm._v(" по цене")])])])]),(_vm.categories.length > 0)?_c('vue-slick-carousel',{attrs:{"centerMode":false,"arrows":true,"slidesToShow":_vm.slidesToShow,"focusOnSelect":true}},_vm._l((_vm.categories),function(category,index){return _c('div',{key:("good-category-" + index),staticClass:"good-category__item text-center good-category__item_bolded",class:{
              'good-category__item_selected': _vm.selectedCategory == category,
            },on:{"click":function($event){_vm.selectedCategory = category}}},[_c('span',{domProps:{"textContent":_vm._s(category)}})])}),0):_vm._e(),_c('div',{staticClass:"goods-list"},_vm._l((_vm.goods),function(good){return _c('shop-item',{key:("good good__item_" + (good.ID)),attrs:{"good":good}})}),1)],1)])]),_c('div',{staticClass:"header__cartdata-wrapper"},[_c('div',{ref:"cartdata",staticClass:"header__cartdata",class:{ header__cartdata_bottom: _vm.cartIsBottom }},[_c('div',{staticClass:"header__cell"},[_c('router-link',{attrs:{"disabled":_vm.cartIsEmpty,"event":!_vm.cartIsEmpty ? 'click' : '',"to":{
            name: 'UserCart',
            params: _vm.$route.params,
          }}},[_c('span',{staticClass:"header__ico glipf-cart"})])],1),(_vm.isGoodsType)?_c('div',{staticClass:"header__cell"},[_c('div',[_vm._v("Вес:")]),_c('div',{staticClass:"cart-payment__summary--bolded"},[_vm._v(_vm._s(_vm.cartWeight)+" кг")])]):_vm._e(),_c('div',{staticClass:"header__cell"},[_c('div',[_vm._v("Итого цена:")]),_c('div',{staticClass:"cart-payment__summary--bolded"},[_vm._v(_vm._s(_vm.cartCost)+" ₽")]),(_vm.isGorserv)?_c('div',{staticClass:"cart-payment__min-summary"},[_vm._v(" Мин. сумма заказа 1000р. ")]):_vm._e()]),_c('div',{staticClass:"header__payment-button"},[_c('router-link',{attrs:{"disabled":_vm.cartIsEmpty,"event":!_vm.cartIsEmpty ? 'click' : '',"to":{
            name: 'UserCart',
            params: _vm.nextRouteParams('Shop', 'Оформление заказа'),
          }}},[_c('a',{staticClass:"main-btn personal-account_item-btn",class:{ 'main-btn-disabled': _vm.cartIsEmpty }},[_vm._v("Оформить заказ")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }