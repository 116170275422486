<template>
  <main class="main-content">
    <div class="container">
      <div class="row align-items-center main-content_panel">
        <div class="main_left-column">
          <div class="main-title main-title_left">Показания</div>
        </div>
        <div class="main_right-column">
          <template v-if="!accounts || accounts.length">
            <div class="adress-box">
              <div class="adress-box_personal-account">
                Адрес:
                <el-select
                  v-model="selectedAccoutID"
                  value-key="AccountID"
                  filterable
                  style="margin-left: 10px; border: 1 px solid #dcdfe6;"
                  @change="selectGroup"
                >
                  <!-- <el-option :value="null" label="Все">Все</el-option> -->
                  <el-option
                    v-for="(info, i) in groupAccounts"
                    :value="info"
                    :label="info.Address ? info.Address : info.AccountTypes"
                    :key="`select-bill-${i}`"
                  >
                    {{ info.Address ? info.Address : info.AccountType }}
                  </el-option>
                </el-select>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="adress-box">
              <div class="common-warning-text">
                Лицевые счета не подключены
              </div>
              <a @click="openAddAccountIdentModal" class="common-warning-link">
                + Подключить лицевой счёт
              </a>
            </div>
          </template>
        </div>
      </div>

      <div class="row testimony-content">
        <template v-if="!accounts || accounts.length">
          <template v-if="metersBySelectedIdent.length">
            <div
              v-for="(meter, i) in metersBySelectedIdent"
              :key="`meter-${i}`"
              class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
            >
              <div class="testimony-item">
                <div class="testimony-item_header">
                  <span :class="['item-icon', ...getIcon(meter.Resource)]"></span>
                  {{ meter.Resource }}
                </div>
                <ul class="testimony-item_top-name_block">
                  <li>
                    <span class="digit">{{
                      meter.CustomName || meter.Name
                    }}</span>
                  </li>
                </ul>
                <ul class="testimony-item_top-list">
                  <li>
                    Заводской №:
                    <span class="digit">{{ meter.FactoryNumber }}</span>
                  </li>
                  <li>
                    Следующая поверка:
                    <span class="digit">{{ meter.NextCheckupDate || "-" }}</span>
                  </li>
                  <li>
                    Межповерочный интервал:
                    <span class="digit" v-if="meter.RecheckInterval"
                      >{{ meter.RecheckInterval }} лет</span
                    ><span v-else>-</span>
                  </li>
                </ul>
                <!-- <ul class="testimony-item_digit-list">
                    <li v-for="(val, index) in meter.Values" :key="`meter-${meter.ID}-val-${index}`">
                      <span class="left-text">{{ val.Period }} <span class="link-text" v-if="val.IsCurrentPeriod"><a href="#" @click="deleteMeterValue(meter.ID)">Удалить</a></span></span>                    
                      <span class="right-text">{{ val.Value }}</span>
                    </li>
                  </ul> -->

                <table class="testimony-item_digit-table">
                  <tr
                    class="testimony-item_digit-tarifName"
                    v-if="meter.TariffNumberInt > 1"
                  >
                    <td></td>
                    <td class="testimony-item_digit-tarifName-item">
                      {{ meter.Tariff1Name || "T1" }}
                    </td>
                    <td
                      v-if="meter.TariffNumberInt > 1"
                      class="testimony-item_digit-tarifName-item"
                    >
                      {{ meter.Tariff2Name || "T2" }}
                    </td>
                    <td
                      v-if="meter.TariffNumberInt > 2"
                      class="testimony-item_digit-tarifName-item"
                    >
                      {{ meter.Tariff3Name || "T3" }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr
                    class="testimony-item_digit-line"
                    v-for="(val, index) in meter.Values"
                    :key="`meter-${meter.ID}-val-${index}`"
                  >
                    <td class="testimony-item_digit-line-period">
                      <span>{{ val.Period }}:</span>
                    </td>
                    <td class="testimony-item_digit-line-value">
                      {{ val.Value }}
                    </td>
                    <td
                      class="testimony-item_digit-line-value"
                      v-if="meter.TariffNumberInt > 1"
                    >
                      {{ val.ValueT2 }}
                    </td>
                    <td
                      class="testimony-item_digit-line-value"
                      v-if="meter.TariffNumberInt > 2"
                    >
                      {{ val.ValueT3 }}
                    </td>
                    <td class="testimony-item_digit-line-remove">
                      <span
                        v-if="val.IsCurrentPeriod"
                        class="digit-line-remove-icon link-text"
                        ><a href="#" @click="deleteMeterValue(meter.ID)"
                          >Удалить</a
                        ></span
                      >
                    </td>
                    <td class="testimony-item_digit-line-kind">
                      <span
                        :class="[
                          'digit-line-kind-icon',
                          ...getMeterValueKindIcon(val.Kind),
                        ]"
                        >{{ val.Kind }}</span
                      >
                    </td>
                  </tr>
                  <tr class="testimony-item_digit-line" v-if="meter.StartValue">
                    <td class="testimony-item_digit-line-period">Начальные:</td>
                    <td class="testimony-item_digit-line-value">
                      {{ meter.StartValue }}
                    </td>
                    <td
                      class="testimony-item_digit-line-value"
                      v-if="meter.TariffNumberInt > 1"
                    >
                      {{ meter.StartValueT2 || 0 }}
                    </td>
                    <td
                      class="testimony-item_digit-line-value"
                      v-if="meter.TariffNumberInt > 2"
                    >
                      {{ meter.StartValueT3 || 0 }}
                    </td>
                    <td class="testimony-item_digit-line-remove"></td>
                    <td class="testimony-item_digit-line-kind"></td>
                  </tr>
                </table>

                <div
                  class="testimony-item_row"
                  @click="getMeterValues(meter.ID, meter.UniqueNum)"
                >
                  <button class="testimony-item_digit-button">
                    <div class="testimony-item_digit-hamburger-box">
                      <div class="testimony-item_digit-hamburger-inner"></div>
                    </div>
                    <span class="testimony-item_digit-button-text"
                      >Все показания</span
                    >
                  </button>
                </div>

                <modal-wrapper
                  :show="activeTestimonyModalId === meter.ID"
                  click-otsitde
                  @close="activeTestimonyModalId = null"
                  class="testimony_modal"
                >
                  <div class="testimony-item_header">
                    <span
                      :class="['item-icon', ...getIcon(meter.Resource)]"
                    ></span>
                    {{ meter.Resource }}
                  </div>
                  <ul class="testimony-item_top-name_block">
                    <li>
                      <span class="digit">{{
                        meter.CustomName || meter.Name
                      }}</span>
                    </li>
                  </ul>
                  <ul class="testimony-item_top-list">
                    <li>
                      Заводской №:
                      <span class="digit">{{ meter.FactoryNumber }}</span>
                    </li>
                    <li>
                      Следующая поверка:
                      <span class="digit">{{
                        meter.NextCheckupDate || "-"
                      }}</span>
                    </li>
                    <li>
                      Межповерочный интервал:
                      <span class="digit" v-if="meter.RecheckInterval"
                        >{{ meter.RecheckInterval }} лет</span
                      ><span v-else>-</span>
                    </li>
                  </ul>
                  <table class="testimony-item_digit-table">
                    <tr
                      class="testimony-item_digit-tarifName"
                      v-if="meter.TariffNumberInt > 1"
                    >
                      <td></td>
                      <td class="testimony-item_digit-tarifName-item">
                        {{ meter.Tariff1Name || "T1" }}
                      </td>
                      <td
                        v-if="meter.TariffNumberInt > 1"
                        class="testimony-item_digit-tarifName-item"
                      >
                        {{ meter.Tariff2Name || "T2" }}
                      </td>
                      <td
                        v-if="meter.TariffNumberInt > 2"
                        class="testimony-item_digit-tarifName-item"
                      >
                        {{ meter.Tariff3Name || "T3" }}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr
                      class="testimony-item_digit-line"
                      v-for="(val, index) in meterValues"
                      :key="`meter-${meter.ID}-val-${index}`"
                    >
                      <td class="testimony-item_digit-line-period">
                        <span>{{ val.Period }}:</span>
                      </td>
                      <td class="testimony-item_digit-line-value">
                        {{ val.Value }}
                      </td>
                      <td
                        class="testimony-item_digit-line-value"
                        v-if="meter.TariffNumberInt > 1"
                      >
                        {{ val.ValueT2 }}
                      </td>
                      <td
                        class="testimony-item_digit-line-value"
                        v-if="meter.TariffNumberInt > 2"
                      >
                        {{ val.ValueT3 }}
                      </td>
                      <td class="testimony-item_digit-line-remove">
                        <span
                          v-if="val.IsCurrentPeriod"
                          class="digit-line-remove-icon link-text"
                          ><a href="#" @click="deleteMeterValue(meter.ID)"
                            >Удалить</a
                          ></span
                        >
                      </td>
                      <td class="testimony-item_digit-line-kind">
                        <span
                          :class="[
                            'digit-line-kind-icon',
                            ...getMeterValueKindIcon(val.Kind),
                          ]"
                          >{{ val.Kind }}</span
                        >
                      </td>
                    </tr>
                    <tr class="testimony-item_digit-line" v-if="meter.StartValue">
                      <td class="testimony-item_digit-line-period">
                        Начальные:
                      </td>
                      <td class="testimony-item_digit-line-value">
                        {{ meter.StartValue }}
                      </td>
                      <td
                        class="testimony-item_digit-line-value"
                        v-if="meter.TariffNumberInt > 1"
                      >
                        {{ meter.StartValueT2 || 0 }}
                      </td>
                      <td
                        class="testimony-item_digit-line-value"
                        v-if="meter.TariffNumberInt > 2"
                      >
                        {{ meter.StartValueT3 || 0 }}
                      </td>
                      <td class="testimony-item_digit-line-remove"></td>
                      <td class="testimony-item_digit-line-kind"></td>
                    </tr>
                  </table>
                </modal-wrapper>
                <template v-if="meter.AutoValueGettingOnly">
                  <div class="attention-text">
                    Автоматическое снятие
                  </div>
                </template>
                <template v-else-if="meter.IsDisabled">
                  <div class="attention-text">
                    Счетчик выбыл
                  </div>
                </template>
                <template v-else>
                  <!-- v-if="new Date().getDate() > 15 && new Date().getDate() < 25"> -->
                  <div class="testimony-current_info">
                    <template
                      v-if="
                        meter.Values &&
                          meter.Values.length &&
                          meter.Values[0].IsCurrentPeriod
                      "
                      >Показания переданы {{ meter.Values[0].Period }}</template
                    >
                  </div>
                  <template v-if="getDiffCheckupDate(meter.NextCheckupDate) > 0">
                    <a
                      v-if="meter.ValuesCanAdd"
                      class="main-btn testimony-item_btn"
                      @click="openAddMeterModal(meter.ID)"
                    >
                      <template
                        v-if="
                          meter.Values &&
                            meter.Values.length &&
                            meter.Values[0].IsCurrentPeriod
                        "
                        >Изменить показания</template
                      >
                      <template v-else>Передать показания</template>
                    </a>
                    <div class="attention-text" v-else>
                      {{ meter.PeriodMessage }}
                    </div>
                  </template>
                </template>
                <template
                  v-if="
                    meter.AllowMeterVerificationRequest &&
                      getDiffCheckupDate(meter.NextCheckupDate) <= 30 &&
                      verificationService
                  "
                >
                  <a
                    class="testimony-item_btn meter__requestverification"
                    :class="{
                      'meter__requestverification--disabled': isNotAllowedVerification,
                    }"
                    @click="openVerificationConfirmationModal(meter.ID)"
                  >
                    <span class="application-timer_icon"
                      ><img src="/img/icons/timer.png" alt="alt"
                    /></span>
                    Заказать поверку
                  </a>
                </template>
              </div>
            </div>
          </template>

          <div
            v-else
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
          >
            <div class="testimony-item" style="min-height: 330px;">
              <div class="testimony-item_header">
                <span :class="['item-icon']"></span>
                Нет приборов учета.
              </div>
              <div class="attention-text">
                Для выбранного лицевого счета не зарегистрировано ни одного
                прибора учета.
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <modal-wrapper
      :show="showNoticeModal"
      @close="toggleNoticeModal"
      class="testimony_modal"
    >
      <p>
        Внимание. Заявка на поверку успешно создана. Номер заявки:
        {{ selectedRequestInfo.RequestNumber }}. В ближайшее время с вами
        свяжется специалист для уточнения стоимости, даты и времени поверки.
      </p>
    </modal-wrapper>
    <modal-wrapper
      :show="showConfirmationModal"
      @close="showConfirmationModal = false"
      class="testimony_modal"
    >
      Создать заявку на поверку?
      <div class="confirmation__button-wrapper">
        <input
          type="submit"
          class="confirmation__reject-button"
          @click="showConfirmationModal = false"
          value="Нет"
        />
        <input
          type="submit"
          class="confirmation__approve-button"
          @click="onRequestVerification"
          value="Да"
        />
      </div>
    </modal-wrapper>
  </main>
</template>

<script>
import modalWrapper from "@/components/modals/modalWrapper";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Meters",
  components: { modalWrapper },
  data() {
    return {
      //accountInfoIDs: null,
      accounts: null,
      selectedIdent: null,
      selectedAccoutID: null,

      meterIDs: [],

      isLoading: false,
      showTestimonyModal: false,
      activeTestimonyModalId: null,

      selectedMeter: null,
      showNoticeModal: false,
      showConfirmationModal: false,

      selectedRequestInfo: {},
    };
  },

  mounted() {
    this.initPage();
    this.getEventBlockData();
  },

  created() {
    this.$root.$on("updated-account-info", this.initPage);
    this.$root.$on("deleted-account-info", this.deleteLocalAccountInfo);
    this.$root.$on("added-meter", this.getMetersList);
  },

  destroyed() {
    this.$root.$off("updated-account-info");
    this.$root.$off("deleted-account-info");
    this.$root.$off("added-meter");
  },

  methods: {
    ...mapActions({
      getEventBlockData: "news/getEventBlockData",
      newRequest: "request/newShopRequest",
      getRequestDetails: "request/getRequestDetails",
      createRequestOnMeterRecheck: "meter/createRequestOnMeterRecheck",
      getInfo: "account/getInfo",
    }),

    initPage() {
      this.getAccountInfo();
      this.getMetersList();
    },

    getAccountInfo() {
      this.isLoading = true;

      this.$store
        .dispatch("account/getUserInfo")
        .then((result) => {
          this.accounts = result.Accounts;
        })

      this.getInfo()
        .finally(() => {
          this.isLoading = false;

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    deleteLocalAccountInfo(id) {
      if (!this.accounts) {
        return;
      }
      let index = this.accounts.map((x) => x.AccountID).indexOf(id);
      if (index !== -1) this.accounts.splice(index, 1);
    },

    getMetersList() {
      this.isLoading = true;

      this.meterIDs = [];

      this.$store
        .dispatch("meter/getMetersList")
        .then((result) => {
          this.meterIDs = [...result.keys];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getMeterValues(id, uniqueNum) {
      this.$store
        .dispatch("meter/getMeterValues", { meterUniqueNum: uniqueNum })
        .then(() => {
          this.activeTestimonyModalId = id;
        });
    },

    deleteMeterValue(id) {
      if (
        confirm("Действительно удалить переданные показания по прибору учета?")
      ) {
        this.$store
          .dispatch("meter/deleteMeterValue", { ID: id })
          .then(() => {
            this.getMetersList();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    getIcon(meterResource) {
      if (
        ["Горячая вода", "Горячее водоснабжение", "Горячее ВС", "ГВС"].includes(
          meterResource
        )
      ) {
        return ["glipf-drop"];
      } else if (
        [
          "Холодная вода",
          "Холодное водоснабжение",
          "Холодное ВС",
          "ХВС",
        ].includes(meterResource)
      ) {
        return ["glipf-drop", "glipf-drop_mod"];
      } else if (meterResource.match(/(электр)/i) !== null) {
        return ["glipf-electricity"];
      } else if (meterResource.match(/([Гг]аз)/i) !== null) {
        return ["glipf-gas-testimony"];
      } else if (["Тепловая энергия"].includes(meterResource)) {
        return ["glipf-thermo-testimony"];
      } else {
        return ["glipf-unknown_meter"];
      }
    },
    getMeterValueKindIcon(meterValueKind) {
      if (meterValueKind == "Передано") {
        return ["icon_is-sent"];
      } else if (meterValueKind == "Учтено") {
        return ["icon_checked"];
      } else {
        return [""];
      }
    },
    getDiffCheckupDate(nextCheckupDate) {
      let diff;
      if (nextCheckupDate) {
        let checkupDate = nextCheckupDate.split(".");
        checkupDate = new Date(
          checkupDate[2],
          parseInt(checkupDate[1]) - 1,
          checkupDate[0]
        );
        const nowDate = new Date();
        diff = (checkupDate - nowDate) / (1000 * 60 * 60 * 24) + 1;
      }
      if (diff) {
        return diff;
      } else {
        diff = 9999;
      }

      return diff;
    },
    toggleNoticeModal() {
      this.showNoticeModal = !this.showNoticeModal;
    },
    toggleConfirmationModal() {
      this.showConfirmationModal = !this.showConfirmationModal;
    },
    openVerificationConfirmationModal(meterId) {
      if (!this.isNotAllowedVerification) {
        this.selectedMeter = this.getMeterById(meterId);
        this.toggleConfirmationModal();
      }
    },
    onRequestVerification() {
      this.createRequestOnMeterRecheck(this.selectedMeter.ID)
        .then((response) => {
          //this.getMetersList();
          if (response.data.isSucceed) {
            return this.getRequestDetails(response.data.id).then((result) => {
              this.selectedRequestInfo = {
                RequestNumber: result.RequestNumber,
              };
            });
          } else {
            throw new Error(response.data.error);
          }
        })
        .then(() => {
          this.toggleNoticeModal();
        })
        .finally(() => {
          this.getMetersList();
          this.toggleConfirmationModal();
        });
    },

    selectGroup(e) {
      this.selectedIdent = e.Ident;
    }
  },
  computed: {
    ...mapState(["additionalService"]),
    ...mapGetters({
      getMeters: "meter/getMeters",
      getMeterById: "meter/getMeterById",
      getAllInfo: 'account/getAllInfo',
      getAccountSettings: 'account/getAccountSettings',
    }),

    selectedAccountInfo() {
      if (!this.selectedIdent) {
        return [];
      }

      return this.getAllInfo()
        .filter((account) => {
          // if (account.OriginalIdent) {
            // return account.OriginalIdent === this.selectedIdent;
            return account.Ident === this.selectedIdent || account.OriginalIdent === this.selectedIdent;
          // }

          // const address = this.accounts.find(account => account.Ident === this.selectedIdent).Address;

          // return account.Address === address;
        });
    },

    meters() {
      return this.getMeters(this.meterIDs);
    },

    meterValues() {
      return this.$store.state.meter.meterValues;
    },

    metersBySelectedIdent() {
      if (this.selectedAccountInfo.length) {
        let meters = [];

        this.selectedAccountInfo.map((selectedAccount) => {
          const filteredMeters = this.meters.filter((meter) => {
            return meter.Ident === selectedAccount.Ident;
          });
          
          if (filteredMeters.length) {
            meters = [
              ...meters, 
              ...filteredMeters
            ];
          }
        });

        return meters;
      }

      return this.meters;
    },

    verificationService() {
      let service = Object.entries(this.additionalService.services).find(
        (item) => {
          return item[1].Name === "Поверка (замена) прибора";
        }
      );
      if (service) {
        return service[1];
      }
      return null;
    },
    isNotAllowedVerification() {
      const foundedMeter = this.metersBySelectedIdent.find((meter) => {
        return meter.ExistVerificationRequest;
      });
      //Если есть хоть один счетчик с ExistVerificationRequest=true
      //То отключаем для всего ЛС все кнопки заказа поверки
      return !!foundedMeter;
    },

    groupAccounts() {
      return this.getAllInfo()
        .filter((x, i, self) => {
          return self.findIndex((t) => t.Address == x.Address) === i;
        })
        .filter((account) => {
          return !this.getAccountSettings(account.AccountID).HideOnMetersPage;
        })
        .sort((a, b) => a.Ident - b.Ident);
    },

  },
};
</script>

<style lang="scss" rel="stylesheets/scss" scoped>
.confirmation__button-wrapper {
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: space-between;
  padding: 5px;
}
.confirmation__approve-button {
  flex: 0 0 50%;
  margin: 5px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  background-color: var(--base-color);
  -webkit-box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0 1em;
  color: #fff;
  text-align: center;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  white-space: nowrap;
  z-index: 1;
  cursor: pointer;
}
.confirmation__approve-button:active {
  -webkit-box-shadow: inset 1px 2px #6d0005;
  box-shadow: inset 1px 2px #6d0005;
  border-top: 1px solid #6d0005;
}
.confirmation__reject-button {
  flex: 0 0 50%;
  margin: 5px;
  border: 2px solid var(--base-color);
  color: var(--base-color);
  border-radius: 10px;
}
.confirmation__reject-button:hover {
  color: white;
  background-color: var(--base-color);
}

.confirmation__reject-button:active {
  -webkit-box-shadow: inset 1px 2px #6d0005;
  box-shadow: inset 1px 2px #6d0005;
  border-top: 1px solid #6d0005;
}
</style>

<style lang="scss">
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}

.testimony-item_row {
  margin-bottom: 15px;
}

.testimony-item_digit-button {
  display: inline-flex;
  align-items: center;

  background: none;
  border: none;
}

.testimony-item_digit-hamburger-box {
  position: relative;

  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.testimony-item_digit-hamburger-inner {
  top: 8px;
}

.testimony-item_digit-hamburger-inner,
.testimony-item_digit-hamburger-inner:after,
.testimony-item_digit-hamburger-inner:before {
  position: absolute;

  height: 2px;
  width: 100%;

  background: var(--base-color);
}

.testimony-item_digit-hamburger-inner:after,
.testimony-item_digit-hamburger-inner:before {
  display: block;
  content: "";
}

.testimony-item_digit-hamburger-inner:before {
  top: -6px;
}

.testimony-item_digit-hamburger-inner:after {
  bottom: -6px;
}

.testimony-item_digit-button-text {
  color: var(--base-color);
  // border-bottom: 1px solid var(--base-color);
  box-shadow: 0 1px 0 0 var(--base-color);
  transition: box-shadow 0.3s;
}

.testimony-item_digit-button:hover .testimony-item_digit-button-text {
  box-shadow: none;
  transition: box-shadow 0.3s;
}
.meter__requestverification {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-width: 200px;
  height: 60px;
  line-height: 60px;
  background-color: #ff0713;
  -webkit-box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: auto;
  padding: 0 1em;
  color: #fff;
  text-align: center;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  white-space: nowrap;
  z-index: 1;
  cursor: pointer;
}

.meter__requestverification:active {
  -webkit-box-shadow: inset 1px 2px #6d0005;
  box-shadow: inset 1px 2px #6d0005;
  border-top: 1px solid #6d0005;
}
.meter__requestverification--disabled {
  cursor: default;
  background-color: #dadada;
}
.meter__requestverification--disabled:active {
  -webkit-box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 8px 16.7px 1.3px rgba(0, 0, 0, 0.13);
  border-top: none;
}
</style>
