var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop"},[_vm._t("default",[_c('router-link',{attrs:{"to":{
        name: 'Shop',
        params: {
          id: _vm.shop.ShopID,
          AdditionalServiceId: _vm.shop.ID,
          type: _vm.shop.ShopType,
          id_RequestType: _vm.shop.id_RequestType,
          breadcrumbs: [
            {
              text: ("" + _vm.serviceName),
              route: {
                name: 'Services',
                params: { serviceId: _vm.shop.ID },
              },
            },
            {
              text: ("" + (_vm.shop.Name)),
              route: '',
            } ],
        },
        props: {},
      }}},[(_vm.shop.HasLogo)?_c('img',{staticClass:"shop__img",attrs:{"src":_vm.shop.LogoLink,"alt":_vm.shop.Name}}):_vm._e(),_c('div',{staticClass:"shop__desc"},[_vm._v(_vm._s(_vm.shop.Name))])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }