<template>
  <main class="main-content">
    <div class="meeting-owners_section">
      <div class="container">
        <div class="main-title">
          Приветствуем вас
          <small>на собрании собственников жилья!</small>
        </div>
        <div class="row">
          <div class="meeting-owners_col-left">
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod">
                <span class="glipf-info"></span>Общая информация
              </div>
              <div class="meeting-information_box">
                <div class="row">
                  <div class="col-md-8">
                    <ul class="meeting-information_list">
                      <li>
                        <span class="item-title">Наименование документа:</span>
                        {{ oss.MeetingTitle }}
                      </li>
                      <li>
                        <span class="item-title">Инициатор собрания: </span>
                        {{ oss.Author }}
                      </li>
                      <li>
                        <span class="item-title">Адрес дома: </span>
                        {{ oss.HouseAddress }}
                      </li>
                      <li>
                        <span class="item-title"
                          >Реквизиты, подтверждающие собственность:</span
                        >
                        <p
                          v-for="account in oss.Accounts"
                          :key="
                            `oss_acc-${account.Ident}-${account.PremiseNumber}`
                          "
                        >
                          {{ account.Document
                          }}{{
                            account.DocumentDate
                              ? " от " + account.DocumentDate
                              : ""
                          }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <ul class="meeting-information_list">
                      <li>
                        <span class="item-title">Номер собственности:</span
                        >{{ account_premises }}
                      </li>
                      <li>
                        <span class="item-title">Площадь: </span
                        >{{ account_areas }}
                      </li>
                      <li>
                        <span class="item-title">Доля:</span
                        >{{ account_ownership }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <ul class="meeting-information_list">
                    <li class="last-child">
                      <span class="item-title">Дата собрания: </span>
                      С {{ oss.DateStart }} по {{ oss.DateEnd }} включительно.
                    </li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <ul class="meeting-information_list">
                    <li class="last-child">
                      <span class="item-title">Форма проведения:</span>
                      {{ oss.Form }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod">
                <span class="glipf-agenda"></span>Повестка собрания
              </div>
              <ul class="meeting-information_list">
                <li
                  v-for="question in oss.Questions"
                  :key="`question-${question.ID}`"
                >
                  <span class="item-title">Вопрос № {{ question.Number }}</span>
                  {{ question.Text }}
                  <div class="item-file" v-if="question.HasFile">
                    <span class="glipf-pdf"></span>
                    <span class="item-desc"
                      >Файл для обсуждения.pdf<br /><a
                        :href="question.FileLink"
                        download=""
                        >Скачать</a
                      ></span
                    >
                  </div>
                </li>
              </ul>
            </div>

            <!--Сведения об администраторе-->
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod">
                <span class="glipf-admin2"></span>Сведения об администраторе
              </div>
              <template v-if="oss.AdministratorIsFL">
                <div class="meeting-information_box">
                  <div class="row">
                    <ul class="meeting-information_list">
                      <li>
                        <span class="item-title">ФИО: </span
                        >{{ oss.AdminstratorName }}
                      </li>
                      <li>
                        <span class="item-title">Паспортные данные: </span
                        >{{ oss.AdminstratorDocNumber }}
                      </li>
                      <li>
                        <span class="item-title"
                          >Место постоянного проживания</span
                        >{{ oss.AdminstratorAddress }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorPhone">
                        <span class="item-title"
                          >Контактный номер телефона:
                        </span>
                        <a
                          :href="`tel:${oss.AdminstratorPhone}`"
                          class="admin-phone"
                          >{{ oss.AdminstratorPhone }}</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorEmail">
                        <span class="item-title"
                          >Адрес электронной почты:
                        </span>
                        <a
                          :href="`mailto:${oss.AdminstratorEmail}`"
                          class="admin-email"
                          >{{ oss.AdminstratorEmail }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="meeting-information_box">
                  <div class="row">
                    <ul class="meeting-information_list">
                      <li>
                        <span class="item-title">Фирменное наименование:</span
                        >{{ oss.AdminstratorName }}
                      </li>
                      <li>
                        <span class="item-title"
                          >Организационно-правовая форма:</span
                        >{{ oss.AdminstratorDocNumber }}
                      </li>
                      <li>
                        <span class="item-title">Место нахождения: </span>
                        {{ oss.AdminstratorAddress }}
                      </li>
                      <li>
                        <span class="item-title">Почтовый адрес: </span
                        >{{ oss.AdminstratorPostAddress }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorPhone">
                        <span class="item-title"
                          >Контактный номер телефона:
                        </span>
                        <a
                          :href="`tel:${oss.AdminstratorPhone}`"
                          class="admin-phone"
                          >{{ oss.AdminstratorPhone }}</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorSite">
                        <span class="item-title">Официальный сайт: </span>
                        <a :href="oss.AdminstratorSite" class="site-link">{{
                          oss.AdminstratorSite
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="meeting-owners_col-right">
            <!--Статус собрания-->
            <div class="unified-box unified-box_mod unified-box_status">
              <div class="unified-box_title-mod">
                Статус собрания:
              </div>

              <div :class="'status-meeting ' + oss_status.statusClass">
                <span :class="oss_status.glipf"></span
                >{{ oss_status.statusText }}
              </div>
              <!-- <a href="" class="main-btn">Принять участие</a> -->
              <router-link
                v-if="oss_status.canVote"
                class="main-btn"
                :to="{ name: 'OssVote', params: { id: oss.ID } }"
                >Принять участие</router-link
              >
              <router-link
                v-if="oss_status.canViewResults"
                class="main-btn"
                :to="{ name: 'OssResult', params: { id: oss.ID } }"
                >Результаты голосования</router-link
              >
            </div>

            <!--Сведения о собственности-->
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod">
                <span class="glipf-briefcase"></span>Сведения о собственности
              </div>
              <ul class="meeting-information_list">
                <li>
                  <span class="item-title"
                    >Реквизиты, подтверждающие собственность:
                  </span>
                  <p
                    v-for="account in oss.Accounts"
                    :key="`oss_acc-${account.Ident}-${account.PremiseNumber}`"
                  >
                    {{ account.Document
                    }}{{
                      account.DocumentDate ? " от " + account.DocumentDate : ""
                    }}
                  </p>
                </li>
              </ul>
              <div class="row">
                <div class="col-md-7">
                  <ul class="meeting-information_list">
                    <li>
                      <span class="item-title">Номер собственности: </span
                      >{{ account_premises }}
                    </li>
                    <li>
                      <span class="item-title item-title_mod">Доля:</span
                      >{{ account_ownership }}
                    </li>
                  </ul>
                </div>
                <div class="col-md-5">
                  <ul class="meeting-information_list">
                    <li>
                      <span class="item-title">Площадь: </span
                      >{{ account_areas }}
                    </li>
                  </ul>
                </div>
              </div>
              <ul class="meeting-information_list">
                <li class="last-child">
                  <span class="item-title">Общая площадь помещений: </span
                  >Жилые: {{ oss.AreaResidential }} м.кв. <br />
                  Нежилые: {{ oss.AreaNonresidential }} м.кв.
                </li>
              </ul>
            </div>

            <!--Порядок приёма решений-->
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod">
                <span class="glipf-decision"></span>Порядок приёма решений
              </div>
              <ul class="meeting-information_list">
                <li>
                  <span class="item-title">Электронные образцы: </span>
                  <a :href="oss.WebSiteForScanDocView">{{
                    oss.WebSiteForScanDocView
                  }}</a>
                </li>
                <li class="last-child">
                  <span class="item-title">Рукописные решения: </span>
                  {{ oss.PlaceOfReceiptSolutions }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "OssInfo",
  data() {
    return {
      //ossIDs: [],
      oss: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getOSS();
  },
  methods: {
    getOSS() {
      this.isLoading = true;
      this.$store
        .dispatch("oss/getOssById", this.idOss)
        .then((result) => {
          this.oss = result;
          this.isLoading = false;
          this.$store.dispatch("oss/setAcquainted", this.idOss);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    idOss() {
      return this.$route.params.id;
    },
    //   oss() {
    //     return this.$store.getters['oss/getOssById'](this.idOss);
    //   },
    account_premises() {
      var oss = this.oss;
      var result = "";
      if (!oss.Accounts) {
        return result;
      }
      for (let i = 0; i < oss.Accounts.length; i++) {
        if (i > 0) {
          result += "; ";
        }
        result += oss.Accounts[i].PremiseNumber;
      }
      return result;
    },
    account_areas() {
      var oss = this.oss;
      var result = "";
      if (!oss.Accounts) {
        return result;
      }
      for (let i = 0; i < oss.Accounts.length; i++) {
        if (i > 0) {
          result += "; ";
        }
        result += oss.Accounts[i].Area;
      }
      return result + " м.кв.";
    },
    account_ownership() {
      var oss = this.oss;
      var result = "";
      if (!oss.Accounts) {
        return result;
      }
      for (let i = 0; i < oss.Accounts.length; i++) {
        if (i > 0) {
          result += "; ";
        }
        result += oss.Accounts[i].PropertyPercent;
      }
      return result + " %";
    },
    oss_status() {
      return this.$store.getters["oss/getStateParameters"](this.oss);
    },
  },
};
</script>

<style scoped></style>
