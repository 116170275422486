<template>
  <!-- Модальное окно -->
  <main class="main-content main-content_application">
    <modal-wrapper :show="showDialogModal" @close="showDialogModal = false">
      <template v-if="hasAccounts">
        <div v-if="selectedRequestInfo">
          <div class="requestinfo-name">
            <span class="requestinfo-text" v-text="selectedRequestHeader">
            </span>
          </div>
          <div class="requestinfo-text">
            {{ selectedRequestInfo.added }}
          </div>
          <div class="requestinfo-space" />
          <div>
            <span class="requestinfo-label">Тип:</span>
            <span class="requestinfo-text">{{ selectedRequestInfo.type }}</span>
          </div>
          <div>
            <span class="requestinfo-label">Адрес:</span>
            <span class="requestinfo-text">{{
              selectedRequestInfo.address
            }}</span>
          </div>
          <div>
            <span class="requestinfo-label">Телефон:</span>
            <span class="requestinfo-text">{{
              selectedRequestInfo.phone
            }}</span>
          </div>
          <div>
            <span class="requestinfo-label">Код подтверждения</span>
            <span class="requestinfo-text">{{
              selectedRequestInfo.PaidRequestCompleteCode
            }}</span>
          </div>
          <div class="requestinfo-space" />
          <div>
            <span class="glipf-clock-2 item-icon requestinfo-label"></span>
            <span class="requestinfo-text">{{
              selectedRequestInfo.status
            }}</span>
          </div>
          <a
            v-if="selectedRequestInfo.CanCancelingRequest"
            @click="closeRequest"
            style="cursor: pointer"
            :class="[
              'main-btn closeRequest-btn',
              !(selectedRequestInfo && selectedRequestInfo.canClose)
                ? 'closeRequest-btn_disabled'
                : '',
            ]"
            >Закрыть заявку</a
          >
          <div class="requestinfo-space" />
        </div>
        <div class="wrapper_application-content">
          <div
            class="application_main-content scroll-content scroll-content_application-content"
            ref="dialogListScroll"
          >
            <div
              v-loading="isLoadingMessages"
              v-for="(messagesArray, date) in messages"
              :key="`list-date-${date}`"
            >
              <div class="wrapper_application-date">
                <span class="application-date">{{ date }}</span>
              </div>

              <div
                v-for="message in messagesArray"
                :key="`message-${message.ID}`"
                :class="[
                  'application-item_mod',
                  { 'right-content': message.IsSelf },
                ]"
              >
                <div class="application_time-user">
                  <div class="application-user_name" v-if="!message.IsSelf">
                    <span class="application-user_icon"
                      ><img src="/img/icons/admin.png" alt="alt"
                    /></span>
                    {{ message.AuthorName }}
                  </div>
                  <span class="application-time">{{ message.Added }}</span>
                  <span class="application-user_icon" v-if="message.IsSelf">
                    <img src="/img/icons/user.png" alt="alt" />
                  </span>
                </div>
                <div
                  :class="[
                    'text-part_application',
                    { 'red-bg': message.IsSelf },
                  ]"
                >
                  <template v-if="message.FileID > 0">
                    <a
                      @click="getFile(message.FileID)"
                      style="cursor: pointer"
                      >{{ message.Text }}</a
                    >
                  </template>
                  <pre v-else>
                    {{ message.Text }}
                  </pre>
                </div>
              </div>
            </div>

            <div
              v-if="showPayButton && canPay"
              class="request-dialog_pay-panel"
            >
              <button
                class="main-btn request-dialog_pay-button"
                @click="checkSumIsVerified"
              >
                Оплатить
              </button>
            </div>
          </div>

          <form
            v-if="!disableCommentingRequests"
            v-on:submit.prevent
            class="form-message"
            v-show="selectedRequestID"
          >
            <label class="glipf-clip" style="cursor: pointer">
              <input
                type="file"
                @change="addFile"
                name="file"
                style="display: none"
              />
            </label>
            <input
              type="text"
              :disabled="isSendingMessage"
              v-model="form.message"
              @keyup.enter="addMessage"
              class="form-mesage_input"
              placeholder="Введите сообщение.."
            />
            <div
              class="wrapper_form-message_submit"
              v-loading="isSendingMessage"
            >
              <input
                type="submit"
                @click="addMessage"
                :disabled="form.message.length === 0"
                value=""
                class="form-message_submit"
              />
              <span class="glipf-message-3"></span>
            </div>
          </form>
        </div>
      </template>
      <template v-else>
        <div class="adress-box">
          <div class="common-warning-text">Лицевые счета не подключены</div>
          <a
            @click="openAddAccountIdentModal"
            style=""
            class="common-warning-link"
          >
            + Подключить лицевой счёт
          </a>
        </div>
      </template>
    </modal-wrapper>

    <div class="container">
      <div class="row">
        <div class="main_left-column">
          <div class="main-title">Заявки</div>
          <template v-if="hasAccounts">
            <a
              @click="openNewRequestModal"
              style="cursor: pointer"
              class="main-btn create-new_application-btn"
              >Создать новую заявку</a
            >
            <div
              class="show-closed_application"
              v-show="request.requests.length > 0"
            >
              <SwitchBtn v-model="showClosed" />
              Показать закрытые
            </div>

            <!--Список заявок-->
            <div
              class="scroll-content scroll-content_application"
              v-loading="isLoadingRequests"
              ref="requestListScroll"
            >
              <div
                v-for="request in filteredRequests"
                :key="`request-list-${request.ID}`"
                @click="openRequest(request.ID)"
                style="cursor: pointer"
                :class="[
                  'application-item',
                  { completed: request.IsPerformed },
                ]"
              >
                <div class="application-item_header">
                  №{{ request.RequestNumber }}
                  <span class="application-subject"
                    >• {{ truncateText(request.Name, 100) }}</span
                  >
                </div>
                <span class="application-item_date">{{ request.Added }}</span>
                <span class="application-item_status">
                  <span class="glipf-clock-2 item-icon"></span
                  >{{ request.Status }}
                </span>
                <a class="application-item_btn" style="cursor: pointer"></a>
              </div>
            </div>
          </template>
        </div>

        <!--Диалог-->
        <div v-if="!this.isMobile()" class="main_right-column">
          <template v-if="hasAccounts">
            <div v-if="selectedRequestInfo">
              <div class="requestinfo-name">
                <span class="requestinfo-text" v-text="selectedRequestHeader">
                </span>
              </div>
              <div class="requestinfo-text">
                {{ selectedRequestInfo.added }}
              </div>
              <div class="requestinfo-space" />
              <div>
                <span class="requestinfo-label">Тип:</span>
                <span class="requestinfo-text">{{
                  selectedRequestInfo.type
                }}</span>
              </div>
              <div>
                <span class="requestinfo-label">Адрес:</span>
                <span class="requestinfo-text">{{
                  selectedRequestInfo.address
                }}</span>
              </div>
              <div>
                <span class="requestinfo-label">Телефон:</span>
                <span class="requestinfo-text">{{
                  selectedRequestInfo.phone
                }}</span>
              </div>
              <div>
                <span class="requestinfo-label">Код подтверждения:</span>
                <span class="requestinfo-text">{{
                  selectedRequestInfo.PaidRequestCompleteCode
                }}</span>
              </div>
              <div class="requestinfo-space" />
              <div>
                <span class="glipf-clock-2 item-icon requestinfo-label"></span>
                <span class="requestinfo-text">{{
                  selectedRequestInfo.status
                }}</span>
              </div>
              <a
                v-if="selectedRequestInfo.CanCancelingRequest"
                @click="closeRequest"
                style="cursor: pointer"
                :class="[
                  'main-btn closeRequest-btn',
                  !(selectedRequestInfo && selectedRequestInfo.canClose)
                    ? 'closeRequest-btn_disabled'
                    : '',
                ]"
                >Закрыть заявку</a
              >
              <div class="requestinfo-space" />
            </div>
            <div class="wrapper_application-content">
              <div class="application_main-content" ref="dialogListScroll">
                <!-- <div class="common-warning-text" v-if="!selectedRequestID">
                      Заявка не выбрана
                    </div>
                    <div
                      v-if="selectedRequestInfo && selectedRequestInfo.source"
                      class="wrapper_application-source"
                    >
                      <span class="application-source">{{
                        selectedRequestInfo.source
                      }}</span>
                    </div> -->
                <div
                  v-loading="isLoadingMessages"
                  v-for="(messagesArray, date) in messages"
                  :key="`list-date-${date}`"
                >
                  <div class="wrapper_application-date">
                    <span class="application-date">{{ date }}</span>
                  </div>

                  <div
                    v-for="message in messagesArray"
                    :key="`message-${message.ID}`"
                    :class="[
                      'application-item_mod',
                      { 'right-content': message.IsSelf },
                    ]"
                  >
                    <div class="application_time-user">
                      <div class="application-user_name" v-if="!message.IsSelf">
                        <span class="application-user_icon"
                          ><img
                            :src="resolveUrl('/img/icons/admin.png')"
                            alt="alt"
                        /></span>
                        {{ message.AuthorName }}
                      </div>
                      <span class="application-time">{{ message.Added }}</span>
                      <span class="application-user_icon" v-if="message.IsSelf">
                        <img
                          :src="resolveUrl('/img/icons/user.png')"
                          alt="alt"
                        />
                      </span>
                    </div>
                    <div
                      :class="[
                        'text-part_application',
                        { 'red-bg': message.IsSelf },
                      ]"
                    >
                      <template v-if="message.FileID > 0">
                        <a
                          @click="getFile(message.FileID)"
                          style="cursor: pointer"
                          >{{ message.Text }}</a
                        >
                      </template>
                      <pre v-else>
                        {{ message.Text }}
                      </pre>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showPayButton && canPay"
                  class="request-dialog_pay-panel"
                >
                  <button
                    class="main-btn request-dialog_pay-button"
                    @click="checkSumIsVerified"
                  >
                    Оплатить
                  </button>
                </div>
              </div>

              <form
                v-if="!disableCommentingRequests"
                v-on:submit.prevent
                class="form-message"
                v-show="selectedRequestID"
              >
                <label class="glipf-clip" style="cursor: pointer">
                  <input
                    type="file"
                    @change="addFile"
                    name="file"
                    style="display: none"
                  />
                </label>
                <input
                  type="text"
                  :disabled="isSendingMessage"
                  v-model="form.message"
                  @keyup.enter="addMessage"
                  class="form-mesage_input"
                  placeholder="Введите сообщение.."
                />
                <div
                  class="wrapper_form-message_submit"
                  v-loading="isSendingMessage"
                >
                  <input
                    type="submit"
                    @click="addMessage"
                    :disabled="form.message.length === 0"
                    value=""
                    class="form-message_submit"
                  />
                  <span class="glipf-message-3"></span>
                </div>
              </form>
            </div>
          </template>
          <template v-else>
            <div class="adress-box">
              <div class="common-warning-text">
                Лицевые счета не подключены
              </div>
              <a
                @click="openAddAccountIdentModal"
                style=""
                class="common-warning-link"
              >
                + Подключить лицевой счёт
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
    <modal-wrapper
      v-if="selectedRequestInfo"
      :show="showPaidModal"
      @close="closePaidModal"
      class="request_paid-modal"
    >
      <h3 class="request_paid-modal-title">
        {{ selectedRequestInfo.PaidServiceText }}
      </h3>
      <p class="request_paid-modal-sum">
        Итого:
        <span class="request_paid-modal-sum-value">{{
          selectedRequestInfo.PaidSumm
        }}</span>
        <span class="request_paid-modal-sum-currency">руб.</span>
      </p>
      <!-- <div class="request_paid-modal-radio-group">
        <label class="request_paid-modal-radio">
          <input
            class="select_payment_system_group_radio"
            type="radio"
            value="online"
            v-model="paidType"
          />
          Картой
        </label>
      </div> -->
      <div
        class="request_paid-modal-switch"
        v-if="
          config && config.useBonusSystem && selectedRequestInfo.BonusAmount
        "
      >
        <SwitchBtn v-model="paidWithBonuses" />
        <span class="request_paid-modal-radio"
          >Зачесть бонусами {{ selectedRequestInfo.BonusAmount }} балл(ов)</span
        >
      </div>
      <button class="main-btn request_paid-modal-button" @click="paidRequest">
        Оплатить
      </button>
      <p class="request_paid-modal-description">
        После успешной оплаты Вам придет код подтверждения в смс
      </p>
    </modal-wrapper>
  </main>
</template>

<script>
import SwitchBtn from "@/components/ui/SwitchBtn";
import * as requestApi from "@/api/request";
import download from "downloadjs";
import modalWrapper from "@/components/modals/modalWrapper";
import PerfectScrollbar from "perfect-scrollbar";
import { mapState, mapActions, mapGetters } from "vuex";

var perfectScrollConfig = { dialogListScroll: null, requestListScroll: null };

export default {
  name: "Requests",
  components: { SwitchBtn, modalWrapper },
  props: {
    requestID: null,
  },
  data() {
    return {
      windowWidth: 0,
      hasAccounts: true,
      messageIDs: [],
      selectedRequestID: this.requestID,
      selectedRequestInfo: null,
      templateMessageFiles: [],
      timer: null,
      disableCommentingRequests: false,
      showDialogModal: false,
      showPaidModal: false,
      paidType: "online",
      //форма отправки сообщения
      form: {
        message: "",
        file: null,
      },

      showClosed: false,
      isLoadingRequests: false,
      isLoadingMessages: false,
      isSendingMessage: false,
    };
  },
  mounted() {
    let requestId = this.requestID;

    this.calcWindowWidth();
    window.addEventListener("resize", this.calcWindowWidth);

    this.updateDisableCommentingRequests(this.config.disableCommentingRequests);
    this.getAccountInfo();
    this.getRequests(requestId);
    let target = this;
    this.timer = setInterval(() => {
      target.update();
    }, 30000);

    this.initPerfectScroll();
    setTimeout(this.showPaymentResult, 1000);
  },

  created() {
    this.$root.$on("new-request", this.getRequestsList);
    this.$root.$on("updated-account-info", this.onAccountInfoChanged);
    this.$root.$on("deleted-account-info", this.onAccountInfoChanged);
  },
  destroyed() {
    window.removeEventListener("resize", this.calcWindowWidth);

    this.$root.$off("new-request", this.getRequestsList);
    this.$root.$off("updated-account-info", this.onAccountInfoChanged);
    this.$root.$off("deleted-account-info", this.onAccountInfoChanged);
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions({
      getUserInfo: "account/getUserInfo",
      refreshRequest: "request/update",
      getPayLink: "account/getPayLink",
      sendMessage: "request/addMessage",
      getRequestsList: "request/getRequestsList",
      getRequestDetails: "request/getRequestDetails",
      checkRequestSumIsVerified: "request/checkRequestSumIsVerified",
      getPaidRequestAllowedBonuses: "request/getPaidRequestAllowedBonuses",
      sendPaidRequestCompleteCodeCash:
        "request/sendPaidRequestCompleteCodeCash",
    }),
    initPerfectScroll() {
      Object.keys(perfectScrollConfig).forEach((item) => {
        if (perfectScrollConfig[item]) perfectScrollConfig[item].destroy();
        perfectScrollConfig[item] = new PerfectScrollbar(this.$refs[item]);
      });
    },
    calcWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    updateDisableCommentingRequests(disableCommentingRequests) {
      this.disableCommentingRequests = disableCommentingRequests;
    },
    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getRequests();
    },

    openRequest(requestId) {
      if (this.selectedRequestID !== requestId) {
        this.$router.push({
          name: "Requests",
          params: { requestID: requestId },
        });
      }
      // this.selectedRequestID = requestId;
      if (this.isMobile()) {
        this.showDialogModal = true;
      }
    },

    getAccountInfo() {
      this.getUserInfo().then((result) => {
        this.hasAccounts = result.Accounts && result.Accounts.length;
      });
    },
    update() {
      this.refreshRequest(this.selectedRequestID).then((result) => {
        if (result.current) {
          // Update common info
          this.selectedRequestInfo = {
            id: result.ID,
            phone: result.current.Phone,
            address: result.current.Address,
            type: result.current.TypeName,
            name: result.current.Name,
            status: result.current.Status,
            statusId: result.StatusID,
            added: result.current.Added,
            number: result.current.RequestNumber,
            canClose: !result.current.IsClosed,
            CanPayOnMobileApp: result.current.CanPayOnMobileApp,
            CanCancelingRequest: result.current.CanCancelingRequest,
            IsPaid: result.current.IsPaid,
            IsPaidByUser: result.current.IsPaidByUser,
            PaidServiceText: result.current.PaidServiceText,
            paidRequestStatus: result.current.PaidRequestStatus,
            PaidSumm: result.current.PaidSumm,
          };
          // Add new messages
          for (let i = 0; i < result.current.Messages.length; i++) {
            var message = result.current.Messages[i];
            let found = false;
            for (let j = 0; j < this.messageIDs.length; j++) {
              if (message.ID === this.messageIDs[j]) {
                found = true;
                break;
              }
            }
            if (!found) {
              this.messageIDs.push(message.ID);
            }
          }

          this.$nextTick(() => this.scrollToBottom());
        }
      });
    },
    closeRequest() {
      if (
        !this.selectedRequestInfo ||
        !this.selectedRequestInfo.canClose ||
        !this.selectedRequestInfo.CanCancelingRequest
      ) {
        return;
      }

      this.closeRequestModal(
        this.selectedRequestID,
        this.selectedRequestInfo.number
      );
    },

    getRequests(selectId = null) {
      this.isLoadingRequests = true;

      this.messageIDs = [];
      this.selectedRequestID = null;

      this.getRequestsList()
        .then(() => {
          if (selectId) {
            // this.$router.push({
            //   name: "Requests",
            //   params: { requestID: selectId },
            // });
            this.selectedRequestID = selectId;
            if (this.isMobile()) {
              this.showDialogModal = true;
            }
            return;
          }
          if (this.filteredRequests.length) {
            this.$router.push({
              name: "Requests",
              params: { requestID: this.filteredRequests[0].ID },
            });
          }
          // this.selectedRequestID = this.filteredRequests.length
          //   ? this.filteredRequests[0].ID
          //   : null;
        })
        .finally(() => {
          this.isLoadingRequests = false;
        });
    },
    getRequest() {
      if (!this.selectedRequestID) {
        return;
      }

      this.isLoadingMessages = true;
      this.selectedRequestInfo = null;
      this.getRequestDetails(this.selectedRequestID)
        .then((result) => {
          this.messageIDs = result.Messages;
          this.templateMessageFiles = [...result.Files];
          this.selectedRequestInfo = {
            id: result.ID,
            phone: result.Phone,
            address: result.Address,
            type: result.TypeName,
            name: result.Name,
            status: result.Status,
            statusId: result.StatusID,
            added: result.Added,
            number: result.RequestNumber,
            canClose: !result.IsClosed,
            source: result.Source,
            IsPaid: result.IsPaid,
            CanPayOnMobileApp: result.CanPayOnMobileApp,
            CanCancelingRequest: result.CanCancelingRequest,
            IsPaidByUser: result.IsPaidByUser,
            PaidServiceText: result.PaidServiceText,
            paidRequestStatus: result.PaidRequestStatus,
            PaidSumm: result.PaidSumm,
          };
          this.$nextTick(this.scrollToBottom);
        })
        .finally(() => {
          this.isLoadingMessages = false;
        });
    },
    getFile(fileID) {
      requestApi.getFile(fileID).then((response) => {
        const file = this.templateMessageFiles.find((el) => el.ID === fileID);
        if (file.Name) {
          download(response.data, file.Name, response.headers["content-type"]);
        } else {
          this.$notify.error("Ошибка названия файла");
        }
      });
    },
    addMessage() {
      if (this.form.message.length > 0) {
        this.isSendingMessage = true;

        this.sendMessage({
          RequestId: this.selectedRequestID,
          Text: this.form.message,
        })
          .then(() => {
            this.form.message = "";
            this.getRequest();
          })
          .finally(() => {
            this.isSendingMessage = false;
          });
      }
    },
    addFile(event) {
      let formData = new FormData();
      formData.append("requestId", this.selectedRequestID);
      formData.append("file", event.target.files[0]);

      requestApi
        .addFile(formData)
        .then(this.getRequest)
        .catch(() => {
          this.$notify.error("Ошибка загрузки файла");
        });
    },
    scrollToBottom() {
      try {
        this.$refs.dialogListScroll.scrollTop = this.$refs.dialogListScroll.scrollHeight;
      } catch (e) {
        console.log(e);
      }
    },
    closePaidModal() {
      this.showPaidModal = false;
      this.paidType = "online";
      this.paidWithBonuses = false;
    },

    checkSumIsVerified() {
      if (this.config && this.config.useBonusSystem) {
        this.getPaidRequestAllowedBonuses(this.selectedRequestInfo.id).then(
          (result) => {
            this.selectedRequestInfo.BonusAmount = result.BonusAmount;
          }
        );
      }

      this.checkRequestSumIsVerified(this.selectedRequestInfo.id).then(
        (result) => {
          if (result) {
            this.showPaidModal = true;
          }
        }
      );
    },

    paidRequest() {
      if (this.paidType === "online") {
        this.getPayLink({
          sum: this.selectedRequestInfo.PaidSumm,
          paidRequestId: this.selectedRequestInfo.id,
          paymentSystem: null,
          allowCardSaving: false,
          payAll: false,
          bonusAmount:
            this.paidWithBonuses && this.selectedRequestInfo.BonusAmount
              ? this.selectedRequestInfo.BonusAmount
              : 0,
        }).then((result) => {
          if (result.payLink && result.payLink.length > 0) {
            location.href = result.payLink;
            this.closePaidModal();
          }
        });
        return;
      }

      if (this.paidType === "cash") {
        this.sendPaidRequestCompleteCodeCash({
          requestId: this.selectedRequestInfo.id,
          phone: this.selectedRequestInfo.phone,
        }).then((result) => {
          if (result.isSucceed) {
            this.closePaidModal();
            this.getRequest();
          }
        });
        return;
      }
    },

    showPaymentResult() {
      if (location.hash.match(/paymentSuccess$/)) {
        this.$notify.success({
          title: "Оплата",
          message: "Оплата прошла успешно",
          duration: 6000,
          offset: 100,
        });
      }

      if (location.hash.match(/paymentFail$/)) {
        this.$notify.error({
          title: "Оплата",
          message: "Оплата не прошла",
          duration: 6000,
          offset: 100,
        });
      }
    },
  },
  computed: {
    ...mapState(["request"]),
    ...mapGetters({
      getMenuVisibilitySettings: "config/getMenuVisibilitySettings",
      getMessages: "request/getMessages",
    }),
    filteredRequests() {
      return this.request.requests.filter((request) => {
        if (!this.showClosed) {
          return !request.IsClosed;
        }
        return true;
      });
    },
    selectedRequestHeader() {
      return `Заявка № ${this.selectedRequestInfo.number} ${
        this.selectedRequestInfo.paidRequestStatus
          ? this.selectedRequestInfo.paidRequestStatus
          : ""
      }`;
    },
    lastMessage() {
      let messages = this.getMessages(this.messageIDs);
      return messages[messages.length - 1];
    },
    messages() {
      let messages = this.getMessages(this.messageIDs);
      return messages.reduce((newEl, item) => {
        let date = item.Added.split(" ");

        if (!Array.isArray(newEl[date[0]])) {
          newEl[date[0]] = [];
        }

        newEl[date[0]].push({
          ...item,
          Added: date[1],
        });

        return newEl;
      }, {});
    },
    showPayButton() {
      return (
        !!this.lastMessage &&
        !this.lastMessage.IsSelf &&
        !!this.selectedRequestInfo &&
        !this.selectedRequestInfo.IsPaidByUser &&
        this.selectedRequestInfo.IsPaid && //IsPaid - заявка платная, а не оплаченная!
        this.selectedRequestInfo.CanPayOnMobileApp
      );
    },

    canPay() {
      return (
        this.selectedRequestInfo &&
        // 5 – Выполнена(выполнена)
        this.selectedRequestInfo.statusId != 5 &&
        // 6 - Житель подтвердил(закрыл) (закрыта)
        this.selectedRequestInfo.statusId != 6 &&
        // 7 - Отменена(житель отказался) (закрыта)
        this.selectedRequestInfo.statusId != 7 &&
        // 8 - Закрыта с низкой оценкой(закрыта)
        this.selectedRequestInfo.statusId != 8 &&
        // 10 - Закрыта, низкая оценка обработана(закрыта)
        this.selectedRequestInfo.statusId != 10 &&
        // 11 - Закрыта сотрудником(закрыта)
        this.selectedRequestInfo.statusId != 11 &&
        // 12 - Закрыта автоматически(закрыта)
        this.selectedRequestInfo.statusId != 12
      );
    },
  },
  watch: {
    selectedRequestID(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        this.getRequest();
      }
    },
    $route(to) {
      if (this.selectedRequestID !== to.params.requestID) {
        this.selectedRequestID = to.params.requestID;
      }
    },
    config(newValue) {
      this.updateDisableCommentingRequests(newValue.disableCommentingRequests);
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.modal_request-dialog
  position: absolute
  z-index: 998

.request-dialog_pay-panel
  display: flex
  justify-content: center
  padding: 10px
.request-dialog_pay-button
  border: none
  box-shadow: none

.request_paid-modal
  z-index: 999

.request_paid-modal-title
  font-size: 1.3125rem
  font-weight: 700
  margin-bottom: 0.5em

.request_paid-modal-sum-value
  font-size: 1.3125rem
  font-weight: 700
  color: var(--base-color)

.request_paid-modal-sum-currency
  display: inline-block
  margin-left: 5px
  color: #777777

.request_paid-modal-title,
.request_paid-modal-sum
  text-align: center

.request_paid-modal-radio-group
  margin-bottom: 15px

.request_paid-modal-radio
  font-weight: 600
  color: var(--base-color)

.request_paid-modal-button
  width: 100%
  margin-bottom: 15px
  border: none

.request_paid-modal-description
  font-size: 0.9rem
  color: #777777

.request_paid-modal-switch
  text-align: center
  margin-bottom: 15px

.request_paid-modal
  display: flex
  justify-content: center
  align-items: center

.text-part_application pre
  overflow: visible
  white-space: pre-line
  word-wrap: break-word
  font-family: inherit
</style>
